/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { useCallback, useMemo, useState } from "react";
import Button from "src/components/Button";
import DelayedView from "src/components/DelayedView";
import Typo from "src/components/Typo";
import Css from "../Css";
import useDeferred from "../Deferred";
import RootClick from "../RootClick";
import getErrorMessage from "../getErrorMessage";
import EditorContainer2 from "./EditorContainer2";
import FieldController, { FieldConfig } from "./FieldController";
import Input from "./Input";
import Picker, { usePicker } from "./Picker";

type OptionKey = string | number;

type SelectFieldValue<TKey extends OptionKey> = TKey | null;

type SelectFieldControllerConfig<
  TKey extends OptionKey,
  TOption extends {},
  ValidValue extends SelectFieldValue<TKey>
> = Omit<FieldConfig<SelectFieldValue<TKey>, ValidValue>, "initialValue"> & {
  initialValue: TKey | TOption | null;
  options: TOption[] | Promise<TOption[]>;
  keyExtractor: (option: TOption) => TKey;
  textExtractor: (option: TOption) => string;
  nullValueMeaning?: string;
};

export class SelectFieldController<
  TKey extends OptionKey,
  TOption extends {},
  ValidValue extends SelectFieldValue<TKey>
> extends FieldController<SelectFieldValue<TKey>, ValidValue> {
  private optionsPromise: Promise<Array<TOption>>;
  private optionsDeferred: Array<TOption> | null = null;

  constructor(
    readonly config: SelectFieldControllerConfig<TKey, TOption, ValidValue>
  ) {
    // Value
    const initialValue = config.initialValue;
    let value: SelectFieldValue<TKey> = null;
    if (initialValue === null || initialValue === undefined) value = null;
    else if (SelectFieldController.isKey<TKey>(initialValue))
      value = initialValue;
    else value = config.keyExtractor(initialValue);
    super({ ...config, initialValue: value });

    // Options
    const options = this.config.options;
    if (options instanceof Promise) {
      this.optionsPromise = options;
      options.then((o) => {
        this.optionsDeferred = o;
        this.r.notify();
      });
    } else {
      this.optionsPromise = Promise.resolve(options);
      this.optionsDeferred = options;
    }
  }

  getOptionsPromise() {
    return this.optionsPromise;
  }

  useOption() {
    return this.r.useSelector(() => {
      const value = this.getValue();
      if (!value) return null;
      const options = this.optionsDeferred;
      if (!options) return undefined;
      const option = options.find((o) => this.config.keyExtractor(o) === value);
      return option;
    });
  }

  findOption(key: TKey): TOption;
  findOption(key: null): null;
  findOption(key: TKey | null): TOption | null;
  findOption(key: TKey | null): TOption | null {
    const { keyExtractor } = this.config;
    if (this.optionsDeferred === null) throw new Error("No options available");
    if (key === null) return null;
    const option = this.optionsDeferred.find((o) => keyExtractor(o) === key);
    if (!option) throw new Error("No option found");
    return option;
  }

  render(): React.ReactElement {
    return <SelectField controller={this} />;
  }

  static isKey<K>(value: any): value is K {
    return typeof value === "string" || typeof value === "number";
  }
}

type SelectFieldProps<
  TKey extends OptionKey,
  TOption extends {},
  ValidValue extends SelectFieldValue<TKey>
> = {
  controller: SelectFieldController<TKey, TOption, ValidValue>;
};

function SelectField<
  TKey extends OptionKey,
  TOption extends {},
  ValidValue extends SelectFieldValue<TKey>
>(props: SelectFieldProps<TKey, TOption, ValidValue>) {
  const { controller } = props;
  const { options, keyExtractor, textExtractor, nullValueMeaning } =
    controller.config;

  const [value, setValue] = useState<TKey | null>(() => controller.getValue());
  controller.useValueModifier(value);

  const disabled = controller.isDisabled();

  const optionsPromise = useMemo(() => {
    return options instanceof Promise ? options : Promise.resolve(options);
  }, [options]);

  const optionPromise = useMemo(async () => {
    const options = await optionsPromise;
    if (value !== null) {
      const option = options.find((o) => keyExtractor(o) === value);
      if (option === undefined) return null;
      return option as TOption;
    } else {
      return null;
    }
  }, [value, options]);

  const optionDeferred = useDeferred(optionPromise);

  const displayedValue = useMemo(() => {
    if (optionDeferred.state === "idling") return "-";
    else if (optionDeferred.state === "pending") return "Chargement...";
    else if (optionDeferred.state === "rejected")
      return getErrorMessage(optionDeferred.error);
    else {
      const option = optionDeferred.value;
      if (option !== null) {
        return textExtractor(option);
      } else {
        return "";
      }
    }
  }, [optionDeferred, nullValueMeaning]);

  const picker = usePicker();

  const onFocus = useCallback(() => {
    if (disabled) return;
    RootClick.trigger();
    picker.setOpened.toTrue();
  }, [disabled]);

  const onSelectValue = useCallback((option: TOption | null) => {
    setValue(option ? keyExtractor(option) : null);
    picker.setOpened.toFalse();
  }, []);

  const contentCss = css({
    display: "flex",
  });

  const optionsCss = css({
    display: "flex",
    flexDirection: "column",
  });

  const optionCss = css({
    cursor: "pointer",
    borderBottom: `1px solid ${rgba("black", 0.2)}`,
    "&:last-child": {
      borderBottom: "none",
    },
  });

  const inputCss = css(Css.inputReset, {
    flexGrow: 1,
  });

  return (
    <RootClick.Boundary>
      <EditorContainer2 controller={controller}>
        <div css={contentCss}>
          <Input
            type="text"
            readOnly
            value={displayedValue}
            css={inputCss}
            onFocus={onFocus}
            disabled={disabled}
            nullValueMeaning={nullValueMeaning}
          />
          {value !== null && !disabled ? (
            <Button label="-" onClick={() => setValue(null)} />
          ) : null}
          <Picker {...picker}>
            <div css={optionsCss}>
              <DelayedView promise={optionsPromise}>
                {(options) =>
                  options.map((o) => (
                    <div
                      key={keyExtractor(o)}
                      css={optionCss}
                      onClick={() => onSelectValue(o)}
                    >
                      <Typo>{textExtractor(o)}</Typo>
                    </div>
                  ))
                }
              </DelayedView>
            </div>
          </Picker>
        </div>
      </EditorContainer2>
    </RootClick.Boundary>
  );
}
