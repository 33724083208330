/** @jsxImportSource @emotion/react */

import { Fragment, useMemo } from "react";
import Typo from "src/components/Typo";
import Form from "src/utilities/Editor2/Form";
import { MultiSelectFieldController } from "src/utilities/Editor2/MultiSelectFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Extractors from "src/utilities/Extractors";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export type Step1Data = {
  locales: Array<string>;
  code: string;
};

type Step1Props = {
  onSubmit: (data: Step1Data) => any;
};

export default function Step1(props: Step1Props) {
  const { onSubmit } = props;

  const localesField = useMemo(() => {
    const locales = [
      { key: "fr-FR", label: "Français" },
      { key: "en-GB", label: "Anglais" },
      { key: "es-ES", label: "Espganol" },
    ];
    return new MultiSelectFieldController({
      label: "Langues supportées",
      options: locales,
      keyExtractor: (r) => Extractors.key(r),
      textExtractor: Extractors.label,
      initialValue: [],
      validation: required,
    });
  }, []);

  const codeField = useMemo(() => {
    return new TextFieldController({
      label: "Nom interne",
      initialValue: null,
      validation: required,
      help: "Nom du challenge utilisé en interne",
    });
  }, []);

  const onSubmitForm = useSubmitCallback(
    () => {
      onSubmit({
        locales: localesField.validate(),
        code: codeField.validate(),
      });
    },
    {},
    []
  );

  return (
    <Fragment>
      <Typo typo="subtitle">Etape 1/4 : Informations générales</Typo>
      <Form onSubmit={onSubmitForm}>
        {localesField.render()}
        {codeField.render()}
      </Form>
    </Fragment>
  );
}
