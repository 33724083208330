/** @jsxImportSource @emotion/react */

import { css, SerializedStyles } from "@emotion/react";

type TypoConfig = {
  size: number | string;
  lineHeight: number;
  weight: number;
  extraCss?: SerializedStyles;
};

export const POPPINS_LINE_HEIGHT = 1.5;

const title: TypoConfig = {
  size: "1.6rem",
  lineHeight: POPPINS_LINE_HEIGHT,
  weight: 600,
};
const subtitle: TypoConfig = {
  size: "1.4rem",
  lineHeight: POPPINS_LINE_HEIGHT,
  weight: 500,
};
const body: TypoConfig = {
  size: "1rem",
  lineHeight: POPPINS_LINE_HEIGHT,
  weight: 400,
};
const minor: TypoConfig = {
  size: "0.8rem",
  lineHeight: POPPINS_LINE_HEIGHT,
  weight: 400,
};
const label: TypoConfig = {
  size: "0.8rem",
  lineHeight: POPPINS_LINE_HEIGHT,
  weight: 400,
};
const button: TypoConfig = {
  size: "1rem",
  lineHeight: POPPINS_LINE_HEIGHT,
  weight: 600,
  extraCss: css({ textTransform: "uppercase" }),
};
const badge: TypoConfig = {
  size: "0.8rem",
  lineHeight: POPPINS_LINE_HEIGHT,
  weight: 600,
  extraCss: css({ textTransform: "uppercase" }),
};

export const TypoConfigs = {
  label,
  title,
  body,
  minor,
  button,
  badge,
  subtitle,
};

export type TypoName = keyof typeof TypoConfigs;

function getTypoStyles(metrics: TypoConfig): SerializedStyles {
  return css(
    {
      fontFamily: "Poppins",
      fontWeight: metrics.weight,
      lineHeight: metrics.lineHeight,
      fontSize: metrics.size,
    },
    metrics.extraCss
  );
}

const Typos: { [typo in TypoName]: SerializedStyles } = {
  label: getTypoStyles(label),
  title: getTypoStyles(title),
  body: getTypoStyles(body),
  button: getTypoStyles(button),
  badge: getTypoStyles(badge),
  minor: getTypoStyles(minor),
  subtitle: getTypoStyles(subtitle),
};

export default Typos;
