/** @jsxImportSource @emotion/react */
import dayjs from "dayjs";
import {
  DependencyList,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import DelayedView from "src/components/DelayedView";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import MassNotificationEditor from "src/editors/MassNotificationEditor";
import MassNotificationModel from "src/models/MassNotificationModel";
import MassNotificationShipmentModel from "src/models/MassNotificationShipmentModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Css from "src/utilities/Css";
import Dialogs from "src/utilities/Dialogs";
import getLocaleFlag from "src/utilities/getLocaleFlag";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function NotificationEdition() {
  const challengeUuid = useChallenge();
  const { platform } = Services.use();
  const navigate = useNavigate();

  const notificationUuid = Navigation.useParam("notificationUuid");

  const [loadTime, setLoadTime] = useState<Date>(() => new Date());

  const load = useMemo(
    () =>
      platform.get<MassNotificationModel>(
        `/support/challenges/${challengeUuid}/mass-notifications/${notificationUuid}`
      ),
    [loadTime]
  );

  const onUpdate = useSubmitCallback(
    async (updates: Updates<MassNotificationModel, "sent">) => {
      await platform.patch<MassNotificationModel>(
        `/support/challenges/${challengeUuid}/mass-notifications/${notificationUuid}`,
        updates
      );
    },
    {},
    []
  );

  const onDelete = useSubmitCallback(
    async () => {
      if (!(await Dialogs.confirm("Confirmer"))) return;
      await platform.delete(
        `/support/challenges/${challengeUuid}/mass-notifications/${notificationUuid}`
      );
      navigate(Navigation.getPath("NotificationsHome"));
    },
    { confirmationMessage: "Confirmez ?" },
    []
  );

  return (
    <DelayedView promise={load}>
      {(notification) => (
        <Fragment>
          <MassNotificationEditor
            notitication={notification}
            onSubmit={onUpdate}
            onDelete={onDelete}
          />
          <Spacer />
          {!notification.sent ? (
            <ShipNotification
              notification={notification}
              onDone={() => setLoadTime(new Date())}
            />
          ) : (
            <NotificationShipment uuid={notification.uuid} />
          )}
        </Fragment>
      )}
    </DelayedView>
  );
}

type ShipNotificationProps = {
  notification: MassNotificationModel;
  onDone: (notification: MassNotificationModel) => any;
};

function ShipNotification(props: ShipNotificationProps) {
  const { notification, onDone } = props;
  const challengeUuid = useChallenge().uuid;
  const { platform } = Services.use();

  const targets = useMemo(
    async () =>
      platform.get<Array<{ numberOfAccounts: number; locale: string }>>(
        `/support/challenges/${challengeUuid}/mass-notification-targets`
      ),
    [challengeUuid]
  );

  const onSend = useCallback(async () => {
    if (
      !(await Dialogs.confirm(
        "La notification va être envoyée tout de suite à l'ensemble des participants enregistrés"
      ))
    )
      return;
    const newNotification = await platform.put<MassNotificationModel>(
      `/support/challenges/${challengeUuid}/mass-notifications/${notification.uuid}/ship`
    );
    onDone(newNotification);
  }, [notification.uuid, onDone]);

  return (
    <div>
      <Typo>La notification n'a pas encore été envoyée</Typo>
      <Typo>Si vous l'envoyez maintenant, elle sera envoyée ainsi : </Typo>
      <DelayedView promise={targets}>
        {(targets) => {
          return (
            <Fragment>
              <table css={Css.table}>
                <thead>
                  <tr>
                    <td>Langue</td>
                    <td>Titre</td>
                    <td>Corps</td>
                    <td>Nb. de comptes ciblés</td>
                  </tr>
                </thead>
                <tbody>
                  {targets.map((t) => (
                    <tr>
                      <td>{getLocaleFlag(t.locale)}</td>
                      <td>{notification.title[t.locale]}</td>
                      <td>{notification.body[t.locale]}</td>
                      <td>{t.numberOfAccounts} compte(s)</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Spacer />
              <Button onClick={onSend} label="Envoyer la notification" />
            </Fragment>
          );
        }}
      </DelayedView>
    </div>
  );
}

type NotificationShipmentProps = {
  uuid: string;
};

function NotificationShipment(props: NotificationShipmentProps) {
  const { uuid } = props;
  const challengeUuid = useChallenge().uuid;
  const { platform } = Services.use();

  const shipments = useMemo(
    async () =>
      platform.get<Array<MassNotificationShipmentModel>>(
        `/support/challenges/${challengeUuid}/mass-notifications/${uuid}/shipments`
      ),
    [challengeUuid, uuid]
  );

  return (
    <div>
      <Typo>La notification a été envoyée</Typo>
      <DelayedView promise={shipments}>
        {(shipments) => {
          return (
            <Fragment>
              <table css={Css.table}>
                <thead>
                  <tr>
                    <td>Langue</td>
                    <td>Titre</td>
                    <td>Corps</td>
                    <td>Nb. de comptes ciblés</td>
                    <td>Envoyé à</td>
                  </tr>
                </thead>
                <tbody>
                  {shipments.map((s) => (
                    <tr>
                      <td>{getLocaleFlag(s.locale)}</td>
                      <td>{s.title}</td>
                      <td>{s.body}</td>
                      <td>{s.targetedAccountsNumber} compte(s) ciblés</td>
                      <td>{dayjs(s.sentAt).format("LLLL")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Spacer />
            </Fragment>
          );
        }}
      </DelayedView>
    </div>
  );
}

function usePromiseState<TValue>(
  initialValue: null | Promise<TValue> | (() => Promise<TValue>),
  deps: DependencyList = []
) {
  const [state, setState] = useState<Promise<TValue> | null>(() => {
    if (initialValue === null) return null;
    else if (initialValue instanceof Function) return initialValue();
    else return initialValue;
  });

  const toValue = useCallback((value: TValue) => {
    setState(Promise.resolve(value));
  }, []);

  const mountPassed = useRef(false);

  useEffect(() => {
    if (mountPassed.current === false) {
      mountPassed.current = true;
      return;
    }
    if (initialValue === null) setState(null);
    else if (initialValue instanceof Function) setState(initialValue());
    else setState(initialValue);
  }, deps);

  return [state, { toValue }] as const;
}
