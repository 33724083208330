import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import DelayedView from "src/components/DelayedView";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import QuizAnswerEditor from "src/editors/QuizAnswerEditor";
import QuizEditor from "src/editors/QuizEditor";
import QuizAnswerModel from "src/models/QuizAnswerModel";
import QuizModel from "src/models/QuizModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Dialogs from "src/utilities/Dialogs";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Toasts from "src/utilities/Toasts";
import Updates from "src/utilities/Updates";
import useMemoState from "src/utilities/useMemoState";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function MissionCreation() {
  const challengeUuid = useChallenge().uuid;
  const quizUuid = Navigation.useParam("quizUuid");
  const navigate = useNavigate();

  const { platform } = Services.use();

  const [load, setLoad] = useMemoState(async () => {
    return Promise.all([
      platform.get<QuizModel>(
        `/support/challenges/${challengeUuid}/quizzes/${quizUuid}`
      ),
      platform.get<QuizAnswerModel[]>(
        `/support/challenges/${challengeUuid}/quizzes/${quizUuid}/answers`
      ),
    ]);
  }, []);

  const onSubmit = useSubmitCallback(
    async (updates: Updates<QuizModel>) => {
      try {
        await platform.put<QuizModel>(
          `/support/challenges/${challengeUuid}/quizzes/${quizUuid}`,
          updates
        );
        Toasts.success("Quiz mis à jour");
      } catch (err) {
        Toasts.error(err);
      }
    },
    { successMessage: "Quiz modifié" },
    [challengeUuid]
  );

  const onDelete = useSubmitCallback(
    async () => {
      if (!(await Dialogs.confirm("Supprimer le quiz ?"))) return;
      try {
        await platform.delete<QuizModel>(
          `/support/challenges/${challengeUuid}/quizzes/${quizUuid}`
        );
        navigate(Navigation.getPath("QuizzesHome"));
      } catch (err) {
        Toasts.error(err);
      }
    },
    { successMessage: "Quiz supprimé " },
    [challengeUuid]
  );

  const onCreateAnswer = useSubmitCallback(
    async (updates: Updates<QuizAnswerModel>) => {
      try {
        const newAnswer = await platform.post<QuizAnswerModel>(
          `/support/challenges/${challengeUuid}/quizzes/${quizUuid}/answers`,
          updates
        );
        const [quiz, answers] = await load;
        setLoad(
          Promise.resolve([quiz, [...answers, newAnswer]] as [
            QuizModel,
            Array<QuizAnswerModel>
          ])
        );
      } catch (err) {
        Toasts.error(err);
      }
    },
    { successMessage: "Nouvelle réponse créée" },
    [challengeUuid, load]
  );

  const onUpdateAnswer = useSubmitCallback(
    async (uuid: string, updates: Updates<QuizAnswerModel>) => {
      try {
        const upToDate = await platform.put<QuizAnswerModel>(
          `/support/challenges/${challengeUuid}/quizzes/${quizUuid}/answers/${uuid}`,
          updates
        );
        const [quiz, answers] = await load;
        setLoad(
          Promise.resolve([
            quiz,
            answers.filter((a) => (a.uuid === uuid ? upToDate : a)),
          ] as [QuizModel, Array<QuizAnswerModel>])
        );
      } catch (err) {
        Toasts.error(err);
      }
    },
    { successMessage: "Réponse mide à jour" },
    [challengeUuid]
  );

  const onDeleteAnswer = useSubmitCallback(
    async (uuid: string) => {
      if (!(await Dialogs.confirm("Supprimer la réponse ?"))) return;
      try {
        await platform.delete<QuizAnswerModel>(
          `/support/challenges/${challengeUuid}/quizzes/${quizUuid}/answers/${uuid}`
        );
        const [quiz, answers] = await load;
        setLoad(
          Promise.resolve([quiz, answers.filter((a) => a.uuid !== uuid)] as [
            QuizModel,
            Array<QuizAnswerModel>
          ])
        );
        Toasts.success("Réponse supprimée");
      } catch (err) {
        Toasts.error(err);
      }
    },
    { successMessage: "Réponse supprimée" },
    [challengeUuid]
  );

  return (
    <DelayedView promise={load}>
      {([quiz, answers]) => {
        return (
          <Fragment>
            <QuizEditor quiz={quiz} onSubmit={onSubmit} onDelete={onDelete} />
            <Spacer />
            <Typo center typo="title">
              Réponses
            </Typo>
            <Fragment>
              {answers.map((answer) => (
                <Fragment key={answer.uuid}>
                  <QuizAnswerEditor
                    answer={answer}
                    onSubmit={(u) => onUpdateAnswer(answer.uuid, u)}
                    onDelete={() => onDeleteAnswer(answer.uuid)}
                  />
                  <Spacer />
                </Fragment>
              ))}
              <QuizAnswerEditor
                answer={null}
                key={answers.length + 1}
                onSubmit={onCreateAnswer}
              />
            </Fragment>
          </Fragment>
        );
      }}
    </DelayedView>
  );
}
