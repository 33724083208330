/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { PropsWithChildren } from "react";
import RootClick from "../RootClick";
import useBooleanState from "../useBooleanState";

export function usePicker() {
  const [opened, setOpened] = useBooleanState(false);

  RootClick.useListener(() => {
    console.log("ROOTCLICK");
    setOpened.toFalse();
  }, []);

  return { opened, setOpened };
}

type PickerProps = PropsWithChildren<ReturnType<typeof usePicker>>;

export default function Picker(props: PickerProps) {
  const { opened, children } = props;

  const pickerCss = css({
    position: "absolute",
    zIndex: 2,
    top: "100%",
    left: 0,
    right: 0,
    background: "white",
    width: 300,
    boxShadow: `0px 0px 20px ${rgba("black", 0.2)}`,
    opacity: opened ? 1 : 0,
    pointerEvents: opened ? "all" : "none",
    transition: "all 200ms",
    table: {
      width: "100%",
    },
  });

  return <div css={pickerCss}>{children}</div>;
}
