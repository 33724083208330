import { useMemo } from "react";
import AccountModel from "src/models/AccountModel";
import Editor2 from "src/utilities/Editor2";
import { SelectFieldController } from "src/utilities/Editor2/SelectFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Extractors from "src/utilities/Extractors";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

interface AccountEditorProps {
  account: AccountModel | null;
  onSubmit: (updates: Partial<Updates<AccountModel>>) => any;
  // onDelete?: () => any;
}
export default function AccountEditor(props: AccountEditorProps) {
  const { account, onSubmit } = props;

  const emailField = useMemo(
    () =>
      new TextFieldController({
        label: "Adresse email",
        initialValue: account ? account.email : null,
        validation: required,
        disabled: true,
      }),
    []
  );

  const firstNameField = useMemo(
    () =>
      new TextFieldController({
        label: "Prénom",
        initialValue: account ? account.firstName : null,
        validation: required,
        help: "L'utilisateur peut aussi modifier par lui même son prénom directement dans l'application",
      }),
    []
  );

  const lastNameField = useMemo(
    () =>
      new TextFieldController({
        label: "Nom",
        initialValue: account ? account.lastName : null,
        validation: required,
        help: "L'utilisateur peut aussi modifier par lui même son nom directement dans l'application",
      }),
    []
  );

  const genderOptions = useMemo(
    () => [
      { key: "female" as const, label: "Apparaître dans le classement Femme" },
      { key: "male" as const, label: "Apparaître dans le classement Homme" },
    ],
    []
  );

  const genderField = useMemo(() => {
    return new SelectFieldController({
      label: "Classement par sexe",
      initialValue: account ? account.gender : null,
      options: genderOptions,
      keyExtractor: Extractors.key,
      textExtractor: Extractors.label,
      nullValueMeaning:
        "N'apparaitre ni dans le classement Femme, ni dans le classement Homme",
    });
  }, []);

  const onSubmitForm = useSubmitCallback(
    () => {
      const updates: Partial<Updates<AccountModel>> = {
        firstName: firstNameField.validate(),
        lastName: lastNameField.validate(),
        gender: genderField.validate(),
      };
      onSubmit(updates);
    },
    {},
    [onSubmit]
  );

  return (
    <Editor2.Form onSubmit={onSubmitForm}>
      {emailField.render()}
      {firstNameField.render()}
      {lastNameField.render()}
      {genderField.render()}
    </Editor2.Form>
  );
}
