import { Fragment, useMemo } from "react";
import Button from "src/components/Button";
import DataTable2 from "src/components/DataTable2";
import DelayedView from "src/components/DelayedView";
import ChallengeModel from "src/models/ChallengeModel";
import QuizModel from "src/models/QuizModel";
import {
  useChallenge,
  useChallengeLocale,
  useChallengeService,
} from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { SwitchFieldController } from "src/utilities/Editor2/SwitchFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Extractors from "src/utilities/Extractors";
import List from "src/utilities/List";
import ListItem from "src/utilities/List/ListItem";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function QuizzesList() {
  const challenge = useChallenge();
  const locale = useChallengeLocale();

  const { platform } = Services.use();
  const challengeService = useChallengeService();

  const load = useMemo(async () => {
    return await platform.get<QuizModel[]>(
      `/support/challenges/${challenge.uuid}/quizzes`
    );
  }, [challenge.uuid]);

  const activationField = useMemo(() => {
    return new SwitchFieldController({
      label: "Activer les quiz",
      initialValue: challenge.quizzesActivated,
      validation: required,
    });
  }, [challenge]);

  const onSubmit = useSubmitCallback(
    async () => {
      const quizzesActivated = activationField.validate();
      const newChallenge = await platform.patch<ChallengeModel>(
        `/support/challenges/${challenge.uuid}`,
        { quizzesActivated }
      );
      challengeService.set(newChallenge);
    },
    { successMessage: "Ok !" },
    [activationField]
  );

  return (
    <Fragment>
      <Editor2.Form onSubmit={onSubmit}>
        {activationField.render()}
      </Editor2.Form>

      {challenge.quizzesActivated ? (
        <Fragment>
          <Button
            label="Ajouter un quiz"
            center
            to={Navigation.getPath("QuizCreation")}
          />
          <DelayedView promise={load}>
            {(quizzes) => (
              <List
                items={quizzes}
                keyExtractor={Extractors.uuid}
                renderItem={(q) => (
                  <ListItem
                    label={q.name[locale]}
                    helper={q.question[locale]}
                    to={Navigation.getPath("QuizEdition", {
                      params: { quizUuid: q.uuid },
                    })}
                  />
                )}
              />
            )}
          </DelayedView>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

interface QuizzesTableProps {
  quizzes: QuizModel[];
}

function QuizzesTable(props: QuizzesTableProps) {
  const { quizzes } = props;

  const columns = DataTable2.useColumns<QuizModel>([
    DataTable2.columns.perLocaleStrings({
      id: "name",
      accessor: (r) => r.name,
      header: "Libellé",
    }),
    DataTable2.columns.date({
      id: "startAt",
      accessor: (r) => new Date(r.startAt),
      header: "Début",
    }),
    DataTable2.columns.date({
      id: "endAt",
      accessor: (r) => new Date(r.endAt),
      header: "Fin",
    }),
    {
      id: "manage",
      accessor: (r) => r.uuid,
      render: (v) => (
        <Button
          label="Gérer"
          to={Navigation.getPath("QuizEdition", { params: { quizUuid: v } })}
        />
      ),
    },
  ]);

  return <DataTable2 data={quizzes} columns={columns} />;
}
