/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { without } from "lodash";
import { Fragment, useCallback, useMemo } from "react";
import Button from "src/components/Button";
import Clickable from "src/components/Clickable";
import DelayedView from "src/components/DelayedView";
import Divider from "src/components/Divider";
import Typo from "src/components/Typo";
import Intersperse from "../Intersperse";
import RootClick from "../RootClick";
import EditorContainer2 from "./EditorContainer2";
import FieldController, { FieldConfig } from "./FieldController";
import Input from "./Input";
import Picker, { usePicker } from "./Picker";

type OptionKey = string | number;

type MultiSelectFieldValue<TKey extends OptionKey> = Array<TKey> | null;

type MultiSelectFieldControllerConfig<
  TKey extends OptionKey,
  TOption extends {},
  ValidValue extends MultiSelectFieldValue<TKey>
> = Omit<
  FieldConfig<MultiSelectFieldValue<TKey>, ValidValue>,
  "initialValue"
> & {
  initialValue: Array<TKey> | Array<TOption> | null;
  options: TOption[] | Promise<TOption[]>;
  keyExtractor: (option: TOption) => TKey;
  textExtractor: (option: TOption) => string;
  nullValueMeaning?: string;
};

export class MultiSelectFieldController<
  TKey extends OptionKey,
  TOption extends {},
  ValidValue extends MultiSelectFieldValue<TKey>
> extends FieldController<MultiSelectFieldValue<TKey>, ValidValue> {
  private optionsPromise: Promise<Array<TOption>>;
  private optionsDeferred: Array<TOption> | null = null;

  constructor(
    readonly config: MultiSelectFieldControllerConfig<TKey, TOption, ValidValue>
  ) {
    const initialValue = config.initialValue;
    let value: MultiSelectFieldValue<TKey> = null;
    if (initialValue === null || initialValue === undefined) value = null;
    else if (MultiSelectFieldController.areKeys<TKey>(initialValue))
      value = initialValue;
    else value = initialValue.map((v) => config.keyExtractor(v));
    super({
      ...config,
      initialValue: value,
    });
    // Options
    const options = this.config.options;
    if (options instanceof Promise) {
      this.optionsPromise = options;
      options.then((o) => {
        this.optionsDeferred = o;
        this.r.notify();
      });
    } else {
      this.optionsPromise = Promise.resolve(options);
      this.optionsDeferred = options;
    }
  }

  // useOption() {
  //   return this.dispatcher.useSelector(() => {
  //     const value = this.getValue();
  //     if (!value) return null;
  //     const options = this.optionsDefered;
  //     if (!options) return undefined;
  //     const option = options.find((o) => this.config.keyExtractor(o) === value);
  //     return option;
  //   });
  // }

  findOption(key: TKey): TOption;
  findOption(key: null): null;
  findOption(key: TKey | null): TOption | null;
  findOption(key: TKey | null): TOption | null {
    const { keyExtractor } = this.config;
    if (this.optionsDeferred === null) throw new Error("No options available");
    if (key === null) return null;
    const option = this.optionsDeferred.find((o) => keyExtractor(o) === key);
    if (!option) throw new Error("No option found");
    return option;
  }

  getOptionsPromise() {
    return this.optionsPromise;
  }

  render(): React.ReactElement {
    return <MultiSelectField controller={this} />;
  }

  static areKeys<K>(value: any): value is Array<K> {
    if (!Array.isArray(value)) return false;
    const nonKey = value.find((v) => !MultiSelectFieldController.isKey<K>(v));
    return nonKey === undefined;
  }

  static isKey<K>(value: any): value is K {
    return typeof value === "string" || typeof value === "number";
  }
}

type MultiSelectFieldProps<
  TKey extends OptionKey,
  TOption extends {},
  ValidValue extends MultiSelectFieldValue<TKey>
> = {
  controller: MultiSelectFieldController<TKey, TOption, ValidValue>;
};

function MultiSelectField<
  TKey extends OptionKey,
  TOption extends {},
  ValidValue extends MultiSelectFieldValue<TKey>
>(props: MultiSelectFieldProps<TKey, TOption, ValidValue>) {
  const { controller } = props;

  const disabled = controller.isDisabled();
  const { keyExtractor, textExtractor, nullValueMeaning } = controller.config;

  const values = controller.useValue();

  const optionsPromise = controller.getOptionsPromise();

  const addableOptionsPromise = useMemo(async () => {
    const options = await optionsPromise;
    if (values === null) return options;
    const addable = options.filter((o) => !values.includes(keyExtractor(o)));
    return addable;
  }, [optionsPromise, values]);

  const selectedOptionsPromise = useMemo(async () => {
    if (values === null) return [];
    const options = await optionsPromise;
    const addable = values.map((v) => {
      const match = options.find((o) => keyExtractor(o) === v);
      if (!match) throw new Error("Invalid option");
      return match;
    });
    return addable;
  }, [values, optionsPromise]);

  const addOption = useCallback(
    (option: TOption) => {
      if (disabled) return;
      const key = keyExtractor(option);
      if (values === null) {
        controller.setValue([key]);
      } else {
        if (values.includes(key)) return;
        controller.setValue([...values, key]);
      }
    },
    [values, disabled]
  );

  const dropOption = useCallback(
    (option: TOption) => {
      if (disabled) return;
      const key = keyExtractor(option);
      if (values === null) {
        controller.setValue(null);
      } else {
        if (values.includes(key)) {
          controller.setValue(without(values, key));
        }
      }
    },
    [values, disabled]
  );

  const picker = usePicker();

  const optionCss = css({
    display: "flex",
    alignItems: "center",
  });

  return (
    <RootClick.Boundary>
      <EditorContainer2 controller={controller}>
        {values === null ? (
          <Input readOnly nullValueMeaning={nullValueMeaning} value="" />
        ) : (
          <DelayedView promise={selectedOptionsPromise}>
            {(options) => (
              <Intersperse between={() => <Divider />}>
                {options.map((option) => (
                  <div css={optionCss}>
                    <Typo css={css({ flex: 1 })}>{textExtractor(option)}</Typo>
                    {disabled ? null : (
                      <Button label="-" onClick={() => dropOption(option)} />
                    )}
                  </div>
                ))}
              </Intersperse>
            )}
          </DelayedView>
        )}
        <DelayedView promise={addableOptionsPromise}>
          {(options) => {
            if (options.length === 0) return null;
            if (disabled) return null;
            return (
              <Fragment>
                <Divider />
                <Input
                  placeholder="➕"
                  value=""
                  onFocus={picker.setOpened.toTrue}
                  readOnly={true}
                />
                <Picker {...picker}>
                  <div>
                    <Intersperse between={() => <Divider />}>
                      {options.map((option) => (
                        <Clickable
                          css={optionCss}
                          onClick={() => addOption(option)}
                        >
                          <Typo>{textExtractor(option)}</Typo>
                        </Clickable>
                      ))}
                    </Intersperse>
                  </div>
                </Picker>
              </Fragment>
            );
          }}
        </DelayedView>
      </EditorContainer2>
    </RootClick.Boundary>
  );
}
