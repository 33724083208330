import { Fragment, useMemo } from "react";
import Button from "src/components/Button";
import DataTable2 from "src/components/DataTable2";
import DelayedView from "src/components/DelayedView";
import Spacer from "src/components/Spacer";
import ChallengeModel from "src/models/ChallengeModel";
import ConferenceModel from "src/models/ConferenceModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { SwitchFieldController } from "src/utilities/Editor2/SwitchFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function ConferencesList() {
  const challenge = useChallenge();
  const { platform } = Services.use();

  const loading = useMemo(
    async () =>
      await platform.get<Array<ConferenceModel>>(
        `/support/challenges/${challenge.uuid}/conferences`
      ),
    [challenge.uuid]
  );

  const activationField = useMemo(
    () =>
      new SwitchFieldController({
        label: "Activer les conférences",
        initialValue: challenge.conferencesActivated,
        validation: required,
      }),
    []
  );

  const onSubmit = useSubmitCallback(async () => {
    const update: Pick<ChallengeModel, "conferencesActivated"> = {
      conferencesActivated: activationField.validate(),
    };
    await platform.patch(`/support/challenges/${challenge.uuid}`, update);
    window.location.reload();
  });

  const columns = DataTable2.useColumns<ConferenceModel>([
    DataTable2.columns.perLocaleStrings({
      id: "label",
      header: "Libellé",
      accessor: (c) => c.label,
    }),
    DataTable2.columns.boolean({
      id: "enabled",
      header: "Visible",
      accessor: (c) => c.enabled,
    }),
    DataTable2.columns.date({
      id: "startAt",
      header: "Début",
      accessor: (c) => new Date(c.startAt),
    }),
    DataTable2.columns.date({
      id: "endAt",
      header: "Fin",
      accessor: (c) => new Date(c.endAt),
    }),
    {
      id: "link",
      header: "Gérer",
      accessor: (c) => c,
      render: (c: ConferenceModel) => (
        <Button
          to={Navigation.getPath("ConferencesEdition", {
            params: { conferenceUuid: c.uuid },
          })}
          label="Gérer"
        />
      ),
    },
  ]);

  return (
    <Fragment>
      <Editor2.Form onSubmit={onSubmit}>
        {activationField.render()}
      </Editor2.Form>
      {challenge.conferencesActivated ? (
        <div>
          <Spacer />
          <Button
            center
            label="Ajouter une conférence"
            to={Navigation.getPath("ConferencesCreation")}
          />
          <Spacer />
          <DelayedView promise={loading}>
            {(confs) => <DataTable2 data={confs} columns={columns} />}
          </DelayedView>
        </div>
      ) : null}
    </Fragment>
  );
}
