/** @jsxImportSource @emotion/react */

import { Fragment, useMemo } from "react";
import Typo from "src/components/Typo";
import { DateFieldController } from "src/utilities/Editor2/DateFieldController";
import Form from "src/utilities/Editor2/Form";
import { required } from "src/utilities/Editor2/useValidate";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export type Step3Data = {
  startAt: string;
  endAt: string;
};

type Step3Props = {
  onSubmit: (data: Step3Data) => any;
};

export default function Step3(props: Step3Props) {
  const { onSubmit } = props;

  const startField = useMemo(() => {
    return new DateFieldController({
      label: "Début du challenge",
      initialValue: null,
      validation: required,
    });
  }, []);

  const endField = useMemo(() => {
    return new DateFieldController({
      label: "Fin du challenge",
      initialValue: null,
      validation: required,
    });
  }, []);

  const onSubmitForm = useSubmitCallback(
    () => {
      onSubmit({
        startAt: startField.validate(),
        endAt: endField.validate(),
      });
    },
    {},
    []
  );

  return (
    <Fragment>
      <Typo typo="subtitle">Etape 3/4 : Dates</Typo>
      <Form onSubmit={onSubmitForm}>
        {startField.render()}
        {endField.render()}
      </Form>
    </Fragment>
  );
}
