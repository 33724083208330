import { useMemo } from "react";
import SensorsModel from "src/models/SensorsModel";
import Editor2 from "src/utilities/Editor2";
import { NumberFieldController } from "src/utilities/Editor2/NumberFieldController";
import { SwitchFieldController } from "src/utilities/Editor2/SwitchFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import useSubmitCallback from "src/utilities/useSubmitCallback";

interface ChallengeSensorsProps {
  sensors: SensorsModel;
  onUpdate: (sensors: SensorsModel) => any;
}

export default function ChallengeSensors(props: ChallengeSensorsProps) {
  const { sensors, onUpdate } = props;

  const garminField = useMemo(
    () =>
      new SwitchFieldController({
        label: "Garmin",
        initialValue: sensors.garmin,
        validation: required,
      }),
    []
  );

  const stravaField = useMemo(
    () =>
      new SwitchFieldController({
        label: "Strava",
        initialValue: sensors.strava !== null,
        validation: required,
      }),
    []
  );

  const stravaClientIdField = useMemo(
    () =>
      new TextFieldController({
        label: "Client ID",
        initialValue: sensors.strava ? sensors.strava.client_id : null,
        validation: required,
      }),
    []
  );

  const stravaClientSecretField = useMemo(
    () =>
      new TextFieldController({
        label: "Client secret",
        initialValue: sensors.strava ? sensors.strava.client_secret : null,
        validation: required,
      }),
    []
  );

  const stravaLimitField = useMemo(
    () =>
      new NumberFieldController({
        label: "Nombre maximal d'utilisateurs",
        initialValue: sensors.strava ? sensors.strava.maximum_user_count : null,
        validation: required,
        unit: (v) => (v >= 1 ? " utilisateurs" : " utilisateur"),
      }),
    []
  );

  const suuntoField = useMemo(
    () =>
      new SwitchFieldController({
        label: "Suunto",
        initialValue: sensors.suunto,
        validation: required,
      }),
    []
  );

  const polarField = useMemo(
    () =>
      new SwitchFieldController({
        label: "Polar",
        initialValue: sensors.polar,
        validation: required,
      }),
    []
  );

  const fitbitField = useMemo(
    () =>
      new SwitchFieldController({
        label: "Fitbit",
        initialValue: sensors.fitbit,
        validation: required,
      }),
    []
  );

  const onSubmit = useSubmitCallback(
    () => {
      const garmin = garminField.validate();
      const suunto = suuntoField.validate();
      const polar = polarField.validate();
      const fitbit = fitbitField.validate();
      const stravaEnabled = stravaField.validate();
      let strava: SensorsModel["strava"] = null;
      if (stravaEnabled) {
        const clientId = stravaClientIdField.validate();
        const clientSecret = stravaClientSecretField.validate();
        const limit = stravaLimitField.validate();
        strava = {
          client_id: clientId,
          client_secret: clientSecret,
          maximum_user_count: limit,
        };
      }
      onUpdate({ garmin, suunto, polar, fitbit, strava });
    },
    { successMessage: "Enregistré !" },
    [onUpdate]
  );

  return (
    <Editor2.Form onSubmit={onSubmit}>
      {garminField.render()}
      {stravaField.render()}
      {stravaField.useValue() ? (
        <Editor2.SubForm>
          {stravaClientIdField.render()}
          {stravaClientSecretField.render()}
          {stravaLimitField.render()}
        </Editor2.SubForm>
      ) : null}
      {suuntoField.render()}
      {polarField.render()}
      {fitbitField.render()}
    </Editor2.Form>
  );
}
