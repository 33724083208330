import { useEffect, useState } from "react";

type Deferred<TValue> =
  | { state: "idling"; value: undefined }
  | { state: "pending"; value: undefined }
  | { state: "resolved"; value: TValue; reloading: boolean }
  | { state: "rejected"; value: undefined; error: any };

export default function useDeferred<TValue>(
  promise: Promise<TValue>,
  keepResult?: boolean
) {
  const [deferred, setDeferred] = useState<Deferred<TValue>>({
    state: "idling",
    value: undefined,
  });

  useEffect(() => {
    const i = setTimeout(() => {
      if (keepResult) {
        if (deferred.state !== "resolved") {
          setDeferred({
            state: "pending",
            value: undefined,
          });
        } else {
          setDeferred({ ...deferred, reloading: true });
        }
      } else {
        setDeferred({
          state: "pending",
          value: undefined,
        });
      }
    }, 0);
    promise.then(
      (value) => {
        clearTimeout(i);
        setDeferred((current) => ({
          state: "resolved",
          value,
          reloading: false,
        }));
      },
      (error) => {
        clearTimeout(i);
        setDeferred({
          state: "rejected",
          error,
          value: undefined,
        });
      }
    );
  }, [promise]);

  return deferred;
}
