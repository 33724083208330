import { useCallback, useMemo } from "react";
import QuizAnswerModel from "src/models/QuizAnswerModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { I18nFieldController } from "src/utilities/Editor2/I18nFieldController";
import { SwitchFieldController } from "src/utilities/Editor2/SwitchFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Toasts from "src/utilities/Toasts";
import Updates from "src/utilities/Updates";

interface QuizAnswerEditorProps {
  answer: QuizAnswerModel | null;
  onSubmit: (updates: Updates<QuizAnswerModel>) => any;
  onDelete?: () => any;
}
export default function QuizAnswerEditor(props: QuizAnswerEditorProps) {
  const { answer, onSubmit, onDelete } = props;

  const challenge = useChallenge();

  const answerField = useMemo(
    () =>
      new I18nFieldController({
        locales: challenge.locales,
        getController: (l) =>
          new TextFieldController({
            label: "Réponse",
            initialValue: answer ? answer.answer[l] : null,
            validation: required,
          }),
      }),
    []
  );

  const validField = useMemo(
    () =>
      new SwitchFieldController({
        label: "Bonne réponse",
        initialValue: answer ? answer.valid : null,
        validation: required,
      }),
    []
  );

  const onSubmitEditor = useCallback(() => {
    try {
      const updates: Updates<QuizAnswerModel> = {
        answer: answerField.validate(),
        valid: validField.validate(),
      };
      onSubmit(updates);
    } catch (err) {
      Toasts.error(err);
    }
  }, [onSubmit]);

  return (
    <Editor2.Form onSubmit={onSubmitEditor} onDelete={onDelete}>
      {answerField.render()}
      {validField.render()}
    </Editor2.Form>
  );
}
