import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import DataTable2 from "src/components/DataTable2";
import DelayedView from "src/components/DelayedView";
import MissionModel from "src/models/MissionModel";
import MissionPerformerModel from "src/models/MissionPerformerModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";

export default function MissionResults() {
  const challengeUuid = useChallenge().uuid;
  const missionUuid = Navigation.useParam("missionUuid");

  const { platform } = Services.use();

  const load = useMemo(async () => {
    return Promise.all([
      await platform.get<MissionModel>(
        `/support/challenges/${challengeUuid}/missions/${missionUuid}`
      ),
      await platform.get<MissionPerformerModel[]>(
        `/support/challenges/${challengeUuid}/missions/${missionUuid}/results`
      ),
    ]);
  }, [challengeUuid, missionUuid]);

  return (
    <DelayedView promise={load}>
      {([mission, performers]) => {
        return <MissionEditionView mission={mission} performers={performers} />;
      }}
    </DelayedView>
  );
}

interface MissionEditionViewProps {
  mission: MissionModel;
  performers: MissionPerformerModel[];
}

function MissionEditionView(props: MissionEditionViewProps) {
  const { mission, performers } = props;
  console.log(mission);

  const columns = DataTable2.useColumns<MissionPerformerModel>([
    DataTable2.columns.number({
      id: "rank",
      accessor: (p) => p.rank,
      header: "Rang",
    }),
    DataTable2.columns.string({
      id: "email",
      accessor: (p) => p.email,
      header: "Email",
    }),
    DataTable2.columns.string({
      id: "firstName",
      accessor: (p) => p.firstName,
      header: "Prénom",
    }),
    DataTable2.columns.string({
      id: "lastName",
      accessor: (p) => p.lastName,
      header: "Nom",
    }),
    DataTable2.columns.date({
      id: "end",
      accessor: (p) => (p.achievedAt === null ? null : new Date(p.achievedAt)),
      header: "Mission terminée le",
    }),
    {
      id: "endTimestamp",
      header: "Mission terminée le (TS)",
      accessor: (p) =>
        p.achievedAt === null ? null : new Date(p.achievedAt).valueOf(),
      exportableValue: (v) => (v === null ? "-" : v.toString()),
    },
    DataTable2.columns.string({
      id: "gender",
      accessor: (p) =>
        p.gender === "male" ? "Homme" : p.gender === "female" ? "Femme" : null,
      header: "Genre",
    }),
    DataTable2.columns.string({
      id: "group",
      accessor: (p) => p.groupName,
      header: "Groupe",
    }),
    DataTable2.columns.string({
      id: "subGroup",
      accessor: (p) => p.subGroupName,
      header: "Sous-groupe",
    }),
  ]);

  const getExportName = useCallback(
    (nb: number) => {
      return `Résultats de mission - ${
        mission.name["fr-FR"]
      } - ${nb} ligne(s) - ${dayjs(new Date()).format(
        "YYYY-MM-DD HH_mm_ss ZZ"
      )}`;
    },
    [mission]
  );

  return (
    <DataTable2
      data={performers}
      columns={columns}
      getExportName={getExportName}
    />
  );
}
