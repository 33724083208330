/**
 * @deprecated : Use Unit instead
 */
enum BaseMetric {
  STEPS_RAW = "STEPS_RAW",
  KILOMETERS = "KILOMETERS",
  POINTS = "POINTS",
}

export enum Unit {
  STEPS_RAW = "STEPS_RAW",
  KILOMETERS = "KILOMETERS",
  POINTS = "POINTS",
}

export default BaseMetric;
