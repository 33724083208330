/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import Switch from "react-switch";
import Button from "src/components/Button";
import Clickable from "src/components/Clickable";
import NullBadge from "src/components/NullBadge";
import Spacer from "src/components/Spacer";
import EditorContainer2 from "./EditorContainer2";
import FieldController, { FieldConfig } from "./FieldController";

type SwitchFieldValue = boolean | null;

type SwitchFieldControllerConfig<ValidValue extends SwitchFieldValue> =
  FieldConfig<SwitchFieldValue, ValidValue> & {
    nullMeaning?: string;
  };

export class SwitchFieldController<
  ValidValue extends SwitchFieldValue
> extends FieldController<SwitchFieldValue, ValidValue> {
  constructor(readonly config: SwitchFieldControllerConfig<ValidValue>) {
    super(config);
  }

  render(): React.ReactElement {
    return React.createElement(SwitchField, { controller: this });
  }
}

export function SwitchField<ValidValue extends SwitchFieldValue>(props: {
  controller: SwitchFieldController<ValidValue>;
}) {
  const controller = props.controller;
  const { nullMeaning } = controller.config;
  const disabled = controller.isDisabled();
  const value = controller.useValue();
  return (
    <EditorContainer2 controller={controller}>
      <div css={css({ display: "flex" })}>
        {value === null ? (
          <Clickable onClick={() => controller.setValue(true)}>
            <NullBadge text={nullMeaning} />
          </Clickable>
        ) : (
          <Switch
            checked={value}
            onChange={(v) => {
              console.log(v);
              controller.setValue(v);
            }}
            disabled={disabled}
          />
        )}
        <Spacer grow />
        {value !== null && !disabled ? (
          <Button label="-" onClick={() => controller.setValue(null)} />
        ) : null}
      </div>
    </EditorContainer2>
  );
}
