import { Fragment, useMemo } from "react";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import ChallengeModel from "src/models/ChallengeModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { SwitchFieldController } from "src/utilities/Editor2/SwitchFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Services from "src/utilities/Services";
import useNow from "src/utilities/useNow";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function Rankings() {
  const challenge = useChallenge();
  const { platform } = Services.use();

  const [codesValidity, reloadCodes] = useNow();

  const showManagedGroupsGlobalRankingField = useMemo(() => {
    return new SwitchFieldController({
      label: "Afficher le classement des groupes supervisés",
      initialValue: challenge.showManagedGroupsGlobalRanking,
      help: "",
      validation: required,
    });
  }, []);

  const showFreeGroupsGlobalRankingField = useMemo(() => {
    return new SwitchFieldController({
      label: "Afficher le classement des groupes libres",
      initialValue: challenge.showFreeGroupsGlobalRanking,
      help: 'Ce classement sera nommé "Autres groupes"',
      validation: required,
    });
  }, []);

  const showAllGroupsGlobalRankingField = useMemo(() => {
    return new SwitchFieldController({
      label:
        "Afficher le classement de l'ensemble des groupes (supervisés et libres confondus)",
      initialValue: challenge.showAllGroupsGlobalRanking,
      help: 'Ce classement sera nommé "Tous les groupes"',
      validation: required,
    });
  }, []);

  const showTeamsGlobalRankingField = useMemo(() => {
    return new SwitchFieldController({
      label: "Afficher le classement des équipes",
      initialValue: challenge.showTeamsGlobalRanking,
      help: "Il s'agit ici du classelement des l'ensemble des équipes, tous groupes confondus",
      validation: required,
    });
  }, []);

  const showTeamsInternalRankingField = useMemo(() => {
    return new SwitchFieldController({
      label: "Afficher le classement des équipes",
      initialValue: challenge.showTeamsInternalRanking,
      help: "Il s'agit ici du classelement des équipes du groupe de l'utilisateur",
      validation: required,
    });
  }, []);

  const showGroupSizeRankingField = useMemo(() => {
    return new SwitchFieldController({
      label: "Activer les classements en nombre de personnes par groupe",
      initialValue: challenge.showGroupSizeRanking,
      validation: required,
    });
  }, []);

  const showTeamSizeRankingField = useMemo(() => {
    return new SwitchFieldController({
      label: "Activer les classements en nombre de personnes par équipe",
      initialValue: challenge.showTeamSizeRanking,
      validation: required,
    });
  }, []);

  const onSubmitGlobal = useSubmitCallback(
    async () => {
      const updates: Partial<ChallengeModel> = {
        showManagedGroupsGlobalRanking:
          showManagedGroupsGlobalRankingField.validate(),
        showFreeGroupsGlobalRanking:
          showFreeGroupsGlobalRankingField.validate(),
        showAllGroupsGlobalRanking: showAllGroupsGlobalRankingField.validate(),
        showTeamsGlobalRanking: showTeamsGlobalRankingField.validate(),
      };
      await platform.patch(`/support/challenges/${challenge.uuid}`, updates);
    },
    { successMessage: "Validé" },
    []
  );

  const onSubmitFree = useSubmitCallback(
    async () => {
      const updates: Partial<ChallengeModel> = {
        showTeamsInternalRanking: showTeamsInternalRankingField.validate(),
      };
      await platform.patch(`/support/challenges/${challenge.uuid}`, updates);
    },
    { successMessage: "Validé" },
    []
  );

  const onSubmitSizes = useSubmitCallback(
    async () => {
      const updates: Partial<ChallengeModel> = {
        showGroupSizeRanking: showGroupSizeRankingField.validate(),
        showTeamSizeRanking: showTeamSizeRankingField.validate(),
      };
      await platform.patch(`/support/challenges/${challenge.uuid}`, updates);
    },
    { successMessage: "Validé" },
    []
  );

  return (
    <Fragment>
      <Typo typo="title">Classement global</Typo>
      <Editor2.Form onSubmit={onSubmitGlobal}>
        {showManagedGroupsGlobalRankingField.render()}
        {showFreeGroupsGlobalRankingField.render()}
        {showAllGroupsGlobalRankingField.render()}
        {showTeamsGlobalRankingField.render()}
      </Editor2.Form>
      <Spacer />
      <Typo typo="title">Classement interne</Typo>
      <Editor2.Form onSubmit={onSubmitFree}>
        {showTeamsInternalRankingField.render()}
      </Editor2.Form>
      <Spacer />
      <Typo typo="title">Classements en nombre de personnes</Typo>
      <Editor2.Form onSubmit={onSubmitSizes}>
        {showGroupSizeRankingField.render()}
        {showTeamSizeRankingField.render()}
      </Editor2.Form>
    </Fragment>
  );
}
