import dayjs from "dayjs";
import { max, min, sortBy, sumBy } from "lodash";
import { Fragment, useMemo } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import DelayedView from "src/components/DelayedView";
import StepModel from "src/models/StepModel";
import { DateFieldController } from "src/utilities/Editor2/DateFieldController";
import List from "src/utilities/List";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";

export default function ParticipantSteps() {
  const { platform } = Services.use();
  const account = Navigation.useParam("participant");

  const load = useMemo(() => {
    return platform.get<Array<StepModel>>(`/support/steps`, {
      params: { accountUuid: account },
    });
  }, [account]);

  return (
    <DelayedView promise={load}>
      {(steps) => <ParticipantStepsView steps={steps} />}
    </DelayedView>
  );
}

type ParticipantStepsViewProps = {
  steps: Array<StepModel>;
};

function ParticipantStepsView(props: ParticipantStepsViewProps) {
  const { steps } = props;

  const dateField = useMemo(() => {
    return new DateFieldController({
      label: "Date",
      initialValue: dayjs().startOf("day").toISOString(),
      dateOnly: true,
    });
  }, []);

  const date = dateField.useValue();

  const daySteps = useMemo(() => {
    if (!date) return [];
    const inDay = steps.filter((s) => {
      const start = dayjs(s.startedAt);
      const end = dayjs(s.endedAt);
      return dayjs(date).isSame(start, "day") || dayjs(date).isSame(end, "day");
    });
    const sorted = sortBy(inDay, (s) => dayjs(s.startedAt).valueOf());
    return sorted;
  }, [steps, date]);

  const perHour = useMemo(() => {
    if (!date) return [];
    const data: Array<any> = [];
    for (let i = 0; i < 24; i++) {
      const start = dayjs(date).add(i, "hours").valueOf();
      const end = dayjs(date)
        .add(i + 1, "hours")
        .valueOf();
      const hourSteps = sumBy(steps, (s) => {
        const windowStart = max([
          start,
          dayjs(s.startedAt).valueOf(),
        ]) as number;
        const windowEnd = min([end, dayjs(s.endedAt).valueOf()]) as number;
        if (windowEnd < windowStart) return 0;
        const windowDuration = Math.abs(windowEnd - windowStart);
        return (s.steps * windowDuration) / (1000 * 60 * 60);
      });
      data.push({
        time: dayjs(date).add(i, "hour").tz(dayjs.tz.guess()).format("H[h]"),
        steps: Math.round(hourSteps),
      });
    }
    return data;
  }, [date, steps]);

  return (
    <Fragment>
      {dateField.render()}
      <ResponsiveContainer height={300}>
        <BarChart data={perHour}>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Bar dataKey="steps" fill="#8884d8" isAnimationActive={false} />
          <XAxis dataKey="time" interval={0} />
          <YAxis />
        </BarChart>
      </ResponsiveContainer>
      <List
        items={daySteps}
        keyExtractor={(s) => s.uuid}
        renderItem={(s) => (
          <List.Item
            label={`${s.steps} pas via ${s.mode}`}
            helper={`De ${dayjs(s.startedAt).format("HH:mm:ss")} à ${dayjs(
              s.endedAt
            ).format("HH:mm:ss")}`}
          />
        )}
      />
    </Fragment>
  );
}
