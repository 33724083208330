import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import DelayedView from "src/components/DelayedView";
import AccountModel from "src/models/AccountModel";
import { useChallenge } from "src/utilities/ChallengeService";
import List from "src/utilities/List";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";

export default function Participants() {
  const { platform } = Services.use();
  const challenge = useChallenge();
  const load = useMemo(() => {
    return platform.get<Array<AccountModel>>(
      `/support/challenges/${challenge.uuid}/accounts`
    );
  }, [challenge.uuid]);

  const exportAccount = useCallback((account: AccountModel) => {
    return [
      { column: "Adresse email", value: account.email },
      { column: "Prénom", value: account.firstName },
      { column: "Nom", value: account.lastName },
      { column: "Sexe", value: displayGender(account.gender) },
      { column: "Groupe", value: account.groupName || "-" },
      { column: "Sous-groupe", value: account.subgroupName || "-" },
      {
        column: "Creation du compte sur l'app",
        value: dayjs(account.createdAt).format("LLLL"),
      },
    ];
  }, []);

  return (
    <DelayedView promise={load}>
      {(accounts) => (
        <List
          items={accounts}
          keyExtractor={(a) => a.uuid}
          renderCsvLine={exportAccount}
          renderItem={(a) => (
            <List.Item
              label={`${a.firstName} ${a.lastName}`}
              helper={a.email}
              to={Navigation.getPath("Participant", {
                params: { participant: a.uuid },
              })}
            />
          )}
        />
      )}
    </DelayedView>
  );
}

function displayGender(g: "male" | "female" | null) {
  if (g === "male") return "Homme";
  else if (g === "female") return "Femme";
  else return "-";
}
