/** @jsxImportSource @emotion/react */

import { Fragment, useMemo } from "react";
import Button from "src/components/Button";
import DelayedView from "src/components/DelayedView";
import Typo from "src/components/Typo";
import ChallengeModel from "src/models/ChallengeModel";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import { Step1Data } from "./Step1";
import { Step2Data } from "./Step2";
import { Step3Data } from "./Step3";
import { Step4Data } from "./Step4";

export type FinalStepData = Step1Data | Step2Data | Step3Data | Step4Data;

type Step4Props = {
  data: FinalStepData;
};

export default function FinalStep(props: Step4Props) {
  const { data } = props;
  const { platform } = Services.use();

  const creation = useMemo(async () => {
    const challenge = await platform.post<ChallengeModel>(
      "/support/challenges",
      data
    );
    return challenge;
  }, []);

  return (
    <Fragment>
      <DelayedView promise={creation}>
        {(challenge) => (
          <Fragment>
            <Typo typo="subtitle">Challenge créé</Typo>
            <Button
              label="Continuer"
              to={Navigation.getPath("ChallengeHome", {
                params: { challengeUuid: challenge.uuid },
              })}
            />
          </Fragment>
        )}
      </DelayedView>
    </Fragment>
  );
}
