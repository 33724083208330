/** @jsxImportSource @emotion/react */

import { useNavigate } from "react-router-dom";
import MetricEditor from "src/editors/MetricEditor";
import MetricModel from "src/models/MetricModel";
import {
  useChallenge,
  useChallengeService,
} from "src/utilities/ChallengeService";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function ChallengeMetricsCreation() {
  const challenge = useChallenge();
  const challengeService = useChallengeService();
  const { platform } = Services.use();
  const navigate = useNavigate();

  const onSubmit = useSubmitCallback(
    async (updates: Updates<MetricModel>) => {
      const metric = await platform.post<MetricModel>(
        `/support/challenges/${challenge.uuid}/metrics`,
        updates
      );
      challengeService.set({
        ...challenge,
        metrics: [...challenge.metrics, metric],
      });
      navigate(
        Navigation.getPath("ChallengeMetricsEdition", {
          params: { metric: metric.uuid },
        }),
        { replace: true }
      );
    },
    {},
    []
  );

  return <MetricEditor metric={null} onSubmit={onSubmit} />;
}
