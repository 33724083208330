import dayjs from "dayjs";
import R from "./R";

export interface TokenPair {
  accessToken: string;
  refreshToken: string;
}

class WhoAmI extends R {
  private state: TokenPair | null = null;

  init() {
    this.state = this.read();
    this.notify();
    if (this.state != null) {
      const token = parseJwt(this.state.accessToken);
      if (token && token.exp) {
        const expirationDate = new Date(token.exp * 1000);
        if (dayjs().isAfter(expirationDate)) {
          // this.logout();
        }
      }
    }
    return this;
  }

  setTokens(pair: TokenPair | null) {
    this.state = pair;
    this.write(pair);
    this.notify();
  }

  read() {
    const state = localStorage.getItem("auth");
    if (state) return JSON.parse(state) as TokenPair;
    else return null;
  }

  write(pair: TokenPair | null) {
    if (pair != null) localStorage.setItem("auth", JSON.stringify(pair));
    else localStorage.removeItem("auth");
  }

  getTokens() {
    return this.state;
  }

  useIsLoggedIn() {
    return this.useSelector(() => !(this.state == null));
  }

  // async login (tokens: TokenPair) {
  //   await post(null, 'auth', false, { accessToken: tokens.accessToken })
  //   this.setTokens(tokens)
  // }

  // async logout () {
  //   this.setTokens(null)
  //   await post(null, 'auth', false)
  // }
}

export default WhoAmI;

function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
