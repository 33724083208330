/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useCallback, useState } from "react";
import Css from "../Css";
import EditorContainer2 from "./EditorContainer2";
import FieldController, { FieldConfig } from "./FieldController";
import Input from "./Input";

type TextFieldValue = string | null;

type TextFieldConfig<ValidValue extends TextFieldValue> = FieldConfig<
  TextFieldValue,
  ValidValue
> & {
  placeholder?: string;
  sensitive?: boolean;
  transformer?: (s: string) => string;
};

export class TextFieldController<
  ValidValue extends TextFieldValue
> extends FieldController<TextFieldValue, ValidValue> {
  readonly placeholder: string | null;
  readonly sensitive: boolean;
  readonly transformer: ((s: string) => string) | null;
  constructor(config: TextFieldConfig<ValidValue>) {
    super(config);
    this.placeholder = config.placeholder || null;
    this.sensitive = config.sensitive || false;
    this.transformer = config.transformer || null;
  }

  render(): React.ReactElement {
    return React.createElement(TextField, { controller: this });
  }
}

export function TextField<ValidValue extends TextFieldValue>(props: {
  controller: TextFieldController<ValidValue>;
}) {
  const controller = props.controller;
  const sensitive = controller.sensitive;
  const transformer = controller.transformer;
  const placeholder = controller.placeholder;
  const disabled = controller.isDisabled();

  const inputCss = css(Css.inputReset, {
    flexGrow: 1,
  });

  const [value, setValue] = useState(() => controller.getValue());
  controller.useValueModifier(value);

  const onValueChange = useCallback(
    (v: string | null) => {
      if (v === null) setValue(null);
      else if (transformer) setValue(transformer(v));
      else setValue(v);
    },
    [transformer]
  );

  return (
    <EditorContainer2 controller={controller}>
      <Input
        type={sensitive ? "password" : "text"}
        value={value || ""}
        css={inputCss}
        onValueChange={onValueChange}
        disabled={disabled}
        placeholder={placeholder || ""}
      />
    </EditorContainer2>
  );
}
