import { useCallback, useMemo } from "react";
import PolicyModel from "src/models/PolicyModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { HtmlFieldController } from "src/utilities/Editor2/HtmlFieldController";
import { I18nFieldController } from "src/utilities/Editor2/I18nFieldController";
import { SwitchFieldController } from "src/utilities/Editor2/SwitchFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Toasts from "src/utilities/Toasts";
import Updates from "src/utilities/Updates";

interface PolicyEditorProps {
  policy: PolicyModel | null;
  onSubmit: (updates: Updates<PolicyModel>) => any;
  onDelete?: () => any;
}
export default function PolicyEditor(props: PolicyEditorProps) {
  const { policy, onSubmit, onDelete } = props;

  const challenge = useChallenge();

  const labelField = useMemo(
    () =>
      new I18nFieldController({
        locales: challenge.locales,
        getController: (l) =>
          new TextFieldController({
            label: "Libellé",
            initialValue: policy ? policy.label[l] : null,
            validation: required,
          }),
      }),
    []
  );

  const documentField = useMemo(
    () =>
      new I18nFieldController({
        locales: challenge.locales,
        getController: (l) =>
          new HtmlFieldController({
            label: "Document",
            initialValue: policy ? policy.document[l] : null,
            validation: required,
          }),
      }),
    []
  );

  // const showOnHomePageField = useMemo(
  //   () =>
  //     new SwitchFieldController({
  //       label: "Afficher sur le page d'accueil de l'app",
  //       initialValue: policy ? policy.showOnHomePage : false,
  //       validation: required,
  //       disabled: true,
  //       help: "Option à venir",
  //     }),
  //   []
  // );

  const mustBeCheckedOnSigninField = useMemo(
    () =>
      new SwitchFieldController({
        label:
          "Contraindre les utilisateurs à prendre connaissance de ce document",
        initialValue: policy ? policy.mustBeCheckedOnSignin : false,
        validation: required,
        help: "Cette option force les utilisateurs à prendre connaissance du texte avant d'accéder à l'application.",
      }),
    []
  );

  const onSubmitEditor = useCallback(() => {
    try {
      const updates: Updates<PolicyModel> = {
        label: labelField.validate(),
        document: documentField.validate(),
        showOnHomePage: false,
        mustBeCheckedOnSignin: mustBeCheckedOnSigninField.validate(),
      };
      console.log(updates);
      onSubmit(updates);
    } catch (err) {
      Toasts.error(err);
    }
  }, [onSubmit]);

  return (
    <Editor2.Form onSubmit={onSubmitEditor} onDelete={onDelete}>
      {labelField.render()}
      {documentField.render()}
      {mustBeCheckedOnSigninField.render()}
    </Editor2.Form>
  );
}
