export enum GroupType {
  PERSONAL = "PERSONAL",
  COMPANY = "COMPANY",
}

export default interface GroupModel {
  uuid: string;
  name: string;
  type: GroupType;
  sizeLimit: number | null;
}
