/** @jsxImportSource @emotion/react */

import { Fragment, useState } from "react";
import Typo from "src/components/Typo";
import FinalStep from "./FinalStep";
import Step1, { Step1Data } from "./Step1";
import Step2, { Step2Data } from "./Step2";
import Step3, { Step3Data } from "./Step3";
import Step4, { Step4Data } from "./Step4";

export default function ChallengeCreation() {
  const [step1, setStep1] = useState<Step1Data | null>(null);
  const [step2, setStep2] = useState<Step2Data | null>(null);
  const [step3, setStep3] = useState<Step3Data | null>(null);
  const [step4, setStep4] = useState<Step4Data | null>(null);

  let step;
  if (!step1) step = <Step1 onSubmit={setStep1} />;
  else if (!step2) step = <Step2 onSubmit={setStep2} locales={step1.locales} />;
  else if (!step3) step = <Step3 onSubmit={setStep3} />;
  else if (!step4) step = <Step4 onSubmit={setStep4} />;
  else step = <FinalStep data={{ ...step1, ...step2, ...step3, ...step4 }} />;

  return (
    <Fragment>
      <Typo typo="title">Nouveau challenge</Typo>
      {step}
    </Fragment>
  );
}
