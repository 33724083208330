/** @jsxImportSource @emotion/react */

import { Fragment, useMemo } from "react";
import DelayedView from "src/components/DelayedView";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import RoomModel from "src/models/RoomModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { SwitchFieldController } from "src/utilities/Editor2/SwitchFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import List from "src/utilities/List";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function ChatRooms() {
  const challenge = useChallenge();
  const { platform, chat } = Services.use();

  const rooms = useMemo(async () => {
    return await chat.get<Array<RoomModel>>(
      `/support/challenges/${challenge.uuid}/rooms`
    );
  }, []);

  const onSubmit = useSubmitCallback(async () => {
    const chatActivated = enabledField.validate();
    await platform.patch(`/support/challenges/${challenge.uuid}`, {
      chatActivated,
    });
    window.location.reload();
  });

  const enabledField = useMemo(
    () =>
      new SwitchFieldController({
        label: "Activer le chat",
        initialValue: challenge.chatActivated,
        validation: required,
      }),
    []
  );

  return (
    <Fragment>
      <Typo typo="title">Réglages du chat</Typo>
      <Editor2.Form onSubmit={onSubmit}>{enabledField.render()}</Editor2.Form>
      <Spacer />
      <Typo typo="title">Canaux de discussion</Typo>

      <Spacer />
      <DelayedView promise={rooms}>
        {(rooms) => (
          <List
            items={rooms}
            keyExtractor={(r) => r.id}
            renderItem={(r) => (
              <List.Item
                label={r.name}
                to={Navigation.getPath("ChatRoom", { params: { room: r.id } })}
              />
            )}
          />
        )}
      </DelayedView>
    </Fragment>
  );
}
