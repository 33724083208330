import { Fragment, useMemo } from "react";
import Button from "src/components/Button";
import Info from "src/components/Info";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import { useChallenge } from "src/utilities/ChallengeService";
import Form from "src/utilities/Editor2/Form";
import { ListFieldController } from "src/utilities/Editor2/ListFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Services from "src/utilities/Services";
import Toasts from "src/utilities/Toasts";
import useBooleanState from "src/utilities/useBooleanState";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function Cleaning() {
  return (
    <Fragment>
      <Anonymisation />
      <Spacer />
      <FitbitUnlinking />
    </Fragment>
  );
}

function Anonymisation() {
  const challenge = useChallenge();
  const { platform } = Services.use();

  const exceptionsField = useMemo(() => {
    const values = [
      "avena-event.fr",
      "adae.app",
      "valentin@valentin.consulting",
    ];
    return new ListFieldController({
      initialValue: values,
      getController: (i) =>
        new TextFieldController({
          label: "Adresses email à ne pas anonymiser",
          initialValue: i === null ? "" : values[i],
          help: "Vous pouvez éviter l'anonymisation de certaines adresses email ou de certains groupes d'adresses emails (partie après le @)",
          validation: required,
        }),
    });
  }, []);

  const onSubmit = useSubmitCallback(
    async () => {
      const exceptions = exceptionsField.validate();
      const result = await platform.post<{ count: number }>(
        `/support/challenges/${challenge.uuid}/accounts/all/anonymisations`,
        { exceptions }
      );
      Toasts.success(`${result.count} compte(s) anonymisé(s) !`);
    },
    {
      confirmationMessage: "Opération sensible et définitive ! Confirmez.",
    },
    []
  );

  return (
    <Fragment>
      <Typo typo="title">Anonymisation des comptes utilisateur</Typo>
      <Typo>
        La procédure d'anonymisation applique les changements suivants :
        <ul>
          <li>
            Les adresses-email seront remplacées par des adresse email
            aléatoires (xxxx@adae.app), de telle manière à ce que les
            utilisateurs ne puissent plus se connecter.
          </li>
          <li>
            Les prénoms et noms des utilisateurs seront remplacés par le symbole
            👤.
          </li>
          <li>
            Les utilisateurs seront déconnectés (dans un délai maximal de 1
            heure). Ils ne pourront plus accéder à leur compte. S'ils tentent de
            se connecter de nouveau, ils seront ammenés à créer un nouveau
            compte.
          </li>
          <li>
            Les performances restents inchangées, mais ne pourront plus être
            rattachées aux utilisateurs
          </li>
          <li>
            Les groupes, équipes, codes et toutes les autres configurations
            restent inchangées
          </li>
        </ul>
        <Info
          type="danger"
          message="L'anonymisation des données est définitive"
        />
      </Typo>
      <Spacer />
      <Form onSubmit={onSubmit} submitLabel="Lancer l'anonymisation">
        {exceptionsField.render()}
      </Form>
    </Fragment>
  );
}

function FitbitUnlinking() {
  const challenge = useChallenge();
  const { tracker } = Services.use();

  const [unlinking, setUnlinking] = useBooleanState(false);

  const unlinkFitbitAccounts = useSubmitCallback(
    async () => {
      try {
        setUnlinking.toTrue();
        await tracker.delete(
          `/support/fitbit/challenge/${challenge.uuid}/links`
        );
      } finally {
        setUnlinking.toFalse();
      }
    },
    {
      successMessage: "Opération terminée",
      confirmationMessage: "Opération sensible ! Confirmez.",
    },
    []
  );

  return (
    <Fragment>
      <Typo typo="title">Nettoyage et fin de challenge</Typo>
      <Button
        label={"Détacher les comptes Fitbit"}
        onClick={unlinkFitbitAccounts}
        loading={unlinking}
      />
    </Fragment>
  );
}
