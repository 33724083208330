import { toast } from "react-toastify";
import getErrorMessage from "./getErrorMessage";

function success(message: string) {
  toast.success(message, {
    autoClose: 1000,
  });
}

function error(error: any) {
  toast.error(getErrorMessage(error), {
    autoClose: 5000,
  });
}

const Toasts = { success, error };

export default Toasts;
