import { DependencyList, useCallback } from "react";
import Dialogs from "./Dialogs";
import Toasts from "./Toasts";

type SubmitCallbackConfig = {
  successMessage?: string;
  confirmationMessage?: string;
};

export default function useSubmitCallback<TArgs extends Array<any>>(
  fn: (...args: TArgs) => any,
  config: SubmitCallbackConfig = {},
  deps: DependencyList = []
) {
  const successMessage = config.successMessage;
  const confirmationMessage = config.confirmationMessage || null;

  return useCallback(
    async (...args: TArgs) => {
      try {
        if (confirmationMessage) {
          if (!(await Dialogs.confirm(confirmationMessage))) return;
        }
        await fn(...args);
        if (successMessage) Toasts.success(successMessage);
      } catch (err) {
        Toasts.error(err);
      }
    },
    [successMessage, confirmationMessage, ...deps]
  );
}
