import { DependencyList, useEffect } from "react";
import R from "../R";
import { Validation, validate } from "./useValidate";

export type FieldConfig<Value, ValidValue extends Value> = {
  initialValue: Value;
  label?: string;
  help?: string;
  disabled?: boolean;
  hidden?: boolean;
  validation?: Validation<Value, ValidValue>;
};

export default abstract class FieldController<Value, ValidValue extends Value> {
  readonly id = `field-${Math.random().toString().split(".")[1]}`;
  readonly r = new R();
  private value: Value;
  private valueFn: (() => Value) | null = null;
  private label: string | null;
  private help: string | null;
  private disabled: boolean;
  private hidden: boolean;
  private validation: Validation<Value, ValidValue> | null;
  private validated: boolean = false;
  private emptyValue: any = null;

  constructor(config: FieldConfig<Value, ValidValue>) {
    this.value = config.initialValue;
    this.label = config.label || null;
    this.help = config.help || null;
    this.disabled = config.disabled || false;
    this.hidden = config.hidden || false;
    this.validation = config.validation || null;
  }

  // Value

  setValueFn(fn: () => Value) {
    this.valueFn = fn;
    this.r.notify();
  }

  getValue() {
    if (this.valueFn) return this.valueFn();
    else return this.value;
  }

  setValue(value: Value) {
    if (this.valueFn) throw new Error("Cannot set value as valueFn is set");
    this.value = value;
    this.r.notify();
  }

  useValueModifier(v: Value) {
    useEffect(() => {
      if (this.valueFn) throw new Error("Cannot set value as valueFn is set");
      this.value = v;
      this.r.notify();
    }, [v]);
  }

  useValue(deps: DependencyList = []) {
    return this.r.useSelector(() => this.getValue(), [...deps, this.id]);
  }

  // Label

  getLabel() {
    return this.label;
  }

  // Help

  getHelp() {
    return this.help;
  }

  // Hidden

  setHidden(hidden: boolean) {
    this.hidden = hidden;
    this.r.notify();
  }

  isHidden() {
    return this.hidden;
  }

  // Disabled

  setDisabled(disabled: boolean) {
    this.disabled = disabled;
    this.r.notify();
  }

  isDisabled() {
    return this.disabled;
  }

  // Validation

  isValid(value: Value): value is ValidValue {
    if (this.validation) {
      try {
        validate(value, this.validation);
        return true;
      } catch (err) {
        return false;
      }
    } else {
      return true;
    }
  }

  assertValueValidity(value: Value): asserts value is ValidValue {
    if (this.validation) validate(value, this.validation);
  }

  runValidationFn(value: Value) {
    if (this.validation) validate(value, this.validation);
  }

  getValidatedValue(): ValidValue {
    const value = this.getValue();
    this.assertValueValidity(value);
    return value;
  }

  private getValidationError() {
    try {
      this.getValidatedValue();
      return null;
    } catch (err) {
      return err;
    }
  }

  setValidated() {
    this.validated = true;
    this.r.notify();
  }

  validate(): ValidValue {
    this.setValidated();
    return this.getValidatedValue();
  }

  // Required

  setEmptyValue(emptyValue: any) {
    this.emptyValue = emptyValue;
  }

  isRequired() {
    try {
      if (!this.validation) return false;
      this.assertValueValidity(this.emptyValue);
      return false;
    } catch (err) {
      return true;
    }
  }

  useError() {
    return this.r.useSelector(() => {
      if (!this.validated) return null;
      const err = this.getValidationError();
      return err;
    }, []);
  }

  abstract render(): React.ReactElement;
}
