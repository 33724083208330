import { useCallback, useMemo } from "react";
import QuizModel from "src/models/QuizModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { DateFieldController } from "src/utilities/Editor2/DateFieldController";
import FileFieldController, {
  UploadSlot,
} from "src/utilities/Editor2/FileFieldController";
import { I18nFieldController } from "src/utilities/Editor2/I18nFieldController";
import { NumberFieldController } from "src/utilities/Editor2/NumberFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import MimeTypes from "src/utilities/MimeTypes";
import Services from "src/utilities/Services";
import Toasts from "src/utilities/Toasts";
import Updates from "src/utilities/Updates";

interface QuizEditorProps {
  quiz: QuizModel | null;
  onSubmit: (updates: Updates<QuizModel>) => any;
  onDelete?: () => any;
}
export default function QuizEditor(props: QuizEditorProps) {
  const { quiz, onSubmit, onDelete } = props;

  const challenge = useChallenge();

  const { platform } = Services.use();

  const nameField = useMemo(
    () =>
      new I18nFieldController({
        locales: challenge.locales,
        getController: (l) =>
          new TextFieldController({
            label: "Libellé",
            initialValue: quiz ? quiz.name[l] : null,
            validation: required,
          }),
      }),
    []
  );

  const startAtField = useMemo(
    () =>
      new DateFieldController({
        label: "Début du quiz",
        initialValue: quiz ? quiz.startAt : null,
        validation: required,
      }),
    []
  );

  const endAtField = useMemo(
    () =>
      new DateFieldController({
        label: "Fin du quiz",
        initialValue: quiz ? quiz.endAt : null,
        validation: required,
      }),
    []
  );

  const pointsField = useMemo(
    () =>
      new NumberFieldController({
        label: "Récompense (en points)",
        initialValue: quiz ? quiz.points : null,
        nullValueMeaning: "Pas de récompense",
        unit: (n) => (n <= 1 ? " point" : " points"),
      }),
    []
  );

  const questionField = useMemo(
    () =>
      new I18nFieldController({
        locales: challenge.locales,
        getController: (l) =>
          new TextFieldController({
            label: "Question",
            initialValue: quiz ? quiz.question[l] : null,
            validation: required,
          }),
      }),
    []
  );

  const explanationField = useMemo(
    () =>
      new I18nFieldController({
        locales: challenge.locales,
        getController: (l) =>
          new TextFieldController({
            label: "Explication de la réponse",
            initialValue: quiz ? quiz.explanation[l] : null,
            validation: required,
          }),
      }),
    []
  );

  const secondsToAnswerField = useMemo(
    () =>
      new NumberFieldController({
        label: "Temps pour réponse (en secondes)",
        initialValue: quiz ? quiz.secondsToAnswer : null,
        nullValueMeaning: "Temps illimité",
        unit: (n) => (n <= 1 ? " seconde" : " secondes"),
      }),
    []
  );

  const imageField = useMemo(
    () =>
      new FileFieldController({
        label: "Image d'illutrstion",
        initialValue: quiz ? quiz.pictureUrl : null,
        getUploadSlot: () => platform.get<UploadSlot>("/support/quiz-pictures"),
        accept: MimeTypes.images,
        validation: required,
      }),
    []
  );

  const onSubmitEditor = useCallback(() => {
    try {
      const updates: Updates<QuizModel> = {
        name: nameField.validate(),
        startAt: startAtField.validate(),
        endAt: endAtField.validate(),
        points: pointsField.validate(),
        question: questionField.validate(),
        explanation: explanationField.validate(),
        secondsToAnswer: secondsToAnswerField.validate(),
        pictureUrl: imageField.validate(),
      };
      onSubmit(updates);
    } catch (err) {
      Toasts.error(err);
    }
  }, [onSubmit]);

  return (
    <Editor2.Form onSubmit={onSubmitEditor} onDelete={onDelete}>
      {nameField.render()}
      {questionField.render()}
      {explanationField.render()}
      {startAtField.render()}
      {endAtField.render()}
      {pointsField.render()}
      {imageField.render()}
      {secondsToAnswerField.render()}
    </Editor2.Form>
  );
}
