/** @jsxImportSource @emotion/react */
import dayjs from "dayjs";
import { Fragment, useMemo } from "react";
import DelayedView from "src/components/DelayedView";
import Info from "src/components/Info";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import ChallengeTrackerModel from "src/models/ChallengeTrackerModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { DateFieldController } from "src/utilities/Editor2/DateFieldController";
import { ListFieldController } from "src/utilities/Editor2/ListFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Services from "src/utilities/Services";
import Toasts from "src/utilities/Toasts";
import useSubmitCallback from "src/utilities/useSubmitCallback";
import { during } from "./Participant";

export default function ChallengeInfos() {
  const challenge = useChallenge();

  const { tracker } = Services.use();

  const trackerChallenge = useMemo(() => {
    return tracker.get<ChallengeTrackerModel>(
      `/support/challenges/${challenge.uuid}`
    );
  }, [challenge.uuid]);

  return (
    <DelayedView promise={trackerChallenge}>
      {(tracker) => <ChallengeDatesView tracker={tracker} />}
    </DelayedView>
  );
}

type ChallengeDatesViewProps = {
  tracker: ChallengeTrackerModel;
};

function ChallengeDatesView(props: ChallengeDatesViewProps) {
  const services = Services.use();
  const { tracker } = props;
  const challenge = useChallenge();
  const uuid = challenge.uuid;

  const platformStartField = useMemo(
    () =>
      new DateFieldController({
        label: "Début du challenge (Platform)",
        initialValue: challenge.startAt,
        validation: required,
        disabled: true,
      }),
    []
  );

  const platformEndField = useMemo(
    () =>
      new DateFieldController({
        label: "Fin du challenge (Platform)",
        initialValue: challenge.endAt,
        validation: required,
        disabled: true,
      }),
    []
  );

  const trackerStartField = useMemo(
    () =>
      new DateFieldController({
        label: "Début du challenge (Tracker)",
        initialValue: tracker.startAt,
        validation: required,
        disabled: true,
      }),
    []
  );

  const trackerEndField = useMemo(
    () =>
      new DateFieldController({
        label: "Fin du challenge (Tracker)",
        initialValue: tracker.endAt,
        validation: required,
        disabled: true,
      }),
    []
  );

  const startField = useMemo(
    () =>
      new DateFieldController({
        label: "Début du challenge",
        initialValue: null,
        validation: required,
      }),
    []
  );

  const endField = useMemo(
    () =>
      new DateFieldController({
        label: "Fin du challenge",
        initialValue: null,
        validation: required,
      }),
    []
  );

  const openingField = useMemo(
    () =>
      new DateFieldController({
        label: "Ouverture du challange",
        initialValue: challenge.authorizeConnectionFrom,
        nullValueMeaning: "Dès l'apparition de l'app dans les stores",
        prefix: "A partir du ",
        help: "L'ouverture du challenge correpond au moment à partir duquel les utilisateurs peuvent se connecter au challenge, à l'aide de leur adresse email et de leur mot de passe. Si une date d'ouverture de challenge est définie, alors les utilisateurs ne pourront pas se connecter au challenge avant cette date.",
      }),
    []
  );

  const earlyAccessField = useMemo(
    () =>
      new ListFieldController({
        initialValue: challenge.earlyAccessWhiteList,
        getController: (i) => {
          return new TextFieldController({
            label: "Adresses email pouvant se connecter avant l'ouverture",
            initialValue:
              challenge.earlyAccessWhiteList && i !== null
                ? challenge.earlyAccessWhiteList[i]
                : null,
            help: "Les adresses email listées ici pourront se connecter à l'application avant l'ouverture du challenge",
            placeholder: i === null ? "Ajouter une adresse email" : "",
          });
        },
      }),
    []
  );

  const showInconsistantDatesWarn = useMemo(() => {
    if (!dayjs(challenge.startAt).isSame(tracker.startAt)) return true;
    if (!dayjs(challenge.endAt).isSame(tracker.endAt)) return true;
    return false;
  }, [challenge.startAt, challenge.endAt, tracker.startAt, tracker.endAt]);

  const rankingLockField = useMemo(
    () =>
      new DateFieldController({
        initialValue: challenge.rankingLockAt,
        label: "Date de verouillage des classements",
      }),
    []
  );

  const onSubmit = useSubmitCallback(
    async () => {
      const start = startField.validate();
      const end = endField.validate();
      await services.platform.patch(`/support/challenges/${uuid}`, {
        startAt: start,
        endAt: end,
      });
      await services.tracker.patch(`/support/challenges/${uuid}`, {
        startAt: start,
        endAt: end,
      });
      Toasts.success("Dates enregistrées !");
      await during(1000);
      window.location.reload();
    },
    {},
    []
  );

  const onSubmitEarlyAccess = useSubmitCallback(
    async () => {
      const authorizeConnectionFrom = openingField.validate();
      const earlyAccessWhiteList = earlyAccessField.validate() || [];
      await services.platform.patch(`/support/challenges/${uuid}`, {
        authorizeConnectionFrom,
        earlyAccessWhiteList,
      });
    },
    { successMessage: "Enregistré !" },
    []
  );

  const onSubmitRankingLock = useSubmitCallback(
    async () => {
      const rankingLockAt = rankingLockField.validate();
      await services.platform.patch(`/support/challenges/${uuid}`, {
        rankingLockAt,
      });
    },
    { successMessage: "Enregistré !" },
    []
  );

  return (
    <Fragment>
      <Typo typo="title">Dates du challenge</Typo>
      <Editor2.Form>
        {platformStartField.render()}
        {platformEndField.render()}
        {trackerStartField.render()}
        {trackerEndField.render()}
        {showInconsistantDatesWarn ? (
          <Info
            type="danger"
            message="Les dates de début et de fin de challenge sont différentes sur les différents serveurs. Veillez à les harmoniser à l'aide du formulaire ci-dessous"
          />
        ) : null}
      </Editor2.Form>
      <Spacer />
      <Typo typo="title">Changer les dates du challenge</Typo>
      <Editor2.Form onSubmit={onSubmit}>
        {startField.render()}
        {endField.render()}
      </Editor2.Form>
      <Spacer />
      <Typo typo="title">Ouverture du challenge</Typo>

      <Editor2.Form onSubmit={onSubmitEarlyAccess}>
        <Typo></Typo>
        <Spacer />
        {openingField.render()}
        {earlyAccessField.render()}
        <Spacer />
      </Editor2.Form>
      <Typo typo="title">Verouillage des classements</Typo>
      <Editor2.Form onSubmit={onSubmitRankingLock}>
        <Typo></Typo>
        <Spacer />
        {rankingLockField.render()}
        <Spacer />
      </Editor2.Form>
    </Fragment>
  );
}
