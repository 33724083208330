import { css } from "@emotion/react";
import { rgba } from "polished";
import Typos from "./Typos";

const clickableReset = css`
  background-color: transparent;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: none;
  color: inherit;
`;

const inputReset = css`
  background-color: transparent;
  border: none;
  padding: 0px;
  margin: 0px;
  width: unset;
  ${Typos.body}
`;

const table = css(
  {
    border: `1px solid black`,
    borderCollapse: "collapse",
    width: "100%",
    borderRadius: 4,
    thead: {
      ...Typos.label,
    },
    "thead td": {
      backgroundColor: rgba("blue", 0.05),
    },
    td: {
      border: `1px solid grey`,
      padding: 5,
    },
  },
  Typos.body
);

const Css = {
  clickableReset,
  inputReset,
  table,
};

export default Css;
