import { useMemo } from "react";
import GroupModel, { GroupType } from "src/models/GroupModel";
import Editor2 from "src/utilities/Editor2";
import {
  CodeFieldController,
  CodeFieldValue,
} from "src/utilities/Editor2/CodeFieldController";
import { NumberFieldController } from "src/utilities/Editor2/NumberFieldController";
import { SelectFieldController } from "src/utilities/Editor2/SelectFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

type GroupEditorProps = {
  group: GroupModel | null;
  onSubmit: (updates: Updates<GroupModel>, code: CodeFieldValue) => any;
  onDelete?: () => any;
};

export default function GroupEditor(props: GroupEditorProps) {
  const { group, onSubmit, onDelete } = props;

  const nameField = useMemo(() => {
    return new TextFieldController({
      label: "Nom du groupe",
      initialValue: group ? group.name : null,
      validation: required,
    });
  }, []);

  const typeField = useMemo(() => {
    const options = [
      { value: GroupType.COMPANY, label: "Entreprise" },
      { value: GroupType.PERSONAL, label: "Personnel" },
    ];
    return new SelectFieldController({
      label: "Type de groupe",
      options,
      keyExtractor: (d) => d.value,
      textExtractor: (d) => d.label,
      initialValue: group ? group.type : null,
      validation: required,
    });
  }, []);

  const codeField = useMemo(() => {
    return new CodeFieldController({
      label: "Code d'accès",
      initialValue: null,
      hidden: group ? true : false,
    });
  }, []);

  const sizeLimitField = useMemo(() => {
    return new NumberFieldController({
      label: "Taille limite",
      initialValue: group ? group.sizeLimit : null,
      nullValueMeaning: "Aucune limite",
      help: "Nombre maximal de participants pouvant rejoindre ce groupe",
      unit: (n) =>
        n <= 1
          ? ` participant max dans ce groupe`
          : ` participants max dans ce groupe`,
    });
  }, []);

  const onFormSubmit = useSubmitCallback(
    () => {
      const name = nameField.validate();
      const type = typeField.validate();
      const code = codeField.validate();
      const sizeLimit = sizeLimitField.validate();
      onSubmit({ name, type, sizeLimit }, code);
    },
    {},
    []
  );

  return (
    <Editor2.Form onSubmit={onFormSubmit} onDelete={onDelete}>
      {nameField.render()}
      {typeField.render()}
      {sizeLimitField.render()}
      {codeField.render()}
    </Editor2.Form>
  );
}
