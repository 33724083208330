import { Fragment, useMemo } from "react";
import Button from "src/components/Button";
import CodesList from "src/components/CodesList";
import DelayedView from "src/components/DelayedView";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import CodeModel from "src/models/CodeModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Services from "src/utilities/Services";
import useNow from "src/utilities/useNow";

export default function Codes() {
  const challenge = useChallenge();
  const { platform } = Services.use();

  const [codesValidity, reloadCodes] = useNow();

  const codes = useMemo(async () => {
    const allCodes = await platform.get<Array<CodeModel>>("/support/codes", {
      params: { challenge: challenge.uuid },
    });

    const challengeCodes: Array<CodeModel> = allCodes.filter(
      (c) => c.group === null && c.subGroup === null
    );
    const groupCodes: Array<CodeModel> = allCodes.filter(
      (c) => c.group !== null && c.subGroup === null
    );
    const subGroupCodes: Array<CodeModel> = allCodes.filter(
      (c) => c.group !== null && c.subGroup !== null
    );

    return {
      challenge: challengeCodes,
      group: groupCodes,
      subGroup: subGroupCodes,
    };
  }, [codesValidity]);

  return (
    <DelayedView promise={codes}>
      {(codes) => {
        return (
          <Fragment>
            <Typo typo="title">Codes permettant l'accès du challenge</Typo>
            <CodesList
              codes={codes.challenge}
              creationTarget={{
                challenge: challenge.uuid,
                group: null,
                subGroup: null,
              }}
              onReload={reloadCodes}
            />
            <Spacer scale={3} />
            <Typo typo="title">
              Codes permettant l'accès au challenge et à un groupe
            </Typo>
            <CodesList codes={codes.group} onReload={reloadCodes} />
            <Spacer />
            <Button
              label="Gérer les groupes"
              to={`/challenges/${challenge.uuid}/groups`}
              center
            />
            <Spacer scale={3} />
            <Typo typo="title">
              Codes permettant l'accès au challenge, à un groupe et à une équipe
            </Typo>
            <CodesList codes={codes.subGroup} onReload={reloadCodes} />
          </Fragment>
        );
      }}
    </DelayedView>
  );
}
