import { FrontError } from "../getErrorMessage";

export function required<TInput>(
  v: TInput
): asserts v is Exclude<TInput, null> {
  if (v === null) throw new FrontError("Veillez à compléter tous les champs");
}

export function regex<TInput>(
  v: TInput,
  regex: RegExp
): asserts v is TInput & string {
  if (typeof v !== "string")
    throw new Error("Cannot apply regex validation on non-string value");
  const match = regex.exec(v);
  if (match === null) throw new FrontError("Format invalide");
}

export const Validations = {
  Email: (value: any): string => {
    if (typeof value !== "string")
      throw new Error("Cannot apply email validation on non-string value");
    const input = document.createElement("input");
    input.type = "email";
    input.value = value;
    if (input.validationMessage === "") {
      return value;
    } else {
      throw new FrontError(input.validationMessage);
    }
  },
};

export type Validation<TInput, TValid extends TInput> = (
  input: TInput
) => asserts input is TValid;

// export default function useValidate<TInput, TValid extends TInput>(
//   value: TInput,
//   validation: Validation<TInput, TValid> = (v) => v as TValid,
//   deps: DependencyList
// ) {
//   const valueRef = useRef<TInput>(value);
//   valueRef.current = value;
//   return useCallback(() => {
//     return validate(valueRef.current, validation);
//   }, deps);
// }

export function validate<TInput, TValid extends TInput>(
  value: TInput,
  validation: Validation<TInput, TValid>
): asserts value is TValid {
  validation(value);
}
