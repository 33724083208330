/** @jsxImportSource @emotion/react */

import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Typo from "src/components/Typo";
import ThemeEditor from "src/editors/ThemeEditor";
import ThemeModel from "src/models/ThemeModel";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function ThemeCreation() {
  const { platform } = Services.use();
  const navigate = useNavigate();

  const onSubmit = useSubmitCallback(
    async (updates: Updates<ThemeModel>) => {
      const theme = await platform.post<ThemeModel>("/support/themes", updates);
      navigate(
        Navigation.getPath("ThemeDisplay", { params: { theme: theme.uuid } }),
        { replace: true }
      );
    },
    { successMessage: "Thème créé" },
    []
  );

  return (
    <Fragment>
      <Typo typo="title">Nouveau thème</Typo>
      <ThemeEditor theme={null} onSubmit={onSubmit} />
    </Fragment>
  );
}
