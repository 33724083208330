/** @jsxImportSource @emotion/react */

import { Fragment, useMemo } from "react";
import Typo from "src/components/Typo";
import Form from "src/utilities/Editor2/Form";
import { NumberFieldController } from "src/utilities/Editor2/NumberFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export type Step4Data = {
  ratioStepToKilometer: number;
  ratioKilometerToPoint: number;
};

type Step4Props = {
  onSubmit: (data: Step4Data) => any;
};

export default function Step4(props: Step4Props) {
  const { onSubmit } = props;

  const stepSizeField = useMemo(() => {
    return new NumberFieldController({
      label: "Distance parcourue par pas en cm",
      initialValue: 65,
      unit: " cm",
      validation: required,
    });
  }, []);

  const pointsPerKmField = useMemo(() => {
    return new NumberFieldController({
      label: "Nombre de points que rapportent 1km parcouru",
      initialValue: null,
      unit: (p) => (p <= 1 ? " point" : " points"),
      validation: required,
    });
  }, []);

  const onSubmitForm = useSubmitCallback(
    () => {
      onSubmit({
        ratioStepToKilometer: stepSizeField.validate() / 100000,
        ratioKilometerToPoint: pointsPerKmField.validate(),
      });
    },
    {},
    []
  );

  return (
    <Fragment>
      <Typo typo="subtitle">Etape 4/4 : Ratios</Typo>
      <Form onSubmit={onSubmitForm}>
        {stepSizeField.render()}
        {pointsPerKmField.render()}
      </Form>
    </Fragment>
  );
}
