/** @jsxImportSource @emotion/react */

import { useNavigate } from "react-router-dom";
import GroupEditor from "src/editors/GroupEditor";
import GroupModel from "src/models/GroupModel";
import { useChallenge } from "src/utilities/ChallengeService";
import { CodeFieldValue } from "src/utilities/Editor2/CodeFieldController";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function GroupCreation() {
  const challenge = useChallenge();

  const { platform, chat } = Services.use();
  const navigate = useNavigate();

  const onSubmit = useSubmitCallback(
    async (updates: Updates<GroupModel>, code: CodeFieldValue) => {
      const group = await platform.post<GroupModel>(
        `/support/challenges/${challenge.uuid}/groups`,
        { ...updates, codeCreationPolicy: code }
      );
      navigate(
        Navigation.getPath("GroupEdition", { params: { group: group.uuid } })
      );
    },
    { successMessage: "Groupe et salon de chat créés !" },
    []
  );

  return <GroupEditor group={null} onSubmit={onSubmit} />;
}
