import { createContext, useContext } from "react";
import ChallengeModel from "src/models/ChallengeModel";
import DataFetcher from "./DataFetcher";
import R from "./R";

export default class ChallengeService {
  private r = new R();
  private challenge: ChallengeModel | null = null;

  constructor(private uuid: string, private platform: DataFetcher) {}

  async init() {
    await this.load();
  }

  async load() {
    const c = await this.platform.get<ChallengeModel>(
      `/support/challenges/${this.uuid}`
    );
    this.challenge = c;
    this.r.notify();
  }

  useChallenge() {
    return this.r.useSelector(() => {
      const challenge = this.challenge;
      if (!challenge) throw new Error("No challenge defined");
      return challenge;
    }, []);
  }

  set(challenge: ChallengeModel) {
    if (challenge.uuid !== this.uuid) throw new Error("Invalid uuid");
    this.challenge = challenge;
    this.r.notify();
  }
}

export const ChallengeServiceContext = createContext<ChallengeService | null>(
  null
);

export function useChallengeService() {
  const service = useContext(ChallengeServiceContext);
  if (!service) throw new Error("No challenge service");
  return service;
}

export function useChallenge() {
  return useChallengeService().useChallenge();
}

export function useChallengeLocale() {
  const challenge = useChallenge();
  if (challenge.locales.includes(navigator.language)) return navigator.language;
  else return challenge.locales[0];
}
