import { Fragment, useCallback, useMemo } from "react";
import DelayedView from "src/components/DelayedView";
import Info from "src/components/Info";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import BundleModel from "src/models/BundleModel";
import ChallengeModel, {
  ChallengeReferenceModel,
  NjukoConfig,
} from "src/models/ChallengeModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { SelectFieldController } from "src/utilities/Editor2/SelectFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import globalChallengeUpdater from "src/utilities/globalChallengeUpdater";
import Services from "src/utilities/Services";
import Toasts from "src/utilities/Toasts";
import useMemoState from "src/utilities/useMemoState";
import useNow from "src/utilities/useNow";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function SigninPage() {
  return (
    <Fragment>
      <BundleForm />
      <Spacer scale={2} />
      <NjukoConfigForm />
    </Fragment>
  );
}

function BundleForm() {
  const { platform } = Services.use();
  const challenge = useChallenge();
  const [now, refresh] = useNow();

  const bundles = useMemo(
    () => platform.get<Array<BundleModel>>("/support/bundles"),
    []
  );

  const bundleField = useMemo(() => {
    return new SelectFieldController({
      initialValue: challenge.bundleUuid,
      label: "Changer l'app de rattachement du challenge",
      nullValueMeaning: "Rattacher le challenge à aucune app",
      options: bundles,
      keyExtractor: (o) => o.uuid,
      textExtractor: (o) => `Rattacher le challenge à l'app ${o.label}`,
    });
  }, []);

  const bundleUuid = challenge.bundleUuid;

  const linkedChallenges = useMemo(() => {
    if (bundleUuid === null) return null;
    return platform.get<Array<ChallengeReferenceModel>>(
      `/support/bundles/${bundleUuid}/challenges`
    );
  }, [bundleUuid, now]);

  const promises = useMemo(
    () => Promise.all([bundles, linkedChallenges]),
    [bundles, linkedChallenges]
  );

  const onSubmit = useSubmitCallback(
    async () => {
      const bundle = bundleField.validate();
      await platform.patch<ChallengeModel>(
        `/support/challenges/${challenge.uuid}`,
        { bundleUuid: bundle }
      );
      globalChallengeUpdater.notify();
    },
    { successMessage: "Enregistré !" },
    [challenge.uuid]
  );

  return (
    <Fragment>
      <Typo typo="title">Application mobile de rattachement</Typo>
      <DelayedView promise={promises}>
        {([bundles, challenges]) => {
          if (!challenge.bundleUuid) {
            return (
              <Info
                type="warn"
                message="Le challenge n'est rattaché à aucune app. Il n'est donc pas accessible"
              />
            );
          } else {
            const bundle = bundles.find((b) => b.uuid === bundleUuid);
            if (!bundle) return null;
            if (!challenges) return null;
            if (challenges.length === 1) {
              if (bundle.mode === "single-challenge") {
                return (
                  <Info
                    type="info"
                    message={`Ce challenge est relié à l'application ${bundle.label}. Il est le seul sur cette application. Les utilisateurs accèdent directement à ce challenge sans avoir besoin de saisir de code.`}
                  />
                );
              } else {
                return (
                  <Info
                    type="warn"
                    message={`Ce challenge est relié à l'application ${bundle.label}. Il est le seul sur cette application. Cependant, l'application exige que les utilisateurs saisissent le code du challenge pour y participer.`}
                  />
                );
              }
            } else {
              if (bundle.mode === "single-challenge") {
                return (
                  <Info
                    type="danger"
                    message={`Ce challenge est relié à l'application ${
                      bundle.label
                    }, à laquelle sont rattachés ${
                      challenges.length
                    } challenges (${challenges
                      .map((c) => c.code)
                      .join(
                        ", "
                      )}). Or, cette application ne peut accueillir qu'un seul challenge à la fois. Elle est donc en pause pour le moment.`}
                  />
                );
              } else {
                return (
                  <Info
                    type="info"
                    message={`Ce challenge est relié à l'application ${
                      bundle.label
                    }, à laquelle sont rattachés ${
                      challenges.length
                    } challenges (${challenges
                      .map((c) => c.code)
                      .join(
                        ", "
                      )}). Grâce au code du challenge, les utilisateurs peuvent accéder à chacun des challenges.`}
                  />
                );
              }
            }
          }
        }}
      </DelayedView>
      <Spacer />
      <Editor2.Form onSubmit={onSubmit}>{bundleField.render()}</Editor2.Form>
      <Spacer />
    </Fragment>
  );
}

function NjukoConfigForm() {
  const { platform } = Services.use();
  const challenge = useChallenge();

  const [config, setConfig] = useMemoState(
    () =>
      platform.get<NjukoConfig | null>(
        `/support/challenges/${challenge.uuid}/njukoConfig`
      ),
    [challenge.uuid]
  );

  const onSubmit = useSubmitCallback(
    async (njukoConfig: NjukoConfig | null) => {
      if (njukoConfig) {
        const output = await platform.post<NjukoConfig>(
          `/support/challenges/${challenge.uuid}/njukoConfig`,
          njukoConfig
        );
        setConfig(Promise.resolve(output));
      } else {
        await platform.delete<NjukoConfig>(
          `/support/challenges/${challenge.uuid}/njukoConfig`
        );
        setConfig(Promise.resolve(null));
      }
    },
    { successMessage: "Enregistré !" },
    [challenge.uuid]
  );

  return (
    <DelayedView promise={config}>
      {(njukoConfig) => (
        <NjukoConfigEditor value={njukoConfig} onSubmit={onSubmit} />
      )}
    </DelayedView>
  );
}

type NjukoConfigEditorProps = {
  value: NjukoConfig | null;
  onSubmit: (value: NjukoConfig | null) => any;
};

function NjukoConfigEditor(props: NjukoConfigEditorProps) {
  const { value, onSubmit } = props;

  const modeField = useMemo(
    () =>
      new SelectFieldController({
        label: "Mode d'inscription",
        options: ["NJUKO", "STANDALONE"],
        keyExtractor: (s) => s,
        textExtractor: (s) => (s === "NJUKO" ? "Via Njuko" : "Sans Njuko"),
        initialValue: value ? "NJUKO" : "STANDALONE",
        validation: required,
      }),
    []
  );

  const mode = modeField.useValue();

  const apiKeyField = useMemo(
    () =>
      new Editor2.SecretFieldController({
        label: "Clé dAPI",
        initialValue: value ? value.apiKey : null,
        validation: required,
      }),
    []
  );

  const editionField = useMemo(
    () =>
      new TextFieldController({
        label: "Edition",
        initialValue: value ? value.edition : null,
        validation: required,
      }),
    []
  );

  const onFormSubmit = useCallback(() => {
    try {
      const config = modeField.validate();
      if (config === "STANDALONE") {
        onSubmit(null);
      } else {
        const apiKey = apiKeyField.validate();
        const edition = editionField.validate();
        onSubmit({ apiKey, edition });
      }
    } catch (err) {
      Toasts.error(err);
    }
  }, []);

  return (
    <Fragment>
      <Typo typo="title">Mode d'inscription</Typo>
      <Editor2.Form onSubmit={onFormSubmit}>
        {modeField.render()}
        {mode === "NJUKO" ? (
          <Editor2.SubForm>
            {apiKeyField.render()}
            {editionField.render()}
          </Editor2.SubForm>
        ) : null}
      </Editor2.Form>
    </Fragment>
  );
}
