import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import QuizEditor from "src/editors/QuizEditor";
import QuizModel from "src/models/QuizModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Toasts from "src/utilities/Toasts";
import Updates from "src/utilities/Updates";

export default function MissionCreation() {
  const challengeUuid = useChallenge().uuid;

  const { platform } = Services.use();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (updates: Updates<QuizModel>) => {
      try {
        const quiz = await platform.post<QuizModel>(
          `/support/challenges/${challengeUuid}/quizzes`,
          updates
        );
        Toasts.success("Quiz créé");
        navigate(
          Navigation.getPath("QuizEdition", { params: { quizUuid: quiz.uuid } })
        );
      } catch (err) {
        Toasts.error(err);
      }
    },
    [challengeUuid]
  );

  return <QuizEditor quiz={null} onSubmit={onSubmit} />;
}
