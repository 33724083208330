/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import Badge from "src/components/Badge";
import Divider from "src/components/Divider";
import Intersperse from "../Intersperse";
import Localized from "../Localized";
import getLocaleFlag from "../getLocaleFlag";
import EditorContainer2 from "./EditorContainer2";
import FieldController from "./FieldController";

type I18nFieldConfig<LocaleValue, ValidLocaleValue extends LocaleValue> = {
  locales: Array<string>;
  getController: (
    locale: string
  ) => FieldController<LocaleValue, ValidLocaleValue>;
};

export class I18nFieldController<
  LocaleValue,
  ValidLocaleValue extends LocaleValue
> extends FieldController<Localized<LocaleValue>, Localized<ValidLocaleValue>> {
  readonly locales: Array<string>;
  readonly controllers: Localized<
    FieldController<LocaleValue, ValidLocaleValue>
  > = {};

  constructor(config: I18nFieldConfig<LocaleValue, ValidLocaleValue>) {
    const controllers: Localized<
      FieldController<LocaleValue, ValidLocaleValue>
    > = {};
    config.locales.forEach((l) => {
      controllers[l] = config.getController(l);
    });
    const mainController = controllers[config.locales[0]];
    if (!mainController) throw new Error("No controller");
    super({
      initialValue: {},
      label: mainController.getLabel() || undefined,
      help: mainController.getHelp() || undefined,
      disabled: mainController.isDisabled(),
      hidden: mainController.isHidden(),
      validation: (
        v: Localized<LocaleValue>
      ): asserts v is Localized<ValidLocaleValue> => {
        Object.entries(v).forEach(([l, value]) =>
          controllers[l].runValidationFn(value)
        );
      },
    });
    this.setEmptyValue(
      Object.fromEntries(config.locales.map((l) => [l, null]))
    );
    this.locales = config.locales;
    this.controllers = controllers;

    this.setValueFn(() => {
      return Object.fromEntries(
        config.locales.map((l) => [l, this.controllers[l].getValue()])
      );
    });
  }

  render(): React.ReactElement {
    return <I18nField controller={this} />;
  }
}

export function I18nField<Value, ValidValue extends Value>(props: {
  controller: I18nFieldController<Value, ValidValue>;
}) {
  const controller = props.controller;
  const locales = controller.locales;
  const controllers = controller.controllers;

  const rowCss = css({
    display: "flex",
  });

  const flagCss = css({
    alignSelf: "center",
    paddingInline: 5,
  });

  const fieldCss = css({
    flex: 1,
    flexShrink: 1,
    minWidth: 0,
  });

  return (
    <EditorContainer2 controller={controller}>
      <Intersperse between={() => <Divider />}>
        {locales.map((l) => (
          <div css={rowCss} key={l}>
            <div css={flagCss}>
              <Badge
                label={getLocaleFlag(l)}
                background="lightgrey"
                color="black"
              />
            </div>
            <div css={fieldCss}>{controllers[l].render()}</div>
          </div>
        ))}
      </Intersperse>
    </EditorContainer2>
  );
}
