import { Fragment, useMemo } from "react";
import Button from "src/components/Button";
import DelayedView from "src/components/DelayedView";
import PolicyModel from "src/models/PolicyModel";
import { useChallenge } from "src/utilities/ChallengeService";
import List from "src/utilities/List";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";

export default function Policies() {
  const challengeUuid = useChallenge().uuid;

  const { platform } = Services.use();

  const load = useMemo(async () => {
    return await platform.get<PolicyModel[]>(
      `/support/challenges/${challengeUuid}/policies`
    );
  }, [challengeUuid]);

  return (
    <Fragment>
      <Button
        label="Ajouter un texte réglementaire"
        center
        to={Navigation.getPath("PolicyCreation")}
      />
      <DelayedView promise={load}>
        {(policies) => (
          <List
            items={policies}
            renderItem={(p) => (
              <List.Item
                label={p.label["fr-FR"]}
                to={Navigation.getPath("PolicyEdition", {
                  params: { policy: p.uuid },
                })}
              />
            )}
            keyExtractor={(p) => p.uuid}
          />
        )}
      </DelayedView>
    </Fragment>
  );
}
