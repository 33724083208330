/** @jsxImportSource @emotion/react */
import { useMemo } from "react";
import CodeModel from "src/models/CodeModel";
import {
  CodeFieldController,
  CodeFieldValue,
} from "src/utilities/Editor2/CodeFieldController";
import Form from "src/utilities/Editor2/Form";
import { SwitchFieldController } from "src/utilities/Editor2/SwitchFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Extractors from "src/utilities/Extractors";
import List from "src/utilities/List";
import ExpandableList from "src/utilities/List/ExpendableList";
import Services from "src/utilities/Services";
import useSubmitCallback from "src/utilities/useSubmitCallback";

type CodesListProps = {
  codes: Array<CodeModel>;
  creationTarget?: {
    challenge: string;
    group: string | null;
    subGroup: string | null;
  };
  onReload: () => any;
};

export default function CodesList(props: CodesListProps) {
  const { onReload, creationTarget } = props;
  const { platform } = Services.use();

  const onSubmitCode = useSubmitCallback(
    async (code: string, disabled: boolean) => {
      await platform.patch(`/support/codes/${code}`, { disabled });
      onReload();
    },
    { successMessage: "Code modifié" },
    []
  );

  const onCreateCode = useSubmitCallback(
    async (code) => {
      if (!creationTarget) return;
      await platform.post(`/support/codes`, {
        policy: code,
        challengeUuid: creationTarget.challenge,
        groupUuid: creationTarget.group,
        subGroupUuid: creationTarget.subGroup,
      });
      onReload();
    },
    {},
    [creationTarget]
  );

  return (
    <ExpandableList
      items={props.codes}
      keyExtractor={Extractors.code}
      renderItem={(i) => {
        let help: Array<string> = [`Challenge ${i.challenge.name}`];
        if (i.group) help.push(`Groupe ${i.group.name}`);
        if (i.subGroup) help.push(`Equipe ${i.subGroup.name}`);
        return (
          <List.Item
            label={i.display}
            helper={help.join(" ❯ ")}
            inactive={i.disabled}
          />
        );
      }}
      renderCreation={
        creationTarget
          ? () => <CodeCreation onSubmit={onCreateCode} />
          : undefined
      }
    >
      {(item) => (
        <CodeEditor
          code={item}
          onSubmit={(s) => onSubmitCode(item.code, s.disabled)}
        />
      )}
    </ExpandableList>
  );
}

type CodeEditorProps = {
  code: CodeModel;
  onSubmit: (updates: { disabled: boolean }) => any;
};

function CodeEditor(props: CodeEditorProps) {
  const { code, onSubmit } = props;

  const disabledField = useMemo(() => {
    return new SwitchFieldController({
      label: "Code en sommeil",
      initialValue: code.disabled,
      validation: required,
      help: "Un code mis en sommeil ne peut plus être utilisé par les utilisateurs",
    });
  }, []);

  const onSubmitForm = useSubmitCallback(
    () => {
      onSubmit({ disabled: disabledField.validate() });
    },
    {},
    []
  );

  return <Form onSubmit={onSubmitForm}>{disabledField.render()}</Form>;
}

type CodeCreatorProps = {
  onSubmit: (codeCreationPolicy: CodeFieldValue) => any;
};

function CodeCreation(props: CodeCreatorProps) {
  const { onSubmit } = props;

  const codeField = useMemo(() => {
    return new CodeFieldController({
      label: "Code",
      initialValue: null,
      validation: required,
    });
  }, []);

  const onSubmitForm = useSubmitCallback(
    () => {
      onSubmit(codeField.validate());
    },
    {},
    []
  );

  return <Form onSubmit={onSubmitForm}>{codeField.render()}</Form>;
}
