/** @jsxImportSource @emotion/react */

import { Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import DelayedView from "src/components/DelayedView";
import Typo from "src/components/Typo";
import ThemeEditor from "src/editors/ThemeEditor";
import ThemeModel from "src/models/ThemeModel";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function ThemeCreation() {
  const { platform } = Services.use();
  const navigate = useNavigate();

  const uuid = Navigation.useParam("theme");

  const theme = useMemo(async () => {
    const themes = await platform.get<Array<ThemeModel>>(`/support/themes`);
    const theme = themes.find((t) => t.uuid === uuid);
    if (!theme) throw new Error("No thème found");
    return theme;
  }, [uuid]);

  const onDelete = useSubmitCallback(
    async () => {
      await platform.delete(`/support/themes/${uuid}`);
      navigate(Navigation.getPath("Themes"), { replace: true });
    },
    { confirmationMessage: "Confirmer", successMessage: "Thème supprimé" },
    []
  );

  return (
    <Fragment>
      <DelayedView promise={theme}>
        {(theme) => (
          <Fragment>
            <Typo typo="title">{theme.label}</Typo>
            <ThemeEditor theme={theme} onDelete={onDelete} />
          </Fragment>
        )}
      </DelayedView>
    </Fragment>
  );
}
