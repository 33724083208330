import { useNavigate } from "react-router-dom";
import PolicyEditor from "src/editors/PolicyEditor";
import PolicyModel from "src/models/PolicyModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function PolicyCreation() {
  const challengeUuid = useChallenge().uuid;

  const { platform } = Services.use();
  const navigate = useNavigate();

  const onSubmit = useSubmitCallback(
    async (updates: Updates<PolicyModel>) => {
      const policy = await platform.post<PolicyModel>(
        `/support/challenges/${challengeUuid}/policies`,
        updates
      );
      navigate(
        Navigation.getPath("PolicyEdition", {
          params: { policy: policy.uuid },
        }),
        { replace: true }
      );
    },
    { successMessage: "Texte créé" },
    []
  );

  return <PolicyEditor policy={null} onSubmit={onSubmit} />;
}
