import { useCallback, useMemo } from "react";
import MassNotificationModel from "src/models/MassNotificationModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { I18nFieldController } from "src/utilities/Editor2/I18nFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Toasts from "src/utilities/Toasts";
import Updates from "src/utilities/Updates";

type MassNotificationEditorProps = {
  notitication: MassNotificationModel | null;
  onSubmit: (updates: Updates<MassNotificationModel, "sent">) => any;
  onDelete?: () => any;
};

export default function MassNotificationEditor(
  props: MassNotificationEditorProps
) {
  const { notitication, onSubmit, onDelete } = props;
  const challenge = useChallenge();

  const titleField = useMemo(
    () =>
      new I18nFieldController({
        locales: challenge.locales,
        getController: (l) =>
          new TextFieldController({
            label: "Titre",
            initialValue: notitication ? notitication.title[l] : null,
            validation: required,
          }),
      }),
    []
  );

  const bodyField = useMemo(
    () =>
      new I18nFieldController({
        locales: challenge.locales,
        getController: (l) =>
          new TextFieldController({
            label: "Contenu",
            initialValue: notitication ? notitication.body[l] : null,
            validation: required,
          }),
      }),
    []
  );

  const onEditorSubmit = useCallback(() => {
    try {
      const updates: Updates<MassNotificationModel, "sent"> = {
        title: titleField.validate(),
        body: bodyField.validate(),
      };
      onSubmit(updates);
    } catch (err) {
      Toasts.error(err);
    }
  }, [onSubmit]);

  return (
    <Editor2.Form onSubmit={onEditorSubmit} onDelete={onDelete}>
      {titleField.render()}
      {bodyField.render()}
    </Editor2.Form>
  );
}
