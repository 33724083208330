/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { RefObject, useCallback } from "react";
import NullBadge from "src/components/NullBadge";
import Typo from "src/components/Typo";
import Css from "../Css";

type InputProps = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onFocus" | "onBlur" | "type" | "readOnly" | "disabled"
> & {
  nullValueMeaning?: string;
  placeholder?: string;
  overlay?: string;
  className?: string;
  value: string | null;
  onValueChange?: (v: string | null) => any;
  inputRef?: RefObject<HTMLInputElement>;
};

export default function Input(props: InputProps) {
  const {
    nullValueMeaning,
    className,
    overlay,
    onValueChange,
    inputRef,
    ...inputProps
  } = props;

  const { value } = props;

  const containerCss = css({
    position: "relative",
  });

  const inputCss = css(Css.inputReset, {
    padding: 4,
    width: "100%",
  });

  const nullValueMeaningCss = css({
    position: "absolute",
    top: 0,
    left: 0,
    padding: 4,
    pointerEvents: "none",
    opacity: nullValueMeaning && !value ? 1 : 0,
    transition: "all 200ms",
  });

  const overlayCss = css({
    position: "absolute",
    top: 0,
    left: 0,
    padding: 4,
    pointerEvents: "none",
    opacity: 0.5,
  });

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value;
      if (!onValueChange) return;
      if (v === "") onValueChange(null);
      else onValueChange(v);
    },
    [onValueChange]
  );

  return (
    <div className={className} css={containerCss}>
      <input
        {...inputProps}
        css={inputCss}
        value={value || ""}
        onChange={onChange}
        ref={inputRef}
      />
      <div css={nullValueMeaningCss}>
        <NullBadge text={nullValueMeaning} />
      </div>
      <div css={overlayCss}>
        <Typo typo="body">{overlay}</Typo>
      </div>
    </div>
  );
}
