import { useCallback, useMemo } from "react";
import ParticipationModel from "src/models/ParticipationModel";
import Editor2 from "src/utilities/Editor2";
import { SwitchFieldController } from "src/utilities/Editor2/SwitchFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Toasts from "src/utilities/Toasts";
import Updates from "src/utilities/Updates";

export type ParticipationModelUpdates = Updates<
  ParticipationModel,
  "accountId" | "isMuted" | "roomId" | "userFirstName" | "userLastName"
>;

interface ParticipationEditorProps {
  participation: ParticipationModel | null;
  onSubmit: (updates: ParticipationModelUpdates) => any;
  onDelete?: () => any;
}
export default function ParticipationEditor(props: ParticipationEditorProps) {
  const { participation, onSubmit, onDelete } = props;

  const bannedField = useMemo(
    () =>
      new SwitchFieldController({
        label: "Banni",
        initialValue: participation ? participation.isBanned : null,
        validation: required,
      }),
    []
  );

  const onSubmitEditor = useCallback(() => {
    try {
      const updates: ParticipationModelUpdates = {
        isBanned: bannedField.validate(),
      };
      onSubmit(updates);
    } catch (err) {
      Toasts.error(err);
    }
  }, [onSubmit]);

  return (
    <Editor2.Form onSubmit={onSubmitEditor} onDelete={onDelete}>
      {bannedField.render()}
    </Editor2.Form>
  );
}
