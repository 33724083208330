/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";
import SubGroupEditor from "src/editors/SubGroupEditor";
import SubGroupModel from "src/models/SubGroupModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function SubGroupCreation() {
  const challenge = useChallenge();
  const group = Navigation.useParam("group");
  const { platform } = Services.use();
  const navigate = useNavigate();

  const onSubmit = useSubmitCallback(
    async (updates: Updates<SubGroupModel>) => {
      const subgroup = await platform.post<SubGroupModel>(
        `/support/challenges/${challenge.uuid}/groups/${group}/subgroups`,
        updates
      );
      navigate(
        Navigation.getPath("SubGroupEdition", {
          params: { subgroup: subgroup.uuid },
        }),
        { replace: true }
      );
    },
    { successMessage: "Sous-groupe et salon de chat créés !" },
    [group]
  );

  return <SubGroupEditor subgroup={null} onSubmit={onSubmit} />;
}
