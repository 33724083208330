import { without } from "lodash";
import { DependencyList, ReactNode, useEffect } from "react";

let listenners: Array<() => void> = [];

function use() {
  useEffect(() => {
    document.addEventListener("click", trigger);
    return () => {
      document.removeEventListener("click", trigger);
    };
  }, []);
}

function trigger() {
  listenners.forEach((l) => l());
}

function useListener(fn: () => any, deps: DependencyList) {
  useEffect(() => {
    listenners.push(fn);
    return () => {
      listenners = without(listenners, fn);
    };
  }, deps);
}

function Boundary(props: { children: ReactNode }) {
  return <span onClick={stopPropagation}>{props.children}</span>;
}

const RootClick = { use, trigger, Boundary, useListener };

const stopPropagation = (e: React.MouseEvent<any>) => {
  e.stopPropagation();
};

export default RootClick;
