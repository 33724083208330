/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import saveAs from "file-saver";
import Papa from "papaparse";
import { Fragment, Key, ReactNode, useCallback } from "react";
import Button from "src/components/Button";
import Divider from "src/components/Divider";
import * as xlsx from "xlsx";
import Intersperse from "../Intersperse";
import ListItem from "./ListItem";

type ListProps<TData> = {
  items: Array<TData>;
  keyExtractor: (d: TData) => Key;
  renderItem: (d: TData) => ReactNode | string;
  renderCsvLine?: (d: TData) => Array<{ column: string; value: string }>;
  onCreate?: () => any;
  exportFileName?: string;
};

export default function List<TData>(props: ListProps<TData>) {
  const { items, keyExtractor, renderItem, renderCsvLine, exportFileName } =
    props;

  const containerCss = css({
    border: `1px solid lightgrey`,
    background: `#EEEEEE`,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
  });

  const onExportCsv = useCallback(() => {
    if (items.length === 0) return;
    if (!renderCsvLine) return;
    const fields = renderCsvLine(items[0]).map((i) => i.column);
    const data = items.map((item) =>
      renderCsvLine(item).map((line) => line.value)
    );
    const csvStr = Papa.unparse({ fields, data });
    const blob = new Blob([csvStr], { type: "text/csv" });
    const filename = exportFileName || "Export";
    saveAs(blob, `${filename}.csv`);
  }, [items, renderCsvLine, exportFileName]);

  const onExportXls = useCallback(() => {
    if (items.length === 0) return;
    if (!renderCsvLine) return;
    const fields = renderCsvLine(items[0]).map((i) => i.column);
    const data = items.map((item) =>
      Object.fromEntries(
        renderCsvLine(item).map((line) => [line.column, line.value])
      )
    );
    const worksheet = xlsx.utils.json_to_sheet(data, { header: fields });
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, "Export");
    const filename = exportFileName || "Export";
    xlsx.writeFile(workbook, filename + ".xlsx");
  }, [items, renderCsvLine, exportFileName]);

  return (
    <Fragment>
      {renderCsvLine ? (
        <Fragment>
          <Button onClick={onExportCsv} label="CSV" />
          <Button onClick={onExportXls} label="XLS" />
        </Fragment>
      ) : null}
      <div css={containerCss}>
        <Intersperse between={() => <Divider />}>
          {items.map((item) => {
            const rendered = renderItem(item);
            return typeof rendered === "string" ? (
              <ListItem key={keyExtractor(item)} label={rendered} />
            ) : (
              <Fragment key={keyExtractor(item)}>{rendered}</Fragment>
            );
          })}
        </Intersperse>
      </div>
    </Fragment>
  );
}

List.Item = ListItem;
