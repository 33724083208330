/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useMemo, useState } from "react";
import { is, string } from "superstruct";
import Css from "../Css";
import EditorContainer2 from "./EditorContainer2";
import FieldController, { FieldConfig } from "./FieldController";
import Input from "./Input";
import { Validation } from "./useValidate";

type NumberFieldValue = number | null;

type NumberFieldControllerConfig<ValidValue extends NumberFieldValue> =
  FieldConfig<NumberFieldValue, ValidValue> & {
    nullValueMeaning?: string;
    unit?: string | ((n: number) => string);
    decimals?: number | [number, number];
  };

export class NumberFieldController<
  ValidValue extends NumberFieldValue
> extends FieldController<NumberFieldValue, ValidValue> {
  constructor(readonly config: NumberFieldControllerConfig<ValidValue>) {
    super(config);
  }

  numberToString(nb: number | null) {
    if (nb === null) return "";
    let minimumFractionDigits: number | undefined = undefined;
    let maximumFractionDigits: number | undefined = undefined;
    const decimals = this.config.decimals;
    if (decimals !== undefined) {
      if (typeof decimals === "number") {
        minimumFractionDigits = decimals;
        maximumFractionDigits = decimals;
      } else {
        minimumFractionDigits = decimals[0];
        maximumFractionDigits = decimals[1];
      }
    }
    const formatter = new Intl.NumberFormat(navigator.language, {
      minimumFractionDigits,
      maximumFractionDigits,
    });
    return formatter.format(nb);
  }

  stringToNumber(str: string | null) {
    if (str === null || str === "") return null;
    return parseLocaleNumber(str, navigator.language);
  }

  render(): React.ReactElement {
    return <NumberField controller={this} />;
  }
}

export function NumberField<ValidValue extends NumberFieldValue>(props: {
  controller: NumberFieldController<ValidValue>;
}) {
  const controller = props.controller;

  const disabled = controller.isDisabled();
  const unit = controller.config.unit;

  const inputCss = css(Css.inputReset, {
    flexGrow: 1,
  });

  const [value, setValue] = useState(() => controller.getValue());
  controller.useValueModifier(value);

  const [display, setDisplay] = useState<string | null>(() =>
    controller.numberToString(value)
  );

  const onValueChange = useCallback((display: string | null) => {
    setDisplay(display);
    try {
      const nb = controller.stringToNumber(display);
      setValue(nb);
    } catch (err) {
      setValue(null);
    }
  }, []);

  const onBlur = useCallback(() => {
    try {
      const nb = controller.stringToNumber(display);
      setValue(nb);
      setDisplay(controller.numberToString(nb));
    } catch (err) {
      setValue(null);
      setDisplay(null);
    }
  }, [display]);

  const overlay = useMemo(() => {
    if (!unit) return undefined;
    if (value === null) return undefined;
    if (is(unit, string())) return display + unit;
    else return display + unit(value);
  }, [value, display]);

  return (
    <EditorContainer2 controller={controller}>
      <Input
        type="text"
        value={display}
        css={inputCss}
        onValueChange={onValueChange}
        disabled={disabled}
        onBlur={onBlur}
        overlay={overlay}
        nullValueMeaning={controller.config.nullValueMeaning}
      />
    </EditorContainer2>
  );
}

interface NumberFieldConfig<TValidated extends number | null> {
  label: string;
  initialValue: number | null | undefined;
  nullValueMeaning?: string;
  validation?: Validation<number | null, TValidated>;
  unit?: (n: number) => string;
}

// See https: stackoverflow.com/questions/29255843/is-there-a-way-to-reverse-the-formatting-by-intl-numberformat-in-javascript
function parseLocaleNumber(stringNumber: string, locale: string) {
  var thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, "");
  var decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, "");

  const parsed = parseFloat(
    stringNumber
      .replace(new RegExp("\\" + thousandSeparator, "g"), "")
      .replace(new RegExp("\\" + decimalSeparator), ".")
  );

  if (isNaN(parsed)) throw new InvalidNumberException(stringNumber);
  else return parsed;
}

class InvalidNumberException extends Error {
  constructor(input: string) {
    super(`Invalid number : ${input}`);
  }
}
