import dayjs from "dayjs";
import { Fragment, useMemo } from "react";
import DelayedView from "src/components/DelayedView";
import SensorSupportModel, { SensorType } from "src/models/SensorModel";
import List from "src/utilities/List";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";

export default function ParticipantSensors() {
  const { tracker } = Services.use();
  const account = Navigation.useParam("participant");

  const load = useMemo(() => {
    return tracker.get<Array<SensorSupportModel>>(`/support/sensors`, {
      params: { account },
    });
  }, [account]);

  return (
    <DelayedView promise={load}>
      {(sensors) => <ParticipantSensorsView sensors={sensors} />}
    </DelayedView>
  );
}

type ParticipantSensorsViewProps = {
  sensors: Array<SensorSupportModel>;
};

function ParticipantSensorsView(props: ParticipantSensorsViewProps) {
  const { sensors } = props;

  return (
    <Fragment>
      <List
        items={sensors}
        keyExtractor={(s) => s.id}
        renderItem={(s) => {
          const label =
            s.type === SensorType.DEVICE
              ? `Podomètre (appareil ${s.reference})`
              : s.type;
          const helper = [`A partir de ${dayjs(s.from).format("LLLL")}`];
          if (!dayjs(s.from).isSame(s.createdAt)) {
            helper.push(`Défini le ${dayjs(s.createdAt).format("LLLL")}`);
          }
          return <List.Item label={label} helper={helper} />;
        }}
      />
    </Fragment>
  );
}
