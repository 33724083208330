/** @jsxImportSource @emotion/react */

import { Fragment, useMemo } from "react";
import ThemeModel, { SelectedBottomMenuColor } from "src/models/ThemeModel";
import Editor2 from "src/utilities/Editor2";
import { ColorFieldController } from "src/utilities/Editor2/ColorFieldController";
import { SelectFieldController } from "src/utilities/Editor2/SelectFieldController";
import { SwitchFieldController } from "src/utilities/Editor2/SwitchFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Extractors from "src/utilities/Extractors";
import ThemePreview from "src/utilities/ThemePreview";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

type ThemeEditorProps = {
  theme: ThemeModel | null;
  onSubmit?: (updates: Updates<ThemeModel>) => any;
  onDelete?: () => any;
};

export default function ThemeEditor(props: ThemeEditorProps) {
  const { theme, onSubmit, onDelete } = props;

  const labelField = useMemo(() => {
    return new TextFieldController({
      label: "Libellé",
      initialValue: theme ? theme.label : null,
      disabled: !!theme,
      validation: required,
    });
  }, []);

  const tonicField = useMemo(() => {
    return new ColorFieldController({
      label: "Couleur d'accentuation",
      initialValue: theme ? theme.tonic : null,
      disabled: !!theme,
      validation: required,
    });
  }, []);

  const primaryField = useMemo(() => {
    return new ColorFieldController({
      label: "Couleur primaire",
      initialValue: theme ? theme.primary : null,
      disabled: !!theme,
      validation: required,
    });
  }, []);

  const primaryLightField = useMemo(() => {
    return new ColorFieldController({
      label: "Couleur primaire (éclaircie)",
      initialValue: theme ? theme.primaryLight : null,
      disabled: !!theme,
      validation: required,
    });
  }, []);

  const primaryTextDarkField = useMemo(() => {
    return new SwitchFieldController({
      label: "Ecrire en noir, sur la couleur primaire",
      initialValue: theme ? theme.primaryTextDark : null,
      disabled: !!theme,
      validation: required,
      help: "A défaut, le texte sera écrit en blanc",
      nullMeaning: "Non défini",
    });
  }, []);

  const tonicTextDarkField = useMemo(() => {
    return new SwitchFieldController({
      label: "Ecrire en noir, sur la couleur d'accentuation",
      initialValue: theme ? theme.tonicTextDark : null,
      disabled: !!theme,
      validation: required,
      help: "A défaut, le texte sera écrit en blanc",
      nullMeaning: "Non défini",
    });
  }, []);

  const selectedBottomMenuColorField = useMemo(() => {
    return new SelectFieldController({
      label: "Dans la barre de navigation, couleur de l'élement sélectionné",
      initialValue: theme ? theme.selectedBottomMenuColor : null,
      disabled: !!theme,
      options: [
        { key: SelectedBottomMenuColor.white, label: "Blanc" },
        { key: SelectedBottomMenuColor.tonic, label: "Couleur d'accentuation" },
      ],
      keyExtractor: Extractors.key,
      textExtractor: Extractors.label,
      validation: required,
    });
  }, []);

  const onSubmitForm = useSubmitCallback(
    () => {
      if (!onSubmit) return;
      const v = selectedBottomMenuColorField.validate();
      const updates: Updates<ThemeModel> = {
        label: labelField.validate(),
        tonic: tonicField.validate(),
        primary: primaryField.validate(),
        primaryLight: primaryLightField.validate(),
        primaryTextDark: primaryTextDarkField.validate(),
        tonicTextDark: tonicTextDarkField.validate(),
        selectedBottomMenuColor:
          selectedBottomMenuColorField.validate() as SelectedBottomMenuColor,
      };
      onSubmit(updates);
    },
    {},
    [onSubmit]
  );

  const preview: Omit<ThemeModel, "uuid" | "label"> = {
    tonic: tonicField.useValue() || "#DDDDDD",
    primary: primaryField.useValue() || "#DDDDDD",
    primaryLight: primaryLightField.useValue() || "#DDDDDD",
    primaryTextDark: primaryTextDarkField.useValue() || false,
    tonicTextDark: tonicTextDarkField.useValue() || false,
    selectedBottomMenuColor:
      selectedBottomMenuColorField.useValue() || SelectedBottomMenuColor.white,
  };

  return (
    <Fragment>
      <Editor2.Form
        onSubmit={onSubmit ? onSubmitForm : undefined}
        onDelete={onDelete}
      >
        {labelField.render()}
        {tonicField.render()}
        {primaryField.render()}
        {primaryLightField.render()}
        {primaryTextDarkField.render()}
        {tonicTextDarkField.render()}
        {selectedBottomMenuColorField.render()}
      </Editor2.Form>
      <ThemePreview theme={preview} />
    </Fragment>
  );
}
