import { useCallback, useMemo } from "react";
import PartnerModel, { PartnerDisplay } from "src/models/PartnerModel";
import Editor2 from "src/utilities/Editor2";
import FileFieldController, {
  UploadSlot,
} from "src/utilities/Editor2/FileFieldController";
import { NumberFieldController } from "src/utilities/Editor2/NumberFieldController";
import { SelectFieldController } from "src/utilities/Editor2/SelectFieldController";
import { UrlFieldController } from "src/utilities/Editor2/UrlFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import MimeTypes from "src/utilities/MimeTypes";
import Services from "src/utilities/Services";
import Toasts from "src/utilities/Toasts";
import Updates from "src/utilities/Updates";

type PartnerEditorProps = {
  partner: PartnerModel | null;
  onSubmit: (partner: Updates<PartnerModel>) => any;
  onDelete?: () => any;
};

export default function PartnerEditor(props: PartnerEditorProps) {
  const { partner, onSubmit, onDelete } = props;

  const { platform } = Services.use();

  const imageField = useMemo(
    () =>
      new FileFieldController({
        label: "Image",
        initialValue: partner ? partner.imageUrl : null,
        getUploadSlot: () =>
          platform.get<UploadSlot>("/support/partners-images"),
        accept: MimeTypes.images,
        validation: required,
      }),
    []
  );

  const positionField = useMemo(
    () =>
      new NumberFieldController({
        label: "Position dans la ligne",
        initialValue: partner ? partner.sort : null,
        validation: required,
      }),
    []
  );

  const rankField = useMemo(
    () =>
      new SelectFieldController({
        label: "Ligne",
        initialValue: partner ? partner.rank : null,
        options: useMemo(() => [1, 2, 3, 4], []),
        keyExtractor: (n: number) => n,
        textExtractor: (n: number) => `Ligne n°${n}`,
        validation: required,
      }),
    []
  );

  const linkField = useMemo(
    () =>
      new UrlFieldController({
        label: "Lien",
        initialValue: partner ? partner.link : null,
      }),
    []
  );

  const displayField = useMemo(
    () =>
      new SelectFieldController({
        label: "Affichage",
        initialValue: partner ? partner.display : null,
        options: useMemo(
          () => [PartnerDisplay.FullWidth, PartnerDisplay.HalfWidth],
          []
        ),
        keyExtractor: (d) => d,
        textExtractor: (d) =>
          d === PartnerDisplay.FullWidth
            ? "Occupe toute la ligne"
            : "Occupe la moitié de la ligne",
        validation: required,
      }),
    []
  );

  const onEditorSubmit = useCallback(() => {
    try {
      const updates: Updates<PartnerModel> = {
        imageUrl: imageField.validate(),
        rank: rankField.validate(),
        display: displayField.validate(),
        link: linkField.validate(),
        sort: positionField.validate(),
      };
      onSubmit(updates);
    } catch (err) {
      Toasts.error(err);
    }
  }, [onSubmit]);

  return (
    <Editor2.Form onSubmit={onEditorSubmit} onDelete={onDelete}>
      {imageField.render()}
      {rankField.render()}
      {positionField.render()}
      {linkField.render()}
      {displayField.render()}
    </Editor2.Form>
  );
}
