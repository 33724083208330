/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import React, {
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import PerLocaleStrings from "src/utilities/PerLocaleStrings";
import { utils, writeFile } from "xlsx";
import Badge from "./Badge";
import Button from "./Button";
import NullBadge from "./NullBadge";
import PerLocaleStringsView from "./PerLocaleStringsView";
import Spacer from "./Spacer";
import Typo from "./Typo";

interface ColumnBase<Row, Value> {
  id: string;
  accessor: (row: Row) => Value;
  header?: string;
}

interface ColumnRenderer<Value> {
  render?: (value: Value) => ReactNode;
  searchableValue?: (value: Value) => string;
  sortableValue?: (value: Value) => string | number;
  exportableValue?: (value: Value) => string;
}

type Column<Row, Value> = ColumnBase<Row, Value> & ColumnRenderer<Value>;

interface DataTable2Props<TRow extends {}> {
  columns: Array<Column<TRow, any>>;
  data: TRow[];
  getExportName?: (nbOfRows: number) => string;
}

export default function DataTable2<TRow extends {}>(
  props: DataTable2Props<TRow>
) {
  const { columns, data, getExportName } = props;

  const [search, setSearch] = useState<string>("");

  const rows = useMemo(() => {
    let output = [...data];
    if (search !== "") {
      output = output.filter((d) => {
        const matchingColumn = columns.find((c) => {
          if (c.searchableValue === undefined) return false;
          const value = c.accessor(d);
          const searchable = c.searchableValue(value);
          return searchable.includes(search);
        });
        return matchingColumn !== undefined;
      });
    }
    return output;
  }, [data, search]);

  const onChangeSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    []
  );

  const buildExport = useCallback(() => {
    const lines: Array<Record<string, string>> = rows.map((r) => {
      const line: Record<string, string> = {};
      columns.forEach((c) => {
        if (c.exportableValue === undefined) return;
        const value = c.accessor(r);
        const exportable = c.exportableValue(value);
        line[c.header ?? c.id] = exportable;
      });
      return line;
    });
    return lines;
  }, [rows, columns]);

  const onExportCsv = useCallback(() => {
    const lines = buildExport();
    const csvStr = Papa.unparse(lines);
    const blob = new Blob([csvStr], { type: "text/csv" });
    const filename =
      getExportName != null ? getExportName(lines.length) : "Export";
    saveAs(blob, `${filename}.csv`);
  }, [buildExport, getExportName]);

  const onExportXls = useCallback(() => {
    const lines = buildExport();
    const worksheet = utils.json_to_sheet(lines);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Export");
    const filename =
      getExportName != null ? getExportName(lines.length) : "Export";
    writeFile(workbook, `${filename}.xlsx`);
  }, [buildExport, getExportName]);

  const barCss = css`
    display: flex;
    align-items: center;
  `;

  const tableCss = css`
    border-collapse: collapse;
    width: 100%;
    thead {
      background-color: rgba(0, 0, 0, 0.1);
    }
    tr {
      border-bottom: 1px solid black;
    }
    td,
    th {
      padding: 4px;
    }
  `;

  return (
    <Fragment>
      <div css={barCss}>
        <input
          type="text"
          value={search}
          onChange={onChangeSearch}
          placeholder="Rechercher..."
        />
        <Spacer />
        <Button label="CSV" onClick={onExportCsv} />
        <Spacer />
        <Button label="XLS" onClick={onExportXls} />
      </div>
      <Spacer />
      <table css={tableCss}>
        <thead>
          <tr>
            {columns.map((c) => {
              if (c.render == null) return null;
              return (
                <th key={c.id}>
                  <Typo typo="body">{c.header}</Typo>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((d, i) => (
            <tr key={i}>
              {columns.map((c) => {
                if (c.render == null) return null;
                return <td key={c.id}>{c.render(c.accessor(d))}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
}

DataTable2.useColumns = <TRow extends {}>(
  columns: Array<Column<TRow, any>>
) => {
  return useMemo(() => columns, []);
};

function createStringColumn<Row>(
  base: ColumnBase<Row, string | null>
): Column<Row, string | null> {
  return {
    ...base,
    render: (v) => (v === null ? <NullBadge /> : <Typo>{v}</Typo>),
    searchableValue: (v) => v ?? "",
    sortableValue: (v) => v ?? "",
    exportableValue: (v) => v ?? "",
  };
}

function createNumberColumn<Row>(
  base: ColumnBase<Row, number | null>
): Column<Row, number | null> {
  return {
    ...base,
    render: (v) => (v === null ? <NullBadge /> : <Typo>{v.toString()}</Typo>),
    searchableValue: (v) => (v === null ? "" : v.toString()),
    sortableValue: (v) => (v === null ? Infinity : v),
    exportableValue: (v) => (v === null ? "-" : v.toString()),
  };
}

function createBooleanColumn<Row>(
  base: ColumnBase<Row, boolean>
): Column<Row, boolean> {
  return {
    ...base,
    render: (v) =>
      v ? (
        <Badge label="Oui" background="darkgreen" color="white" />
      ) : (
        <Badge label="Non" background="darkred" color="white" />
      ),
    searchableValue: (v) => (v ? "Oui" : "Non"),
    sortableValue: (v) => (v ? 1 : 0),
    exportableValue: (v) => (v ? "Oui" : "Non"),
  };
}

function createDateColumn<Row>(
  base: ColumnBase<Row, Date | null>
): Column<Row, Date | null> {
  return {
    ...base,
    render: (v) =>
      v === null ? (
        <NullBadge />
      ) : (
        <Typo>{dayjs(v).format("dddd LL [-] LTS")}</Typo>
      ),
    searchableValue: (v) =>
      v === null ? "" : dayjs(v).format("dddd LL [-] LTS"),
    sortableValue: (v) => (v === null ? 0 : v.valueOf()),
    exportableValue: (v) =>
      v === null ? "" : dayjs(v).format("dddd LL [-] LTS"),
  };
}

function createPerLocaleStringsColumn<Row>(
  base: ColumnBase<Row, PerLocaleStrings | null>
): Column<Row, PerLocaleStrings | null> {
  return {
    ...base,
    render: (v) =>
      v === null ? <NullBadge /> : <PerLocaleStringsView strings={v} />,
    searchableValue: (v) => (v === null ? "" : Object.values(v).join(" ")),
    exportableValue: (v) => (v === null ? "" : PerLocaleStringsView.toText(v)),
  };
}

DataTable2.columns = {
  string: createStringColumn,
  date: createDateColumn,
  number: createNumberColumn,
  boolean: createBooleanColumn,
  perLocaleStrings: createPerLocaleStringsColumn,
};
