/** @jsxImportSource @emotion/react */
import { Fragment, useMemo } from "react";
import Button from "src/components/Button";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import ChallengeModel from "src/models/ChallengeModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { NumberFieldController } from "src/utilities/Editor2/NumberFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Extractors from "src/utilities/Extractors";
import List from "src/utilities/List";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import globalChallengeUpdater from "src/utilities/globalChallengeUpdater";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function ChallengeMetrics() {
  const challenge = useChallenge();
  const { platform } = Services.use();

  const metrics = challenge.metrics;

  const stepSizeField = useMemo(() => {
    return new NumberFieldController({
      label: "Distance parcourue par pas en cm",
      initialValue: challenge.ratioStepToKilometer * 100000,
      unit: " cm",
      validation: required,
    });
  }, []);

  const pointsPerKmField = useMemo(() => {
    return new NumberFieldController({
      label: "Nombre de points que rapportent 1km parcouru",
      initialValue: challenge.ratioKilometerToPoint,
      unit: (p) => (p <= 1 ? " point" : " points"),
      validation: required,
    });
  }, []);

  const onSubmitRatios = useSubmitCallback(
    async () => {
      const updates: Partial<ChallengeModel> = {
        ratioKilometerToPoint: pointsPerKmField.validate(),
        ratioStepToKilometer: stepSizeField.validate() / 100000,
      };
      await platform.patch(`/support/challenges/${challenge.uuid}`, updates);
      globalChallengeUpdater.notify();
    },
    { successMessage: "Enregistré" },
    []
  );

  return (
    <Fragment>
      <Typo typo="title">Ratios de conversion</Typo>
      <Editor2.Form onSubmit={onSubmitRatios}>
        {stepSizeField.render()}
        {pointsPerKmField.render()}
      </Editor2.Form>
      <Spacer />
      <Typo typo="title">Unités affichées sur l'application</Typo>
      <Spacer />
      <List
        items={metrics}
        keyExtractor={Extractors.uuid}
        renderItem={(m) => (
          <List.Item
            label={m.label}
            to={Navigation.getPath("ChallengeMetricsEdition", {
              params: { metric: m.uuid },
            })}
          />
        )}
      />
      <Button
        to={Navigation.getPath("ChallengeMetricsCreation")}
        label="Nouvelle métrique"
      />
    </Fragment>
  );
}
