/** @jsxImportSource @emotion/react */

import { Fragment, useMemo } from "react";
import Button from "src/components/Button";
import DelayedView from "src/components/DelayedView";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import ThemeModel from "src/models/ThemeModel";
import Extractors from "src/utilities/Extractors";
import List from "src/utilities/List";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";

export default function Themes() {
  const { platform } = Services.use();

  const themes = useMemo(async () => {
    const themes = await platform.get<Array<ThemeModel>>(`/support/themes`);
    return themes;
  }, []);

  return (
    <Fragment>
      <Typo typo="title">Thèmes de l'application</Typo>
      <DelayedView promise={themes}>
        {(themes) => (
          <List
            items={themes}
            keyExtractor={Extractors.uuid}
            renderItem={(t) => (
              <List.Item
                label={t.label}
                to={Navigation.getPath("ThemeDisplay", {
                  params: { theme: t.uuid },
                })}
              />
            )}
          ></List>
        )}
      </DelayedView>
      <Spacer />
      <Button
        label="Créer un thème"
        to={Navigation.getPath("ThemeCreation")}
        center
      />
    </Fragment>
  );
}
