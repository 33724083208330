import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import ChallengeInfos from "src/screens/ChallengeInfos";
import ChallengeMenu from "src/screens/ChallengeMenu";
import ChallengePartnersPage from "src/screens/ChallengePartnersPage";
import ChallengeSensorsPage from "src/screens/ChallengeSensorsPage";
import ChatParticipation from "src/screens/ChatParticipation";
import ChatRoom from "src/screens/ChatRoom";
import ChatRooms from "src/screens/ChatRooms";
import ConferencesCreation from "src/screens/ConferenceCreation";
import ConferencesEdition from "src/screens/ConferenceEdition";
import ConferencesList from "src/screens/ConferencesList";
import GroupChange from "src/screens/GroupChange";
import GroupCreation from "src/screens/GroupCreation";
import GroupEdition from "src/screens/GroupEdition";
import Groups from "src/screens/Groups";
import ManualStepsPage from "src/screens/ManualStepsPage";
import MissionCreation from "src/screens/MissionCreation";
import MissionEdition from "src/screens/MissionEdition";
import MissionResults from "src/screens/MissionResults";
import MissionsListPage from "src/screens/MissionsListPage";
import NotificationCreation from "src/screens/NotificationCreation";
import NotificationEdition from "src/screens/NotificationEdition";
import NotificationsHome from "src/screens/NotificationsList";
import Participant from "src/screens/Participant";
import ParticipantSteps from "src/screens/ParticipantSteps";
import Participants from "src/screens/Participants";
import PresentsPage from "src/screens/PresentsPage";
import QuizCreation from "src/screens/QuizCreation";
import QuizEdition from "src/screens/QuizEdition";
import QuizzesList from "src/screens/QuizzesList";
import SigninPage from "src/screens/SigninPage";
import SubGroupCreation from "src/screens/SubGroupCreation";
import SubGroupEdition from "src/screens/SubGroupEdition";
import Navigation from "src/utilities/Navigation";
import ChallengeCreation from "./screens/ChallengeCreation";
import ChallengeDates from "./screens/ChallengeDates";
import ChallengeMetrics from "./screens/ChallengeMetrics";
import ChallengeMetricsCreation from "./screens/ChallengeMetricsCreation";
import ChallengeMetricsEdition from "./screens/ChallengeMetricsEdition";
import ChallengeWrapper from "./screens/ChallengeWrapper";
import Challenges from "./screens/Challenges";
import Cleaning from "./screens/Cleaning";
import Codes from "./screens/Codes";
import ParticipantSensors from "./screens/ParticipantSensors";
import Policies from "./screens/Policies";
import PolicyCreation from "./screens/PolicyCreation";
import PolicyEdition from "./screens/PolicyEdition";
import Rankings from "./screens/Rankings";
import ThemeCreation from "./screens/ThemeCreation";
import ThemeDisplay from "./screens/ThemeDisplay";
import Themes from "./screens/Themes";

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={Navigation.getRootPattern("Home")}
          element={<Navigate to={Navigation.getPath("Challenges")} />}
        />
        <Route
          path={Navigation.getRootPattern("Challenges")}
          element={<Outlet />}
        >
          <Route index element={<Challenges />} />
          <Route
            path={Navigation.getPattern("ChallengeCreation")}
            element={<ChallengeCreation />}
          ></Route>
        </Route>
        <Route path={Navigation.getRootPattern("Themes")} element={<Outlet />}>
          <Route index element={<Themes />} />
          <Route
            path={Navigation.getPattern("ThemeCreation")}
            element={<ThemeCreation />}
          ></Route>
          <Route
            path={Navigation.getPattern("ThemeDisplay")}
            element={<ThemeDisplay />}
          ></Route>
        </Route>
        <Route
          path={Navigation.getRootPattern("ChallengeHome")}
          element={<ChallengeWrapper />}
        >
          <Route index element={<ChallengeMenu />} />
          <Route
            path={Navigation.getPattern("Informations")}
            element={<ChallengeInfos />}
          ></Route>
          <Route
            path={Navigation.getPattern("ChallengeDates")}
            element={<ChallengeDates />}
          ></Route>
          <Route
            path={Navigation.getPattern("Signin")}
            element={<SigninPage />}
          ></Route>
          <Route
            path={Navigation.getPattern("Participants")}
            element={<Outlet />}
          >
            <Route index element={<Participants />}></Route>
            <Route
              path={Navigation.getPattern("Participant")}
              element={<Outlet />}
            >
              <Route index element={<Participant />}></Route>
              <Route
                path={Navigation.getPattern("ParticipantSteps")}
                element={<ParticipantSteps />}
              ></Route>
              <Route
                path={Navigation.getPattern("ParticipantSensors")}
                element={<ParticipantSensors />}
              ></Route>
            </Route>
          </Route>
          <Route
            path={Navigation.getPattern("Partners")}
            element={<ChallengePartnersPage />}
          ></Route>
          <Route
            path={Navigation.getPattern("ChallengeMetrics")}
            element={<Outlet />}
          >
            <Route index element={<ChallengeMetrics />} />
            <Route
              path={Navigation.getPattern("ChallengeMetricsCreation")}
              element={<ChallengeMetricsCreation />}
            />
            <Route
              path={Navigation.getPattern("ChallengeMetricsEdition")}
              element={<ChallengeMetricsEdition />}
            />
          </Route>

          <Route
            path={Navigation.getPattern("Sensors")}
            element={<ChallengeSensorsPage />}
          ></Route>
          <Route
            path={Navigation.getPattern("ConferencesHome")}
            element={<Outlet />}
          >
            <Route index element={<ConferencesList />} />
            <Route
              path={Navigation.getPattern("ConferencesCreation")}
              element={<ConferencesCreation />}
            />
            <Route
              path={Navigation.getPattern("ConferencesEdition")}
              element={<ConferencesEdition />}
            />
          </Route>
          <Route path={Navigation.getPattern("Policies")} element={<Outlet />}>
            <Route index element={<Policies />} />
            <Route
              path={Navigation.getPattern("PolicyCreation")}
              element={<PolicyCreation />}
            />
            <Route
              path={Navigation.getPattern("PolicyEdition")}
              element={<PolicyEdition />}
            />
          </Route>
          <Route
            path={Navigation.getPattern("MissionsHome")}
            element={<Outlet />}
          >
            <Route index element={<MissionsListPage />} />
            <Route
              path={Navigation.getPattern("MissionCreation")}
              element={<MissionCreation />}
            />
            <Route
              path={Navigation.getPattern("MissionEdition")}
              element={<MissionEdition />}
            />
            <Route
              path={Navigation.getPattern("MissionResults")}
              element={<MissionResults />}
            />
          </Route>
          <Route
            path={Navigation.getPattern("QuizzesHome")}
            element={<Outlet />}
          >
            <Route index element={<QuizzesList />} />
            <Route
              path={Navigation.getPattern("QuizCreation")}
              element={<QuizCreation />}
            />
            <Route
              path={Navigation.getPattern("QuizEdition")}
              element={<QuizEdition />}
            />
          </Route>
          <Route
            path={Navigation.getPattern("NotificationsHome")}
            element={<Outlet />}
          >
            <Route index element={<NotificationsHome />} />
            <Route
              path={Navigation.getPattern("NotificationCreation")}
              element={<NotificationCreation />}
            />
            <Route
              path={Navigation.getPattern("NotificationEdition")}
              element={<NotificationEdition />}
            />
          </Route>
          <Route
            path={Navigation.getPattern("Presents")}
            element={<PresentsPage />}
          ></Route>
          <Route
            path={Navigation.getPattern("ManualSteps")}
            element={<ManualStepsPage />}
          ></Route>
          <Route path={Navigation.getPattern("Groups")} element={<Outlet />}>
            <Route index element={<Groups />} />
            <Route
              path={Navigation.getPattern("GroupCreation")}
              element={<GroupCreation />}
            />
            <Route
              path={Navigation.getPattern("GroupEdition")}
              element={<Outlet />}
            >
              <Route index element={<GroupEdition />} />
              <Route
                path={Navigation.getPattern("SubGroupCreation")}
                element={<SubGroupCreation />}
              />
              <Route
                path={Navigation.getPattern("SubGroupEdition")}
                element={<SubGroupEdition />}
              />
            </Route>
          </Route>
          <Route
            path={Navigation.getPattern("GroupChange")}
            element={<GroupChange />}
          />
          <Route path={Navigation.getPattern("ChatRooms")} element={<Outlet />}>
            <Route index element={<ChatRooms />}></Route>
            <Route
              path={Navigation.getPattern("ChatRoom")}
              element={<Outlet />}
            >
              <Route index element={<ChatRoom />}></Route>
              <Route
                path={Navigation.getPattern("ChatParticipation")}
                element={<ChatParticipation />}
              ></Route>
            </Route>
          </Route>
          <Route path={Navigation.getPattern("Codes")} element={<Codes />} />
          <Route
            path={Navigation.getPattern("Rankings")}
            element={<Rankings />}
          />
          <Route
            path={Navigation.getPattern("Cleaning")}
            element={<Cleaning />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
