/** @jsxImportSource @emotion/react */
import { TypoName } from "src/utilities/Typos";
import Badge from "./Badge";

interface NullBadgeProps {
  text?: string;
  typo?: TypoName;
}

export default function NullBadge(props: NullBadgeProps) {
  const { text = "Vide", typo } = props;
  return (
    <Badge
      label={text}
      typo={typo}
      background="rgba(0,0,0,0.1)"
      color="rgba(0,0,0,0.3)"
    />
  );
}
