import { Fragment, useMemo } from "react";
import DelayedView from "src/components/DelayedView";
import ChallengeModel from "src/models/ChallengeModel";
import PresentModel from "src/models/PresentModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import FileFieldController, {
  UploadSlot,
} from "src/utilities/Editor2/FileFieldController";
import { I18nFieldController } from "src/utilities/Editor2/I18nFieldController";
import { SwitchFieldController } from "src/utilities/Editor2/SwitchFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { UrlFieldController } from "src/utilities/Editor2/UrlFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import MimeTypes from "src/utilities/MimeTypes";
import Services from "src/utilities/Services";
import Toasts from "src/utilities/Toasts";
import Updates from "src/utilities/Updates";
import useMemoState from "src/utilities/useMemoState";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function PresentsPage() {
  const { platform } = Services.use();
  const challenge = useChallenge();

  const [presents, setPresents] = useMemoState(() => {
    return platform.get<Array<PresentModel>>(
      `/support/challenges/${challenge.uuid}/presents`
    );
  }, [challenge.uuid]);

  const activationField = useMemo(
    () =>
      new SwitchFieldController({
        label: "Activer les cadeaux",
        initialValue: challenge.presentsActivated,
        validation: required,
      }),
    []
  );

  const onSubmit = useSubmitCallback(async () => {
    const update: Pick<ChallengeModel, "presentsActivated"> = {
      presentsActivated: activationField.validate(),
    };
    await platform.patch(`/support/challenges/${challenge.uuid}`, update);
    window.location.reload();
  });

  const onCreate = useSubmitCallback(
    async (present: Updates<PresentModel>) => {
      const newPresent = await platform.post<PresentModel>(
        `/support/challenges/${challenge.uuid}/presents`,
        present
      );
      const newPresents = [...(await presents), newPresent];
      setPresents(Promise.resolve(newPresents));
    },
    { successMessage: "Cadeau créé !" }
  );

  const onUpdate = useSubmitCallback(
    async (uuid: string, present: Updates<PresentModel>) => {
      const newPresent = await platform.patch<PresentModel>(
        `/support/challenges/${challenge.uuid}/presents/${uuid}`,
        present
      );
      const newPresents = (await presents).map((p) =>
        p.uuid === uuid ? newPresent : p
      );
      setPresents(Promise.resolve(newPresents));
    },
    { successMessage: "Cadeau energistré !" }
  );

  const onDelete = useSubmitCallback(
    async (uuid: string) => {
      await platform.delete<Array<PresentModel>>(
        `/support/challenges/${challenge.uuid}/presents/${uuid}`
      );
      const newPresents = (await presents).filter((p) => p.uuid !== uuid);
      setPresents(Promise.resolve(newPresents));
    },
    { confirmationMessage: "Supprimer ?", successMessage: "Supprimé" },
    []
  );

  return (
    <Fragment>
      <Editor2.Form onSubmit={onSubmit}>
        {activationField.render()}
      </Editor2.Form>
      {challenge.presentsActivated ? (
        <DelayedView promise={presents}>
          {(presents) => {
            return (
              <Fragment>
                {presents.map((p) => (
                  <PresentEditor
                    present={p}
                    onSubmit={(updates) => onUpdate(p.uuid, updates)}
                    onDelete={() => onDelete(p.uuid)}
                    key={p.uuid}
                  />
                ))}
                <PresentEditor present={null} onSubmit={onCreate} />
              </Fragment>
            );
          }}
        </DelayedView>
      ) : null}
    </Fragment>
  );
}

type PresentEditorProps = {
  present: PresentModel | null;
  onSubmit: (updates: Updates<PresentModel>) => any;
  onDelete?: () => any;
};

function PresentEditor(props: PresentEditorProps) {
  const { present, onSubmit, onDelete } = props;

  const { platform } = Services.use();
  const challenge = useChallenge();

  const labelField = useMemo(
    () =>
      new I18nFieldController({
        locales: challenge.locales,
        getController: (l) => {
          return new TextFieldController({
            label: "Libellé",
            initialValue: present ? present.label[l] : null,
            validation: required,
          });
        },
      }),
    []
  );

  const conditionField = useMemo(
    () =>
      new I18nFieldController({
        locales: challenge.locales,
        getController: (l) => {
          return new TextFieldController({
            label: "Condition pour remporter le cadeau",
            initialValue: present ? present.condition[l] : null,
            validation: required,
          });
        },
      }),
    []
  );

  const detailsField = useMemo(
    () =>
      new I18nFieldController({
        locales: challenge.locales,
        getController: (l) => {
          return new TextFieldController({
            label: "Détails du cadeau",
            initialValue:
              present && present.details ? present.details[l] : null,
            validation: required,
          });
        },
      }),
    []
  );

  const imageUrlField = useMemo(
    () =>
      new FileFieldController({
        label: "Image",
        initialValue: present ? present.imageUrl : null,
        getUploadSlot: () =>
          platform.get<UploadSlot>("/support/present-images"),
        accept: MimeTypes.images,
      }),
    []
  );

  const linkField = useMemo(
    () =>
      new UrlFieldController({
        label: "Lien",
        initialValue: present ? present.link : null,
      }),
    []
  );

  const onSubmitEditor = useSubmitCallback(
    () => {
      try {
        const updates: Updates<PresentModel> = {
          label: labelField.validate(),
          condition: conditionField.validate(),
          details: detailsField.validate(),
          imageUrl: imageUrlField.validate(),
          link: linkField.validate(),
        };
        onSubmit(updates);
      } catch (err) {
        Toasts.error(err);
      }
    },
    {},
    []
  );

  return (
    <Editor2.Form onSubmit={onSubmitEditor} onDelete={onDelete}>
      {labelField.render()}
      {conditionField.render()}
      {detailsField.render()}
      {imageUrlField.render()}
      {linkField.render()}
    </Editor2.Form>
  );
}
