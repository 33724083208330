import { useNavigate } from "react-router-dom";
import MissionEditor from "src/editors/MissionEditor";
import MissionModel from "src/models/MissionModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function MissionCreation() {
  const challengeUuid = useChallenge().uuid;
  const navigate = useNavigate();

  const { platform } = Services.use();

  const onSubmit = useSubmitCallback(
    async (updates: Updates<MissionModel>) => {
      const mission = await platform.post<MissionModel>(
        `/support/challenges/${challengeUuid}/missions`,
        updates
      );
      navigate(
        Navigation.getPath("MissionEdition", {
          params: { missionUuid: mission.uuid },
        })
      );
    },
    { successMessage: "Mission créée" },
    [challengeUuid]
  );

  return <MissionEditor mission={null} onSubmit={onSubmit} />;
}
