import { AxiosInstance, AxiosRequestConfig } from "axios";

class DataFetcher {
  constructor(readonly axios: AxiosInstance) {}

  async get<TData>(path: string, config?: AxiosRequestConfig) {
    const result = await this.axios.get<TData>(path, config);
    return result.data;
  }

  async post<TData>(path: string, body: any, config?: AxiosRequestConfig) {
    const result = await this.axios.post<TData>(path, body, config);
    console.log(result);
    return result.data;
  }

  async put<TData>(path: string, body?: any, config?: AxiosRequestConfig) {
    const result = await this.axios.put<TData>(path, body, config);
    return result.data;
  }

  async patch<TData>(path: string, body: any, config?: AxiosRequestConfig) {
    const result = await this.axios.patch<TData>(path, body, config);
    return result.data;
  }

  async delete<TData>(path: string, config?: AxiosRequestConfig) {
    const result = await this.axios.delete<TData>(path, config);
    return result.data;
  }
}

export default DataFetcher;
