import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import DelayedView from "src/components/DelayedView";
import MissionEditor from "src/editors/MissionEditor";
import MissionModel from "src/models/MissionModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function MissionEdition() {
  const challengeUuid = useChallenge();
  const missionUuid = Navigation.useParam("missionUuid");

  const { platform } = Services.use();
  const navigate = useNavigate();

  const load = useMemo(async () => {
    return await platform.get<MissionModel>(
      `/support/challenges/${challengeUuid}/missions/${missionUuid}`
    );
  }, []);

  const onUpdate = useSubmitCallback(
    async (updates: Updates<MissionModel>) => {
      return await platform.put<MissionModel>(
        `/support/challenges/${challengeUuid}/missions/${missionUuid}`,
        updates
      );
    },
    { successMessage: "Mission modifiée" },
    [challengeUuid, missionUuid]
  );

  const onDelete = useSubmitCallback(
    async () => {
      await platform.delete(
        `/support/challenges/${challengeUuid}/missions/${missionUuid}`
      );
      navigate(Navigation.getPath("MissionsHome"));
    },
    { successMessage: "Mission supprimée", confirmationMessage: "Confirmer ?" },
    [challengeUuid]
  );

  return (
    <DelayedView promise={load}>
      {(mission) => (
        <MissionEditor
          mission={mission}
          onSubmit={onUpdate}
          onDelete={onDelete}
        />
      )}
    </DelayedView>
  );
}
