type ThemeModel = {
  uuid: string;
  label: string;
  tonic: string;
  primary: string;
  primaryLight: string;
  tonicTextDark: boolean;
  primaryTextDark: boolean;
  selectedBottomMenuColor: SelectedBottomMenuColor;
};

export default ThemeModel;

export enum SelectedBottomMenuColor {
  white = "white",
  tonic = "tonic",
}
