import React, { PropsWithChildren, useContext } from "react";

export interface ServiceList {}

const context = React.createContext<ServiceList | null>(null);

type ProviderProps = PropsWithChildren<{
  services: ServiceList;
}>;

function Provider(props: ProviderProps) {
  return (
    <context.Provider value={props.services}>{props.children}</context.Provider>
  );
}

function use() {
  const services = useContext(context);
  if (services == null) throw new Error("No services");
  return services;
}

const Services = {
  Provider,
  use,
};

export default Services;
