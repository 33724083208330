/** @jsxImportSource @emotion/react */

import dayjs from "dayjs";
import { groupBy } from "lodash";
import { Fragment, useMemo } from "react";
import Button from "src/components/Button";
import { ClickableProps } from "src/components/Clickable";
import DelayedView from "src/components/DelayedView";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import ChallengeModel from "src/models/ChallengeModel";
import List from "src/utilities/List";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import useDateRender from "src/utilities/useDateRender";

export default function Challenges() {
  const { platform } = Services.use();

  const challenges = useMemo(async () => {
    const challenges = await platform.get<Array<ChallengeModel>>(
      `/support/challenges`
    );
    const byProgression = groupBy(challenges, (c) =>
      getChallengeProgression(c)
    );
    return byProgression;
  }, []);

  return (
    <Fragment>
      <Spacer />
      <Button
        to={Navigation.getPath("ChallengeCreation")}
        label="Créer un challenge"
        center
      />
      <DelayedView promise={challenges}>
        {(challenges) => {
          return (
            <Fragment>
              <Typo typo="title">Challenges en cours</Typo>
              <List
                items={challenges["running"] || []}
                renderItem={(c) => (
                  <ChallengeItem
                    challenge={c}
                    to={Navigation.getPath("ChallengeHome", {
                      params: { challengeUuid: c.uuid },
                    })}
                  />
                )}
                keyExtractor={(c) => c.uuid}
              />
              <Spacer scale={3} />
              <Typo typo="title">Challenges à venir</Typo>
              <List
                items={challenges["warm-up"] || []}
                renderItem={(c) => (
                  <ChallengeItem
                    challenge={c}
                    to={Navigation.getPath("ChallengeHome", {
                      params: { challengeUuid: c.uuid },
                    })}
                  />
                )}
                keyExtractor={(c) => c.uuid}
              />
              <Spacer scale={3} />
              <Typo typo="title">Challenges terminés</Typo>
              <List
                items={challenges["ended"] || []}
                renderItem={(c) => (
                  <ChallengeItem
                    challenge={c}
                    to={Navigation.getPath("ChallengeHome", {
                      params: { challengeUuid: c.uuid },
                    })}
                  />
                )}
                keyExtractor={(c) => c.uuid}
              />
            </Fragment>
          );
        }}
      </DelayedView>
    </Fragment>
  );
}

type ChallengeItemProps = {
  challenge: ChallengeModel;
} & ClickableProps;
function ChallengeItem(props: ChallengeItemProps) {
  const { challenge, ...clickable } = props;
  return (
    <List.Item
      label={challenge.name ? challenge.name[challenge.locales[0]] : "-"}
      helper={`Du ${dayjs(challenge.startAt).format("LLLL")} au ${dayjs(
        challenge.endAt
      ).format("LLLL")}`}
      image={challenge.logoUrl}
      {...clickable}
    />
  );
}

function useChallengeProgression(challenge: ChallengeModel) {
  const startDate = challenge.startAt;
  const endDate = challenge.endAt;
  const d = useDateRender(startDate, endDate);

  const mode = useMemo(() => {
    getChallengeProgression(challenge);
  }, [d]);
  return mode;
}

function getChallengeProgression(challenge: ChallengeModel) {
  const startDate = challenge.startAt;
  const endDate = challenge.endAt;
  if (dayjs().isBefore(startDate)) return "warm-up";
  else if (dayjs().isAfter(endDate)) return "ended";
  else return "running";
}
