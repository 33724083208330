/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useMemo } from "react";
import Button from "src/components/Button";
import Divider from "src/components/Divider";
import Typo from "src/components/Typo";
import Intersperse from "../Intersperse";
import EditorContainer2 from "./EditorContainer2";
import FieldController from "./FieldController";

type ListFieldConfig<ItemValue, ValidItemValue extends ItemValue> = {
  initialValue: Array<ItemValue> | null;
  getController: (
    index: number | null
  ) => FieldController<ItemValue, ValidItemValue>;
};

export class ListFieldController<
  ItemValue,
  ValidItemValue extends ItemValue
> extends FieldController<Array<ItemValue>, Array<ValidItemValue>> {
  private controllers: Array<FieldController<ItemValue, ValidItemValue>> = [];
  private extraController: FieldController<ItemValue, ValidItemValue>;
  private getControllerFn: (
    index: number | null
  ) => FieldController<ItemValue, ValidItemValue>;

  constructor(config: ListFieldConfig<ItemValue, ValidItemValue>) {
    const controllers: Array<FieldController<ItemValue, ValidItemValue>> = [];
    if (config.initialValue) {
      config.initialValue.forEach((_, i) => {
        controllers[i] = config.getController(i);
      });
    }
    const extraController = config.getController(null);
    if (!extraController) throw new Error("No controller");
    super({
      initialValue: [],
      label: extraController.getLabel() || undefined,
      help: extraController.getHelp() || undefined,
      disabled: extraController.isDisabled(),
      hidden: extraController.isHidden(),
      validation: (v): asserts v is Array<ValidItemValue> => {
        //config.locales.forEach((l) => controllers[l].validate());
      },
    });
    this.controllers = controllers;
    this.setValueFn(() => {
      return this.controllers.map((c) => c.getValue());
    });
    this.extraController = extraController;
    this.getControllerFn = config.getController;
  }

  integrateExtraController() {
    this.controllers = [...this.controllers, this.extraController];
    this.extraController = this.getControllerFn(null);
    this.r.notify();
  }

  removeController(i: number) {
    this.controllers.splice(i, 1);
    this.controllers = [...this.controllers];
    this.r.notify();
  }

  useControllers() {
    return this.r.useSelector(() => this.controllers);
  }

  useExtraController() {
    return this.r.useSelector(() => this.extraController);
  }

  render(): React.ReactElement {
    return <ListField controller={this} />;
  }
}

export function ListField<Value, ValidValue extends Value>(props: {
  controller: ListFieldController<Value, ValidValue>;
}) {
  const controller = props.controller;
  const controllers = controller.useControllers();
  const extraController = controller.useExtraController();

  const extraControllerValue = extraController.useValue();
  const initialExtraControllerValue = useMemo(() => extraControllerValue, []);
  useEffect(() => {
    if (extraControllerValue !== initialExtraControllerValue) {
      controller.integrateExtraController();
    }
  }, [extraControllerValue]);

  const rowCss = css({
    display: "flex",
  });

  const numberCss = css({
    alignSelf: "center",
    paddingInline: 5,
    opacity: 0.5,
  });

  const fieldCss = css({
    flex: 1,
    flexShrink: 1,
    minWidth: 0,
  });

  const deleteCss = css({
    alignSelf: "center",
  });

  const nodes = [
    ...controllers.map((l, i) => (
      <div css={rowCss} key={l.id}>
        <div css={numberCss}>
          <Typo>{`#${i + 1}`}</Typo>
        </div>
        <div css={fieldCss}>{controllers[i].render()}</div>
        <div css={deleteCss}>
          <Button label="-" onClick={() => controller.removeController(i)} />
        </div>
      </div>
    )),
    <div css={rowCss} key={extraController.id}>
      <div css={numberCss}>
        <Typo>{`+`}</Typo>
      </div>
      <div css={fieldCss}>{extraController.render()}</div>
    </div>,
  ];

  return (
    <EditorContainer2 controller={controller}>
      <Intersperse between={() => <Divider />}>{nodes}</Intersperse>
    </EditorContainer2>
  );
}
