/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import DelayedView from "src/components/DelayedView";
import MetricEditor from "src/editors/MetricEditor";
import MetricModel from "src/models/MetricModel";
import {
  useChallenge,
  useChallengeService,
} from "src/utilities/ChallengeService";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function ChallengeMetricsEdition() {
  const { platform } = Services.use();
  const challenge = useChallenge();
  const challengeService = useChallengeService();
  const metricUuid = Navigation.useParam("metric");
  const navigate = useNavigate();

  const metric = useMemo(() => {
    return platform.get<MetricModel>(
      `/support/challenges/${challenge.uuid}/metrics/${metricUuid}`
    );
  }, []);

  const onSubmit = useSubmitCallback(
    async (data: Updates<MetricModel>) => {
      const metric = await platform.patch<MetricModel>(
        `/support/challenges/${challenge.uuid}/metrics/${metricUuid}`,
        data
      );
      challengeService.set({
        ...challenge,
        metrics: challenge.metrics.map((m) =>
          m.uuid === metricUuid ? metric : m
        ),
      });
    },
    { successMessage: "Validé !" },
    [metricUuid]
  );
  const onDelete = useSubmitCallback(
    async () => {
      await platform.delete<MetricModel>(
        `/support/challenges/${challenge.uuid}/metrics/${metricUuid}`
      );
      challengeService.set({
        ...challenge,
        metrics: challenge.metrics.filter((m) => m.uuid !== metricUuid),
      });
      navigate(Navigation.getPath("ChallengeMetrics"));
    },
    {
      successMessage: "Supprimé",
      confirmationMessage: "Supprimer la métrique ?",
    },
    []
  );

  return (
    <DelayedView promise={metric}>
      {(metric) => (
        <MetricEditor metric={metric} onDelete={onDelete} onSubmit={onSubmit} />
      )}
    </DelayedView>
  );
}
