async function confirm(message: string) {
  return await new Promise<boolean>((resolve) => {
    const output = window.confirm(message);
    resolve(output);
  });
}

const Dialogs = {
  confirm,
};

export default Dialogs;
