export enum PartnerDisplay {
  FullWidth = 'full-width',
  HalfWidth = 'half-width',
}

export default interface PartnerModel {
  uuid: string
  link: string | null
  imageUrl: string
  rank: number
  sort: number
  display: PartnerDisplay
}
