import { useMemo } from "react";
import ChallengeModel from "src/models/ChallengeModel";
import ThemeModel from "src/models/ThemeModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import FileFieldController, {
  UploadSlot,
} from "src/utilities/Editor2/FileFieldController";
import { I18nFieldController } from "src/utilities/Editor2/I18nFieldController";
import { MultiSelectFieldController } from "src/utilities/Editor2/MultiSelectFieldController";
import { SelectFieldController } from "src/utilities/Editor2/SelectFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { UrlFieldController } from "src/utilities/Editor2/UrlFieldController";
import { regex, required } from "src/utilities/Editor2/useValidate";
import Extractors from "src/utilities/Extractors";
import globalChallengeUpdater from "src/utilities/globalChallengeUpdater";
import Services from "src/utilities/Services";
import ThemePreview from "src/utilities/ThemePreview";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function ChallengeInfos() {
  const challenge = useChallenge();

  const { platform } = Services.use();

  const nameField = useMemo(() => {
    return new I18nFieldController({
      locales: challenge.locales,
      getController: (locale) => {
        return new TextFieldController({
          label: "Nom du challenge",
          initialValue: challenge.name ? challenge.name[locale] : null,
          validation: required,
        });
      },
    });
  }, []);

  const localesField = useMemo(() => {
    return new MultiSelectFieldController({
      initialValue: challenge.locales,
      label: "Langues disponibles",
      options: ["fr-FR", "en-GB", "es-ES"],
      keyExtractor: (o) => o,
      textExtractor: (o) => o,
      validation: required,
      disabled: true,
    });
  }, []);

  const FLOCKLER_REGEX =
    /https:\/\/plugins\.flockler\.com\/embed\/([0-9a-f]*)\/([0-9a-f]*)/;

  const flockerUrlField = useMemo(
    () =>
      new TextFieldController({
        label: "URL Flockler",
        initialValue: challenge.flocklerUrl,
        validation: (s): asserts s is string | null => {
          if (s === null) return;
          regex(s, FLOCKLER_REGEX);
        },
        help: "Format attendu : https://plugins.flockler.com/embed/xxxxxx/xxxxxx",
      }),
    []
  );

  const logoField = useMemo(() => {
    return new FileFieldController({
      label: "Logo",
      initialValue: challenge.logoUrl,
      accept: "image/*",
      getUploadSlot: (config) =>
        platform.post<UploadSlot>(
          "/support/challenge-logo-upload-slot",
          config
        ),
      validation: required,
    });
  }, []);

  const contactEmailField = useMemo(() => {
    return new TextFieldController({
      label: "Adresse email de contact",
      initialValue: challenge.contactEmail,
      validation: required,
    });
  }, []);

  const guideUrlField = useMemo(() => {
    return new FileFieldController({
      label: "Guide",
      initialValue: challenge.guideUrl,
      accept: ".pdf",
      getUploadSlot: (config) =>
        platform.post<UploadSlot>("/support/guide-upload-slot", config),
    });
  }, []);

  const termsField = useMemo(() => {
    return new I18nFieldController({
      locales: challenge.locales,
      getController: (locale) => {
        return new UrlFieldController({
          label: "Conditions générales d'utilisation",
          initialValue: challenge.terms ? challenge.terms[locale] : null,
          validation: required,
        });
      },
    });
  }, []);

  const privacyPolicyField = useMemo(() => {
    return new I18nFieldController({
      locales: challenge.locales,
      getController: (locale) => {
        return new UrlFieldController({
          label: "Politique de confidentialité",
          initialValue: challenge.privacyPolicy
            ? challenge.privacyPolicy[locale]
            : null,
          validation: required,
        });
      },
    });
  }, []);

  const themeField = useMemo(() => {
    return new SelectFieldController({
      label: "Thème",
      initialValue: challenge.themeUuid,
      options: platform.get<Array<ThemeModel>>("/support/themes"),
      keyExtractor: Extractors.uuid,
      textExtractor: Extractors.label,
      validation: required,
    });
  }, []);

  const theme = themeField.useOption();

  const onSubmit = useSubmitCallback(
    async () => {
      console.log(themeField.validate());
      const update: Partial<ChallengeModel> = {
        name: nameField.validate(),
        locales: localesField.validate(),
        logoUrl: logoField.validate(),
        flocklerUrl: flockerUrlField.validate(),
        contactEmail: contactEmailField.validate(),
        terms: termsField.validate(),
        guideUrl: guideUrlField.validate(),
        privacyPolicy: privacyPolicyField.validate(),
        themeUuid: themeField.validate(),
      };
      await platform.patch<ChallengeModel>(
        `/support/challenges/${challenge.uuid}`,
        update
      );
      globalChallengeUpdater.notify();
    },
    {
      successMessage: "Challenge sauvergardé !",
    },
    []
  );

  return (
    <Editor2.Form onSubmit={onSubmit}>
      {nameField.render()}
      {logoField.render()}

      {flockerUrlField.render()}
      {localesField.render()}

      {contactEmailField.render()}
      {guideUrlField.render()}
      {termsField.render()}
      {privacyPolicyField.render()}
      {themeField.render()}
      {theme ? <ThemePreview theme={theme} /> : null}
    </Editor2.Form>
  );
}
