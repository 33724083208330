/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PropsWithChildren } from "react";

type FormProps = PropsWithChildren<{}>;

export default function SubFormForm(props: FormProps) {
  const { children } = props;

  const containerCss = css({
    marginLeft: 20,
    borderLeft: `1px solid grey`,
    paddingLeft: 20,
  });

  return <div css={containerCss}>{props.children}</div>;
}
