/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  POPPINS_LINE_HEIGHT,
  TypoConfigs,
  TypoName,
} from "src/utilities/Typos";
import Typo from "./Typo";

interface BadgeProps {
  label: string;
  background: string;
  color: string;
  parentTypo?: TypoName;
  typo?: TypoName;
  onClick?: () => void;
}

export default function Badge(props: BadgeProps) {
  const { onClick, parentTypo = "body", typo = "badge" } = props;

  const naturalHeight = `( ${TypoConfigs[parentTypo].size} * ${POPPINS_LINE_HEIGHT} )`;
  const contentHeight = `( ${TypoConfigs[typo].size} * ${POPPINS_LINE_HEIGHT} )`;
  const paddingBlock = `( ( ${naturalHeight} - ${contentHeight} ) / 2 )`;

  const containerCss = css({
    background: props.background,
    color: props.color,
    borderRadius: 4,
    paddingBlock: `calc(${paddingBlock})`,
    paddingInline: `calc(${paddingBlock} * 3)`,
  });

  return (
    <div css={containerCss} onClick={onClick}>
      <Typo typo={typo}>{props.label}</Typo>
    </div>
  );
}
