import { Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { DateFieldController } from "src/utilities/Editor2/DateFieldController";
import { NumberFieldController } from "src/utilities/Editor2/NumberFieldController";
import { SelectFieldController } from "src/utilities/Editor2/SelectFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Services from "src/utilities/Services";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function ManualStepsPage() {
  const { platform } = Services.use();
  const challenge = useChallenge();
  const navigate = useNavigate();

  const emailField = useMemo(
    () =>
      new TextFieldController({
        label: "Adresse email du compte",
        initialValue: null,
        validation: required,
      }),
    []
  );

  const actionField = useMemo(
    () =>
      new SelectFieldController({
        label: "Action à effectuer",
        options: ["add", "substract"],
        textExtractor: (v) =>
          v === "add" ? "Ajouter des pas" : "Enlever des pas",
        keyExtractor: (v) => v,
        initialValue: null,
        validation: required,
      }),
    []
  );

  const action = actionField.useValue();

  const stepsField = useMemo(
    () =>
      new NumberFieldController({
        label:
          action === "add"
            ? "Nombre de pas à ajouter"
            : action === "substract"
            ? "Nombre de pas à soustraire"
            : "",
        initialValue: null,
        unit: () => " pas",
        validation: required,
      }),
    [action]
  );

  const startField = useMemo(
    () =>
      new DateFieldController({
        label: "Début",
        initialValue: new Date().toISOString(),
        validation: required,
      }),
    []
  );

  const endField = useMemo(
    () =>
      new DateFieldController({
        label: "Fin",
        initialValue: new Date().toISOString(),
        validation: required,
      }),
    []
  );

  const onSubmit = useSubmitCallback(
    async () => {
      const action = actionField.validate();
      const steps = stepsField.validate();
      const delta = action === "add" ? steps : steps * -1;
      const body = {
        startAt: startField.validate(),
        endAt: endField.validate(),
        stepNumber: delta,
        email: emailField.validate(),
        challengeUuid: challenge.uuid,
      };
      await platform.post<any>(`/support/step`, body);
      navigate(-1);
    },
    { successMessage: "C'est fait !" },
    [stepsField]
  );

  return (
    <Editor2.Form onSubmit={onSubmit}>
      {emailField.render()}
      {actionField.render()}
      {action ? (
        <Fragment>
          {stepsField.render()}
          {startField.render()}
          {endField.render()}
        </Fragment>
      ) : null}
    </Editor2.Form>
  );
}
