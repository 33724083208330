/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import Typo from "./Typo";

type InfoProps = {
  type: "info" | "warn" | "danger";
  message: string;
};

export default function Info(props: InfoProps) {
  const { type, message } = props;

  let color: string;
  if (type === "info") {
    color = "darkgreen";
  } else if (type === "warn") {
    color = "orange";
  } else {
    color = "darkred";
  }

  const containerCss = css({
    border: `1px solid ${color}`,
    padding: 10,
    backgroundColor: rgba(color, 0.1),
    borderRadius: 4,
    color: color,
  });

  return (
    <div css={containerCss}>
      <Typo>{message}</Typo>
    </div>
  );
}
