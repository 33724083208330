/** @jsxImportSource @emotion/react */
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { css } from "@emotion/react";
import React, { useCallback, useMemo, useState } from "react";
import Button from "src/components/Button";
import Css from "../Css";
import useBooleanState from "../useBooleanState";
import EditorContainer2 from "./EditorContainer2";
import FieldController, { FieldConfig } from "./FieldController";
import Input from "./Input";

type HtmlFieldValue = string | null;

type HtmlFieldConfig<ValidValue extends HtmlFieldValue> = FieldConfig<
  HtmlFieldValue,
  ValidValue
> & {};

export class HtmlFieldController<
  ValidValue extends HtmlFieldValue
> extends FieldController<HtmlFieldValue, ValidValue> {
  constructor(readonly config: HtmlFieldConfig<ValidValue>) {
    super(config);
  }

  render(): React.ReactElement {
    return React.createElement(HtmlField, { controller: this });
  }
}

export function HtmlField<ValidValue extends HtmlFieldValue>(props: {
  controller: HtmlFieldController<ValidValue>;
}) {
  const controller = props.controller;
  const { disabled } = controller.config;

  const inputCss = css(Css.inputReset, {
    flexGrow: 1,
  });

  const value = controller.useValue();

  const [opened, setOpened] = useBooleanState(false);
  const [editor, setEditor] = useState<ClassicEditor | null>(null);

  const confirmEdition = useCallback(() => {
    if (!editor) return;
    const html = editor.getData();
    controller.setValue(html);
    setOpened.toFalse();
  }, [editor]);

  const modalCss = css({
    position: "fixed",
    top: 100,
    left: 100,
    bottom: 100,
    right: 100,
    background: "white",
    border: `1px solid rgba(0,0,0,0.2)`,
    boxShadow: `0px 0px 40px rgba(0,0,0,0.5)`,
    borderRadius: 10,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    ".ck-editor": {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      minHeight: 0,
      ".ck-editor__main": {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        minHeight: 0,
        ".ck-editor__editable": {
          flex: 1,
          minHeight: 0,
        },
      },
      // height: "300px",
    },
  });

  const buttonsCss = css({
    display: "flex",
    padding: 20,
    justifyContent: "space-between",
  });

  const preview = useMemo(() => removeHtml(value || ""), [value]);

  return (
    <EditorContainer2 controller={controller}>
      <Input
        type={"text"}
        value={preview}
        css={inputCss}
        readOnly={true}
        onFocus={disabled ? undefined : setOpened.toTrue}
      />
      {opened ? (
        <div css={modalCss}>
          <CKEditor
            editor={ClassicEditor}
            data={value}
            onReady={setEditor}
            config={{
              toolbar: [
                "heading",
                "bold",
                "italic",
                "link",
                "bulletedList",
                "numberedList",
              ],
              heading: {
                options: [
                  {
                    model: "paragraph",
                    title: "Paragraphe",
                    class: "ck-heading_paragraph",
                  },
                  {
                    model: "heading2",
                    view: "h2",
                    title: "Titre",
                    class: "ck-heading_heading2",
                  },
                  {
                    model: "heading3",
                    view: "h3",
                    title: "Sous titre",
                    class: "ck-heading_heading3",
                  },
                ],
              },
            }}
          />
          <div css={buttonsCss}>
            <Button label="Annuler" onClick={setOpened.toFalse} />
            <Button label="Valider" onClick={confirmEdition} />
          </div>
        </div>
      ) : null}
    </EditorContainer2>
  );
}

/*

// /** @jsxImportSource @emotion/react */
// import PerLocaleStrings from "../PerLocaleStrings";
// import { Validation } from "./useValidate";

// interface PerLocaleHtmlFieldConfig<TValidated extends PerLocaleStrings | null> {
//   label: string;
//   initialValue: PerLocaleStrings | undefined | null;
//   locales: string[];
//   validation?: Validation<PerLocaleStrings | null, TValidated>;
// }

// export function usePerLocaleHtmlField<
//   TValidated extends PerLocaleStrings | null
// >(config: PerLocaleHtmlFieldConfig<TValidated>, deps: DependencyList = []) {
//   const { initialValue, label, locales, validation } = config;

//   const containerCss = css({
//     display: "flex",
//     flexDirection: "column",
//   });

//   const localeCss = css({
//     display: "flex",
//     flexDirection: "row",
//     alignItems: "center",
//     borderBottom: "1px solid lightgrey",
//     "&:last-child": {
//       borderBottom: "none",
//     },
//   });

//   const inputCss = css`
//     ${Css.inputReset}
//     padding: 4px;
//     flex-grow: 1;
//   `;

//   const normalizeValue = useCallback((value: PerLocaleStrings | null) => {
//     if (value === null) return null;
//     if (Object.values(value).length === 0) return null;
//     const validStrings: PerLocaleStrings = {};
//     Object.entries(value).forEach(([locale, value]) => {
//       const cleaned = trim(value);
//       if (!cleaned) return;
//       validStrings[locale] = cleaned;
//     });
//     if (Object.values(validStrings).length === 0) return null;
//     return validStrings;
//   }, []);

//   const [value, setValue] = useMemoState(() => {
//     if (initialValue === undefined) return null;
//     else return normalizeValue(initialValue);
//   }, [initialValue]);

//   const onChange = useCallback((locale: string, value: string) => {
//     setValue((current) => ({ ...current, [locale]: value }));
//   }, []);

//   const onBlur = useCallback((locale: string, value: string) => {
//     setValue((current) => normalizeValue({ ...current, [locale]: value }));
//   }, []);

//   const outputValue = useMemo(() => {
//     if (value === null) return null;
//     const keys = Object.keys(value).sort();
//     const sortedLocales = [...locales].sort();
//     if (!isEqual(keys, sortedLocales)) return null;
//     return value;
//   }, [value, locales]);

//   const texts = useMemo(() => {
//     if (value === null) return null;
//     return mapValues(value, (v) => removeHtml(v).substring(0, 30) + "...");
//   }, [value]);

//   const [selectedLocale, setSelectedLocale] = useState<string | null>(null);

//   console.log(selectedLocale);

//

//   const render = () => {
//     return (
//       <Fragment>
//         <EditorContainer label={label} validation={validation}>
//           {locales.map((locale) => {
//             return (
//               <div css={localeCss} key={locale}>
//                 <Spacer />
//                 <Badge
//                   label={getLocaleFlag(locale)}
//                   background="lightgrey"
//                   color="black"
//                 />
//                 <Spacer />
//                 <Input
//                   readOnly
//                   value={texts ? texts[locale] : ""}
//                   css={{ flex: 1 }}
//                   onFocus={() => setSelectedLocale(locale)}
//                 />
//               </div>
//             );
//           })}
//         </EditorContainer>
//         {selectedLocale ? (
//
//         ) : null}
//       </Fragment>
//     );
//   };

//   const validate = useValidate(outputValue, validation, deps);

//   return { render, value: outputValue, validate } as const;
// }

function removeHtml(html: string) {
  const el = document.createElement("div");
  el.innerHTML = html;
  return el.textContent || "";
}

// */
