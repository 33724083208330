/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import Css from "src/utilities/Css";
import PropsWithCss from "src/utilities/PropsWithCss";

type TargetProps = (
  | { submit: true }
  | { onClick: () => any }
  | { href: string; target?: string }
  | { to: string }
  | {}
) & { disabled?: boolean };

export type ClickableProps = PropsWithCss<PropsWithChildren<TargetProps>>;

export default function Clickable(props: ClickableProps) {
  const allCss = css`
    ${Css.clickableReset}
    cursor : pointer;
  `;

  if ("submit" in props) {
    return (
      <button css={allCss} className={props.className} type="submit">
        {props.children}
      </button>
    );
  } else if ("onClick" in props) {
    const onClick = props.onClick;
    return (
      <button
        type="button"
        css={allCss}
        className={props.className}
        onClick={onClick}
      >
        {props.children}
      </button>
    );
  } else if ("href" in props) {
    return (
      <a
        css={allCss}
        className={props.className}
        href={props.href}
        target={props.target}
      >
        {props.children}
      </a>
    );
  } else if ("to" in props) {
    return (
      <Link to={props.to} css={allCss} className={props.className}>
        {props.children}
      </Link>
    );
  } else {
    return (
      <div css={allCss} className={props.className}>
        {props.children}
      </div>
    );
  }
}

export function isDisabled(props: ClickableProps) {
  if (props.disabled) return true;
  if ("submit" in props && props.submit) return false;
  else if ("onClick" in props) return false;
  else if ("href" in props && props.href.length > 0) return false;
  else if ("to" in props && props.to.length > 0) return false;
  return true;
}
