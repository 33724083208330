/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PropsWithChildren } from "react";
import PropsWithCss from "src/utilities/PropsWithCss";
import Typos, { TypoName } from "src/utilities/Typos";

type TypoProps = PropsWithCss<
  PropsWithChildren<{
    Tag?: "p" | "span";
    typo?: TypoName;
    singleLine?: boolean;
    center?: boolean;
    opacity?: number;
  }>
>;

export default function Typo(props: TypoProps) {
  const {
    Tag = "p",
    typo = "body",
    singleLine = false,
    center = false,
    opacity = 1,
    className,
  } = props;
  let containerCss = css`
    ${Typos[typo]};
    margin: 0px;
    padding: 0px;
    text-align: ${center ? "center" : "left"};
    opacity: ${opacity};
  `;
  if (singleLine) {
    containerCss = css`
      ${containerCss}
      white-space : nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-shrink: 1;
    `;
  }
  return (
    <Tag css={containerCss} className={className}>
      {props.children}
    </Tag>
  );
}
