import { Fragment, useCallback, useMemo, useState } from "react";
import DelayedView from "src/components/DelayedView";
import PartnerEditor from "src/editors/PartnerEditor";
import Model from "src/models";
import PartnerModel from "src/models/PartnerModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Dialogs from "src/utilities/Dialogs";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function ChallengePartnersPage() {
  const challengeUuid = useChallenge().uuid;
  const { platform } = Services.use();

  const load = useMemo(async () => {
    return await platform.get<Model.Partner[]>(
      `/support/challenges/${challengeUuid}/partners`
    );
  }, [challengeUuid]);

  return (
    <DelayedView promise={load}>
      {(partners) => <ChallengePartners partners={partners} />}
    </DelayedView>
  );
}

type ChallengePartnersProps = {
  partners: Array<PartnerModel>;
};

function ChallengePartners(props: ChallengePartnersProps) {
  const [partners, setPartners] = useState(props.partners);

  const challengeUuid = useChallenge().uuid;
  const { platform } = Services.use();

  const [newPartnerKey, setNewPartnerKey] = useState(() => Math.random());

  const onCreate = useSubmitCallback(
    async (updates: Updates<PartnerModel>) => {
      const newPartner = await platform.post<Model.Partner>(
        `/support/challenges/${challengeUuid}/partners/`,
        updates
      );
      setPartners((p) => [...p, newPartner]);
      setNewPartnerKey(Math.random());
    },
    { successMessage: "Partenaire créé" },
    []
  );

  const onUpdate = useSubmitCallback(
    async (uuid: string, updates: Updates<PartnerModel>) => {
      const newPartner = await platform.put<Model.Partner>(
        `/support/challenges/${challengeUuid}/partners/${uuid}`,
        updates
      );
      setPartners((ps) => ps.map((p) => (p.uuid === uuid ? newPartner : p)));
    },
    { successMessage: "Partenaire modifié" },
    []
  );

  const onDelete = useCallback(async (uuid: string) => {
    if (!(await Dialogs.confirm("Supprimer définitivement ?"))) return;
    const newPartner = await platform.delete<void>(
      `/support/challenges/${challengeUuid}/partners/${uuid}`
    );
    setPartners((p) => p.filter((p) => p.uuid !== uuid));
  }, []);

  return (
    <Fragment>
      {partners.map((p) => (
        <PartnerEditor
          partner={p}
          onSubmit={(u) => onUpdate(p.uuid, u)}
          onDelete={() => onDelete(p.uuid)}
        />
      ))}
      <PartnerEditor
        key={newPartnerKey}
        partner={null}
        onSubmit={(u) => onCreate(u)}
      />
    </Fragment>
  );
}
