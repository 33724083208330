/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { without } from "lodash";
import {
  Fragment,
  Key,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import Button from "src/components/Button";
import Divider from "src/components/Divider";
import Intersperse from "../Intersperse";
import useBooleanState from "../useBooleanState";
import ListItem from "./ListItem";

type ExpandableListProps<TData> = {
  items: Array<TData>;
  keyExtractor: (d: TData) => Key;
  renderItem: (d: TData, index: number) => ReactNode | string;
  children: (item: TData, index: number) => ReactNode;
  renderCreation?: () => ReactNode;
};

export default function ExpandableList<TData>(
  props: ExpandableListProps<TData>
) {
  const { items, keyExtractor, renderItem, renderCreation, children } = props;

  const [newVisible, setNewVisible] = useBooleanState(false);

  useEffect(() => {
    setNewVisible.to(false);
  }, [items.length]);

  const [unfoldeds, setUnfoldeds] = useState<Array<Key>>([]);

  const toggleItem = useCallback((key: Key) => {
    setUnfoldeds((current) => {
      if (current.includes(key)) return without(current, key);
      else return [...current, key];
    });
  }, []);

  const containerCss = css({
    border: `1px solid lightgrey`,
    background: `#EEEEEE`,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
  });

  const wrapperCss = css({
    display: "flex",
    alignItems: "center",
  });

  const chevronCss = css({
    display: "flex",
    paddingInline: 10,
  });

  const contentCss = css({
    padding: 20,
  });

  return (
    <div css={containerCss}>
      <Intersperse between={() => <Divider />}>
        {items.map((item, index) => {
          const key = keyExtractor(item);
          const rendered = renderItem(item, index);
          let node: React.ReactNode = rendered;
          if (typeof node === "string") node = <ListItem label={node} />;
          const isUnfolded = unfoldeds.includes(key);
          return (
            <div key={key}>
              <div css={wrapperCss}>
                <div
                  css={css(chevronCss, {
                    transform: `rotate(${isUnfolded ? "90deg" : "0deg"})`,
                  })}
                  onClick={() => toggleItem(key)}
                >
                  {"➜"}
                </div>
                {node}
              </div>
              {isUnfolded ? (
                <div css={contentCss}>{children(item, index)}</div>
              ) : null}
            </div>
          );
        })}
      </Intersperse>
      {renderCreation ? (
        newVisible ? (
          <Fragment>
            <Divider />
            <div>
              <div css={wrapperCss}>
                <div
                  css={css(chevronCss, { transform: `rotate(90deg)` })}
                  onClick={setNewVisible.toFalse}
                >
                  {"➕"}
                </div>
                <ListItem label="Nouveau" />
              </div>
              <div css={contentCss}>{renderCreation()}</div>
            </div>
          </Fragment>
        ) : (
          <Button label="+" onClick={setNewVisible.toTrue} />
        )
      ) : null}
    </div>
  );
}
