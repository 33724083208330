/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { rgba } from "polished";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import Button from "src/components/Button";
import Clickable from "src/components/Clickable";
import DelayedView from "src/components/DelayedView";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import ChallengeService, {
  ChallengeServiceContext,
  useChallenge,
} from "src/utilities/ChallengeService";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";

export default function ChallengeWrapper() {
  const { platform } = Services.use();
  const challengeUuid = Navigation.useParam("challengeUuid");

  const challengeService = useMemo(async () => {
    const service = new ChallengeService(challengeUuid, platform);
    await service.init();
    return service;
  }, [challengeUuid]);

  return (
    <DelayedView promise={challengeService}>
      {(service) => (
        <ChallengeServiceContext.Provider value={service}>
          <ChallengeBar />
          <Outlet />
        </ChallengeServiceContext.Provider>
      )}
    </DelayedView>
  );
}

function ChallengeBar() {
  const containerCss = css({
    background: rgba("black", 0.1),
    borderRadius: 10,
    display: "flex",
    padding: 10,
    marginBottom: 20,
    alignItems: "center",
  });

  const challengeCss = css({
    display: "flex",
    alignItems: "center",
  });

  const iconCss = css({
    width: 60,
    height: 60,
  });

  const challenge = useChallenge();

  return (
    <div css={containerCss}>
      <Clickable to={Navigation.getPath("ChallengeHome")} css={challengeCss}>
        <img src={challenge.logoUrl} css={iconCss} />
        <Spacer />
        <div>
          <Typo typo="title">
            {challenge.name ? challenge.name["fr-FR"] : ""}
          </Typo>
          <Typo>
            {`Du ${dayjs(challenge.startAt).format("LLLL")} au ${dayjs(
              challenge.endAt
            ).format("LLLL")}`}
          </Typo>
        </div>
      </Clickable>
      <Spacer grow />
      <Button to={Navigation.getPath("Challenges")} label="Fermer" />
      <Spacer />
    </div>
  );
}
