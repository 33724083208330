import dayjs from "dayjs";
import fr from "dayjs/locale/fr";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import Root from "./Root";
import DataFetcher from "./utilities/DataFetcher";
import WhoAmI from "./utilities/WhoAmI";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.locale(fr);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

declare module "src/utilities/Services" {
  interface ServiceList {
    whoami: WhoAmI;
    platform: DataFetcher;
    tracker: DataFetcher;
    chat: DataFetcher;
  }
}

root.render(<Root />);
