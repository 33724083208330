const id = <T extends { id: any }>(
  i: T
): T extends { id: infer V } ? V : never => i.id;

const uuid = <T extends { uuid: any }>(
  i: T
): T extends { uuid: infer V } ? V : never => i.uuid;

const key = <T extends { key: any }>(
  i: T
): T extends { key: infer V } ? V : never => i.key;
const label = <T extends { label: any }>(
  i: T
): T extends { label: infer V } ? V : never => i.label;
const name = <T extends { name: any }>(
  i: T
): T extends { name: infer V } ? V : never => i.name;
const code = <T extends { code: any }>(
  i: T
): T extends { code: infer V } ? V : never => i.code;

const Extractors = {
  id,
  uuid,
  key,
  label,
  name,
  code,
};

export default Extractors;
