/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FormEvent, Fragment, PropsWithChildren, useCallback } from "react";
import Button from "src/components/Button";
import Spacer from "src/components/Spacer";

type FormProps = PropsWithChildren<{
  onSubmit?: () => any;
  submitLabel?: string;
  onDelete?: () => any;
}>;

export default function Form(props: FormProps) {
  const { onSubmit, onDelete, submitLabel = "Valider" } = props;

  const onFormSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (onSubmit === undefined) return;
      onSubmit();
    },
    [onSubmit]
  );

  const onFormDelete = useCallback(() => {
    if (onDelete === undefined) return;
    onDelete();
  }, []);

  const formCss = css`
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.05);
    padding: 10px;
    border-radius: 3px;
  `;

  const buttonsCss = css`
    display: flex;
    justify-content: flex-end;
  `;

  return (
    <form css={formCss} onSubmit={onFormSubmit}>
      {props.children}
      <Spacer />
      <div css={buttonsCss}>
        {onDelete !== undefined ? (
          <Fragment>
            <Button onClick={onFormDelete} label="Supprimer" dangerous />
            <Spacer />
          </Fragment>
        ) : null}
        {onSubmit != null ? <Button submit label={submitLabel} /> : null}
      </div>
    </form>
  );
}
