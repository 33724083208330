import { Fragment, useMemo } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import DelayedView from "src/components/DelayedView";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import ChallengeSensors from "src/editors/ChallengeSensors";
import LinkedAccountModel, {
  ActivitySourceType,
} from "src/models/LinkedAccountModel";
import SensorsModel from "src/models/SensorsModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Services from "src/utilities/Services";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function ChallengeSensorsPage() {
  const challengeUuid = useChallenge().uuid;
  const { tracker } = Services.use();

  const config = useMemo(async () => {
    return await tracker.get<SensorsModel>(
      `/support/challenges/${challengeUuid}/sensors`
    );
  }, [challengeUuid]);

  const accounts = useMemo(async () => {
    return await tracker.get<Array<LinkedAccountModel>>(
      `/support/challenges/${challengeUuid}/linked-accounts`
    );
  }, [challengeUuid]);

  const all = useMemo(
    () => Promise.all([config, accounts]),
    [config, accounts]
  );

  const onUpdateSensors = useSubmitCallback(
    async (sensors: SensorsModel) => {
      await tracker.put<SensorsModel>(
        `/support/challenges/${challengeUuid}/sensors`,
        sensors
      );
    },
    {},
    []
  );

  return (
    <Fragment>
      <DelayedView promise={all}>
        {([config, accounts]) => (
          <Fragment>
            <ChallengeSensors sensors={config} onUpdate={onUpdateSensors} />
            <Spacer scale={2} />
            <SensorsAccounts
              accounts={accounts}
              stravaLimit={
                config.strava ? config.strava.maximum_user_count : null
              }
            />
          </Fragment>
        )}
      </DelayedView>
    </Fragment>
  );
}

type SensorsAccountsProps = {
  accounts: Array<LinkedAccountModel>;
  stravaLimit: number | null;
};

function SensorsAccounts(props: SensorsAccountsProps) {
  const { accounts, stravaLimit } = props;

  const data = useMemo(() => {
    return [
      {
        service: ActivitySourceType.GARMIN,
        users: accounts.filter((a) => a.service === ActivitySourceType.GARMIN)
          .length,
        fill: "#007EC8",
      },
      {
        service: ActivitySourceType.STRAVA,
        users: accounts.filter((a) => a.service === ActivitySourceType.STRAVA)
          .length,
        limit: stravaLimit,
        fill: "#fc5200",
      },
      {
        service: ActivitySourceType.POLAR,
        users: accounts.filter((a) => a.service === ActivitySourceType.POLAR)
          .length,
        fill: "#FF1639",
      },
      {
        service: ActivitySourceType.FITBIT,
        users: accounts.filter((a) => a.service === ActivitySourceType.FITBIT)
          .length,
        fill: "#00B5BE",
      },
      {
        service: ActivitySourceType.SUUNTO,
        users: accounts.filter((a) => a.service === ActivitySourceType.SUUNTO)
          .length,
        fill: "#FF0004",
      },
    ];
  }, [accounts]);

  return (
    <Fragment>
      <Typo typo="title">
        Utilisateurs et limite d'utilisation, par capteur
      </Typo>
      <Spacer />
      <ResponsiveContainer height={300}>
        <ComposedChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Bar dataKey="users" stackId={"a"} />
          <Scatter dataKey="limit" fill="#FFFFFF" />
          <XAxis dataKey="service" interval={0} />
          <YAxis />
        </ComposedChart>
      </ResponsiveContainer>
    </Fragment>
  );
}
