/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import React, { Fragment, PropsWithChildren, useContext } from "react";
import Typo from "src/components/Typo";
import getErrorMessage from "../getErrorMessage";
import FieldController from "./FieldController";

type EditorContainerProps = PropsWithChildren<{
  controller: FieldController<any, any>;
}>;

export default function EditorContainer2(props: EditorContainerProps) {
  const { controller, children } = props;
  const label = controller.getLabel();
  const help = controller.getHelp();
  const disabled = controller.isDisabled();
  const hidden = controller.isHidden();
  const required = controller.isRequired();
  const error = controller.useError();

  const isInContainer = useContext(EditorContainerContext);

  const containerCss = css({
    display: "flex",
    flexDirection: "column",
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    background: "white",
    marginBottom: 20,
    borderRadius: 4,
    maxWidth: 500,
  });

  const labelCss = css({
    borderBottom: `1px solid rgba(0, 0, 0, 0.2)`,
    padding: 4,
    backgroundColor: rgba("blue", 0.05),
  });

  const helpCss = css({
    borderTop: `1px solid rgba(0, 0, 0, 0.2)`,
    padding: 4,
    backgroundColor: rgba("black", 0.05),
  });

  const errorCss = css({
    padding: 4,
    backgroundColor: rgba("darkred", 1),
    color: "white",
  });

  const childrenCss = css({
    position: "relative",
  });

  if (hidden) {
    return <Fragment />;
  }

  if (isInContainer) {
    return <Fragment key={controller.id}>{children}</Fragment>;
  } else {
    return (
      <EditorContainerContext.Provider value={true} key={controller.id}>
        <div css={containerCss}>
          <label css={labelCss}>
            <Typo typo="label">
              {disabled ? `🔒` : ""}
              {label}
              {required ? (
                <span css={css({ color: "red" })}> *</span>
              ) : (
                <Fragment />
              )}
            </Typo>
          </label>
          <div css={childrenCss}>{children}</div>
          {help ? (
            <div css={helpCss}>
              <Typo typo="label">ℹ️ {help}</Typo>
            </div>
          ) : null}
          {error ? (
            <div css={errorCss}>
              <Typo typo="label">❌ {getErrorMessage(error)}</Typo>
            </div>
          ) : null}
        </div>
      </EditorContainerContext.Provider>
    );
  }
}

const EditorContainerContext = React.createContext<boolean>(false);
