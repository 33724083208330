import { Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import DelayedView from "src/components/DelayedView";
import ConferenceEditor from "src/editors/ConferenceEditor";
import ConferenceModel from "src/models/ConferenceModel";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function ConferencesEdition() {
  const challengeUuid = Navigation.useParam("challengeUuid");
  const conferenceUuid = Navigation.useParam("conferenceUuid");
  const { platform } = Services.use();
  const navigate = useNavigate();

  const loading = useMemo(() => {
    return platform.get<ConferenceModel>(
      `/support/challenges/${challengeUuid}/conferences/${conferenceUuid}`
    );
  }, [conferenceUuid]);

  const onSubmit = useSubmitCallback(
    async (update: Updates<ConferenceModel>) => {
      await platform.patch<ConferenceModel>(
        `/support/challenges/${challengeUuid}/conferences/${conferenceUuid}`,
        update
      );
      navigate(Navigation.getPath("ConferencesHome"));
    },
    { successMessage: "Conférence modifiée !" }
  );

  const onDelete = useSubmitCallback(
    async () => {
      await platform.delete<ConferenceModel>(
        `/support/challenges/${challengeUuid}/conferences/${conferenceUuid}`
      );
      navigate(Navigation.getPath("ConferencesHome"));
    },
    {
      successMessage: "Conférence supprimée !",
      confirmationMessage: "Confirmez",
    }
  );

  return (
    <DelayedView promise={loading}>
      {(conference) => (
        <Fragment>
          <ConferenceEditor
            conference={conference}
            onSubmit={onSubmit}
            onDelete={onDelete}
          />
        </Fragment>
      )}
    </DelayedView>
  );
}
