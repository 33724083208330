enum MetricIcon {
  STEP = "step",
  POINT = "point",
  KM = "km",
  MILE = "mile",
  EURO = "euro",
  BURNED = "burned_calories",
  JONQUILLE = "jonquille",
}

export default MetricIcon;
