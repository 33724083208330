import { useMemo } from "react";
import MissionModel from "src/models/MissionModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { DateFieldController } from "src/utilities/Editor2/DateFieldController";
import FileFieldController, {
  UploadSlot,
} from "src/utilities/Editor2/FileFieldController";
import { I18nFieldController } from "src/utilities/Editor2/I18nFieldController";
import { NumberFieldController } from "src/utilities/Editor2/NumberFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import MimeTypes from "src/utilities/MimeTypes";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

type MissionEditorProps = {
  mission: MissionModel | null;
  onSubmit: (updates: Updates<MissionModel>) => any;
  onDelete?: () => any;
};

export default function MissionEditor(props: MissionEditorProps) {
  const { mission, onSubmit, onDelete } = props;
  const { platform } = Services.use();
  const locales = useChallenge().locales;

  const nameField = useMemo(
    () =>
      new I18nFieldController({
        locales,
        getController: (l) =>
          new TextFieldController({
            label: "Nom de la mission",
            initialValue: mission ? mission.name[l] : null,
            validation: required,
          }),
      }),
    []
  );

  const startAtField = useMemo(
    () =>
      new DateFieldController({
        label: "Début de la mission",
        initialValue: mission ? mission.startAt : null,
        validation: required,
      }),
    []
  );

  const endAtField = useMemo(
    () =>
      new DateFieldController({
        label: "Fin de la mission",
        initialValue: mission ? mission.endAt : null,
        validation: required,
      }),
    []
  );

  const goalField = useMemo(
    () =>
      new NumberFieldController({
        label: "Objectif (en points)",
        initialValue: mission ? mission.goalPoints : null,
        validation: required,
        unit: (n) => (n <= 1 ? " point" : " points"),
      }),
    []
  );

  const pointsField = useMemo(
    () =>
      new NumberFieldController({
        label: "Récompense (en points)",
        initialValue: mission ? mission.points : null,
        nullValueMeaning: "Aucune récompense",
        unit: (n) => (n <= 1 ? " point" : " points"),
      }),
    []
  );

  const imageField = useMemo(
    () =>
      new FileFieldController({
        label: "Image d'illutrstion",
        initialValue: mission ? mission.pictureUrl : null,
        getUploadSlot: () =>
          platform.get<UploadSlot>("/support/mission-pictures"),
        accept: MimeTypes.images,
        validation: required,
      }),
    []
  );

  const onEditorSubmit = useSubmitCallback(
    () => {
      const updates: Updates<MissionModel> = {
        name: nameField.validate(),
        startAt: startAtField.validate(),
        endAt: endAtField.validate(),
        goalPoints: goalField.validate(),
        points: pointsField.validate(),
        pictureUrl: imageField.validate(),
      };
      onSubmit(updates);
    },
    {},
    [onSubmit]
  );

  return (
    <Editor2.Form onSubmit={onEditorSubmit} onDelete={onDelete}>
      {nameField.render()}
      {startAtField.render()}
      {endAtField.render()}
      {goalField.render()}
      {pointsField.render()}
      {imageField.render()}
    </Editor2.Form>
  );
}
