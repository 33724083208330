/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { deburr } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import Clickable from "src/components/Clickable";
import Divider from "src/components/Divider";
import Typo from "src/components/Typo";
import RootClick from "../RootClick";
import EditorContainer2 from "./EditorContainer2";
import FieldController, { FieldConfig } from "./FieldController";
import Input from "./Input";

export type CodeFieldValue = true | string | null;

type CodeFieldConfig<ValidValue extends CodeFieldValue> = FieldConfig<
  CodeFieldValue,
  ValidValue
>;

export class CodeFieldController<
  ValidValue extends CodeFieldValue
> extends FieldController<CodeFieldValue, ValidValue> {
  constructor(readonly config: CodeFieldConfig<ValidValue>) {
    super(config);
  }

  render(): React.ReactElement {
    return <CodeField controller={this} />;
  }
}

type CodeFieldProps<ValidValue extends CodeFieldValue> = {
  controller: CodeFieldController<ValidValue>;
};

function CodeField<ValidValue extends CodeFieldValue>(
  props: CodeFieldProps<ValidValue>
) {
  const { controller } = props;
  const {} = controller.config;

  const [value, setValue] = useState<CodeFieldValue>(() =>
    controller.getValue()
  );
  controller.useValueModifier(value);

  const [input, setInput] = useState<string | null>(null);

  const contentCss = css({});

  const optionCss = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  });

  const inputCss = css({
    flexGrow: 1,
  });

  const onChangeCode = useCallback((input: string | null) => {
    if (input === null) {
      setInput(null);
      setValue(null);
    } else {
      let output = input || "";
      output = output.toUpperCase();
      output = deburr(output);
      output = output.replace(/[^A-Z0-9]/g, "-");
      output = output.replace(/(--)/g, "-");
      setInput(output);
      setValue(output);
    }
  }, []);

  useEffect(() => {
    if (value === true || value === null) setInput(null);
  }, [value]);

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <RootClick.Boundary>
      <EditorContainer2 controller={controller}>
        <div css={contentCss}>
          <Clickable css={optionCss} onClick={() => setValue(true)}>
            <Typo>
              {value === true ? `◉` : `⭘`}
              {" Créer un code aléatoire (format XXX-000-XXX)"}
            </Typo>
          </Clickable>
          <Divider />
          <Clickable
            css={optionCss}
            onClick={() => (inputRef.current ? inputRef.current.focus() : null)}
          >
            <Typo>
              {typeof value === "string" ? `◉` : `⭘`}
              {" Utiliser le code suivant : "}
            </Typo>
            <Input
              type="text"
              value={input}
              css={inputCss}
              onValueChange={onChangeCode}
              inputRef={inputRef}
            />
          </Clickable>
          <Divider />
          <Clickable css={optionCss} onClick={() => setValue(null)}>
            <Typo>
              {value === null ? `◉` : `⭘`}
              {" Ne pas créer de code"}
            </Typo>
          </Clickable>
        </div>
      </EditorContainer2>
    </RootClick.Boundary>
  );
}

/*

*/
