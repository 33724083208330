/** @jsxImportSource @emotion/react */
import { Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import DelayedView from "src/components/DelayedView";
import Typo from "src/components/Typo";
import SubGroupEditor from "src/editors/SubGroupEditor";
import AccountModel from "src/models/AccountModel";
import GroupModel from "src/models/GroupModel";
import RoomModel from "src/models/RoomModel";
import SubGroupModel from "src/models/SubGroupModel";
import { useChallenge } from "src/utilities/ChallengeService";
import List from "src/utilities/List";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";
import { AccountItem } from "./GroupEdition";

export default function SubGroupEdition() {
  const challenge = useChallenge();
  const groupUuid = Navigation.useParam("group");
  const subGroupUuid = Navigation.useParam("subgroup");

  const { platform, chat } = Services.use();
  const navigate = useNavigate();

  const subgroup = useMemo(() => {
    return platform.get<GroupModel>(
      `/support/challenges/${challenge.uuid}/groups/${groupUuid}/subgroups/${subGroupUuid}`
    );
  }, [groupUuid, subGroupUuid]);

  const accounts = useMemo(() => {
    return platform.get<Array<AccountModel>>(
      `/support/challenges/${challenge.uuid}/accounts`,
      { params: { team: subGroupUuid } }
    );
  }, [subGroupUuid]);

  const onSubmit = useSubmitCallback(
    async (updates: Updates<SubGroupModel>) => {
      await platform.patch<SubGroupModel>(
        `/support/challenges/${challenge.uuid}/groups/${groupUuid}/subgroups/${subGroupUuid}`,
        updates
      );
      await chat.patch<RoomModel>(`/support/rooms/${subGroupUuid}`, {
        name: updates.name,
      });
    },
    { successMessage: "Modifié" },
    []
  );

  const onDelete = useSubmitCallback(
    async () => {
      await platform.delete(
        `/support/challenges/${challenge.uuid}/groups/${groupUuid}/subgroups/${subGroupUuid}`
      );
      navigate(Navigation.getPath("Groups"));
    },
    {
      successMessage: "Supprimé !",
      confirmationMessage: "Confirmer la suppression",
    },
    [groupUuid, subGroupUuid]
  );

  return (
    <Fragment>
      <DelayedView promise={subgroup}>
        {(subgroup) => (
          <SubGroupEditor
            subgroup={subgroup}
            onSubmit={onSubmit}
            onDelete={onDelete}
          />
        )}
      </DelayedView>
      <Typo typo="title">Membres</Typo>
      <DelayedView promise={accounts}>
        {(accounts) => (
          <Fragment>
            <List
              items={accounts}
              renderItem={(g) => (
                <AccountItem
                  account={g}
                  to={Navigation.getPath("Participant", {
                    params: { participant: g.uuid },
                  })}
                />
              )}
              keyExtractor={(g) => g.uuid}
            />
          </Fragment>
        )}
      </DelayedView>
    </Fragment>
  );
}
