/** @jsxImportSource @emotion/react */

import axios from "axios";
import { useMemo } from "react";
import DelayedView from "src/components/DelayedView";

type RecolouredSvgProps = {
  src: string;
  recolorations: { [color: string]: string };
};

export default function RecolouredSvg(props: RecolouredSvgProps) {
  const { src, recolorations } = props;

  const data = useMemo(async () => {
    const res = await axios.get<string>(props.src, { responseType: "text" });
    return res.data;
  }, [src]);

  const recoloured = useMemo(async () => {
    let output = await data;
    Object.entries(recolorations).forEach(([source, dest]) => {
      output = output.replaceAll(source, dest);
    });
    return output;
  }, [data, recolorations]);

  const url = useMemo(async () => {
    const recol = await recoloured;
    return URL.createObjectURL(new Blob([recol], { type: "image/svg+xml" }));
  }, [recoloured]);

  return (
    <DelayedView promise={url}>{(url) => <img src={url} alt="" />}</DelayedView>
  );
}
