import { Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import DelayedView from "src/components/DelayedView";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import PolicyEditor from "src/editors/PolicyEditor";
import PolicyModel from "src/models/PolicyModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function PolicyEdition() {
  const challengeUuid = useChallenge().uuid;
  const policyUuid = Navigation.useParam("policy");
  const { platform } = Services.use();
  const navigate = useNavigate();

  const load = useMemo(async () => {
    return await platform.get<PolicyModel>(
      `/support/challenges/${challengeUuid}/policies/${policyUuid}`
    );
  }, []);

  const onSubmit = useSubmitCallback(
    async (updates: Updates<PolicyModel>) => {
      await platform.patch<PolicyModel>(
        `/support/challenges/${challengeUuid}/policies/${policyUuid}`,
        updates
      );
    },
    { successMessage: "Texte modifié" },
    []
  );

  const onDelete = useSubmitCallback(
    async () => {
      await platform.delete<PolicyModel>(
        `/support/challenges/${challengeUuid}/policies/${policyUuid}`
      );
      navigate(Navigation.getPath("Policies"));
    },
    { confirmationMessage: "Confirmer", successMessage: "Texte modifié" },
    []
  );

  const link = `${platform.axios.defaults.baseURL}/policies/${policyUuid}`;

  const copy = useSubmitCallback(
    () => navigator.clipboard.writeText(link),
    { successMessage: "Copié !" },
    []
  );

  return (
    <DelayedView promise={load}>
      {(policy) => (
        <Fragment>
          <Typo typo="title">Modifier le texte</Typo>
          <PolicyEditor
            policy={policy}
            onSubmit={onSubmit}
            onDelete={onDelete}
          />
          <Spacer />
          <Typo typo="title">Lien d'accès</Typo>
          <Typo>{link}</Typo>
          <Spacer />
          <Button onClick={copy} label="Copier le lien" />
          <Spacer />
          <Button href={link} target="blank" label="Visualiser le texte" />
        </Fragment>
      )}
    </DelayedView>
  );
}
