import axios from "axios";
import { get } from "lodash";

export class FrontError extends Error {}

export default function getErrorMessage(
  err: any,
  def: string = "Une erreur est survenue :/"
) {
  if (err === null || err === undefined) {
    return def;
  } else if (err instanceof FrontError) {
    return err.message;
  } else if (get(err, "type") === "BusinessException") {
    return err.message;
  }
  if (axios.isAxiosError(err)) {
    let output = err.message;
    if (err.response != null) {
      const data = err.response.data;
      const dataError = getErrorMessage(data);
      if (dataError !== null) {
        output = `${output} - ${dataError}`;
      }
    }
    return output;
  } else if (typeof err === "object") {
    const message = err.message;
    if (typeof message === "string") {
      return message;
    }
  }
  return def;
}
