/** @jsxImportSource @emotion/react */
import { Fragment, useMemo } from "react";
import Button from "src/components/Button";
import { ClickableProps } from "src/components/Clickable";
import DelayedView from "src/components/DelayedView";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import GroupModel from "src/models/GroupModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { NumberFieldController } from "src/utilities/Editor2/NumberFieldController";
import { SelectFieldController } from "src/utilities/Editor2/SelectFieldController";
import { SwitchFieldController } from "src/utilities/Editor2/SwitchFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import List from "src/utilities/List";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function Groups() {
  const challenge = useChallenge();

  const { platform } = Services.use();

  const groups = useMemo(() => {
    return platform.get<Array<GroupModel>>(
      `/support/challenges/${challenge.uuid}/groups`
    );
  }, []);

  const freeGroupsField = useMemo(() => {
    return new SwitchFieldController({
      label: "Permettre aux utilisateurs de créer des groupes libres",
      initialValue: challenge.freeGroupsEnabled,
      validation: required,
    });
  }, []);

  const defaultGroupSizeLimitField = useMemo(() => {
    return new NumberFieldController({
      label: "Nombre maximum de participants par groupe",
      initialValue: challenge.defaultGroupSizeLimit,
      decimals: 0,
      unit: (n) => (n > 1 ? " participants/groupe" : " participant/groupe"),
      nullValueMeaning: "Aucune limite",
      help: `Les groupes créés par les participants depuis l'app mobile ne pourront pas dépasser cette limite.`,
    });
  }, []);

  const managedGroupDisplayField = useMemo(() => {
    return new SelectFieldController({
      label: "Nommage des groupes supervisés",
      initialValue: challenge.managedGroupDisplay,
      options: [
        { value: "company", label: "Entreprise" },
        { value: "cabinet", label: "Cabinet" },
      ],
      keyExtractor: (v) => v.value,
      textExtractor: (v) => v.label,
    });
  }, []);

  const defaultTeamSizeLimitField = useMemo(() => {
    return new NumberFieldController({
      label: "Nombre maximum de participants par équipe",
      initialValue: challenge.defaultTeamSizeLimit,
      decimals: 0,
      unit: (n) => (n > 1 ? " participants/équipe" : " participant/équipe"),
      nullValueMeaning: "Aucune limite",
      help: `Les équipes créées par les participants depuis l'app mobile ne pourront pas dépasser cette limite`,
    });
  }, []);

  const teamDisplayField = useMemo(() => {
    return new SelectFieldController({
      label: "Nommage des équipes",
      initialValue: challenge.teamDisplay,
      options: [
        { value: "team", label: "Equipe" },
        { value: "duo", label: "Duo" },
      ],
      keyExtractor: (v) => v.value,
      textExtractor: (v) => v.label,
    });
  }, []);

  const onSubmitManagedGroups = useSubmitCallback(
    async () => {
      await platform.patch(`/support/challenges/${challenge.uuid}`, {
        managedGroupDisplay: managedGroupDisplayField.validate(),
      });
    },
    { successMessage: "Validé" },
    []
  );

  const onSubmitFreeGroups = useSubmitCallback(
    async () => {
      await platform.patch(`/support/challenges/${challenge.uuid}`, {
        freeGroupsEnabled: freeGroupsField.validate(),
        defaultGroupSizeLimit: defaultGroupSizeLimitField.validate(),
      });
    },
    { successMessage: "Validé" },
    []
  );

  const onSubmitTeams = useSubmitCallback(
    async () => {
      await platform.patch(`/support/challenges/${challenge.uuid}`, {
        teamDisplay: teamDisplayField.validate(),
        defaultTeamSizeLimit: defaultTeamSizeLimitField.validate(),
      });
    },
    { successMessage: "Validé" },
    []
  );

  return (
    <Fragment>
      <Button
        center
        label="Nouveau groupe"
        to={Navigation.getPath("GroupCreation")}
      />
      <Spacer />
      <Typo typo="title">Liste des groupes</Typo>
      <DelayedView promise={groups}>
        {(groups) => (
          <List
            items={groups}
            keyExtractor={(d) => d.uuid}
            renderItem={(d) => (
              <GroupItem
                group={d}
                to={Navigation.getPath("GroupEdition", {
                  params: { group: d.uuid },
                })}
              />
            )}
          />
        )}
      </DelayedView>
      <Spacer />
      <Typo typo="title">Fonctionnement des groupes supervisés</Typo>
      <Typo>Anciennement groupes "Entreprise"</Typo>
      <Editor2.Form onSubmit={onSubmitManagedGroups}>
        {managedGroupDisplayField.render()}
      </Editor2.Form>
      <Spacer />
      <Typo typo="title">Fonctionnement des groupes libres</Typo>
      <Typo>Anciennement groupes "Personnels"</Typo>
      <Editor2.Form onSubmit={onSubmitFreeGroups}>
        {freeGroupsField.render()}
        {defaultGroupSizeLimitField.render()}
      </Editor2.Form>
      <Spacer />
      <Typo typo="title">Fonctionnement des équipes</Typo>
      <Editor2.Form onSubmit={onSubmitTeams}>
        {teamDisplayField.render()}
        {defaultTeamSizeLimitField.render()}
      </Editor2.Form>
      <Spacer />
    </Fragment>
  );
}

type GroupItemProps = {
  group: GroupModel;
} & ClickableProps;

function GroupItem(props: GroupItemProps) {
  const { group, ...clickable } = props;
  return <List.Item label={props.group.name} {...clickable} />;
}
