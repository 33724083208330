/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { rgba } from "polished";
import React, { useCallback, useMemo, useState } from "react";
import Calendar from "react-calendar";
import Button from "src/components/Button";
import Typo from "src/components/Typo";
import Css from "../Css";
import RootClick from "../RootClick";
import EditorContainer2 from "./EditorContainer2";
import FieldController, { FieldConfig } from "./FieldController";
import Input from "./Input";
import Picker, { usePicker } from "./Picker";

type DateFieldValue = string | null;

type DateFieldConfig<ValidValue extends DateFieldValue> = FieldConfig<
  DateFieldValue,
  ValidValue
> & { dateOnly?: boolean; nullValueMeaning?: string; prefix?: string };

export class DateFieldController<
  ValidValue extends DateFieldValue
> extends FieldController<DateFieldValue, ValidValue> {
  constructor(readonly config: DateFieldConfig<ValidValue>) {
    super(config);
  }

  render(): React.ReactElement {
    return React.createElement(DateField, { controller: this });
  }
}

function DateField<ValidValue extends DateFieldValue>(props: {
  controller: DateFieldController<ValidValue>;
}) {
  const controller = props.controller;
  const { dateOnly = false, nullValueMeaning, prefix } = controller.config;
  const disabled = controller.isDisabled();

  // Value

  const value = controller.useValue();

  const date = useMemo(() => {
    if (value === undefined) return null;
    if (value === null) return null;
    if (value === "") return null;
    let date = dayjs(value);
    if (!date.isValid()) return null;
    if (dateOnly) date = date.startOf("day");
    return date;
  }, [value]);

  // Timezone

  const [timezone, setTimezone] = useState<string>(() => {
    const stored = localStorage.getItem("TZ");
    if (stored) return stored;
    else return dayjs.tz.guess();
  });

  const localeTimezone = useMemo(() => dayjs.tz.guess(), []);

  // Picker mode

  const [pickerMode, setPickerMode] = useState<"date" | "time" | "timezone">(
    "date"
  );

  const displayedValue = useMemo(() => {
    if (date === null) return "";
    let output = date.tz(timezone).format(dateOnly ? "LL" : "LLLL");
    if (timezone !== localeTimezone) output += ` (${timezone.split("/")[1]})`;
    if (prefix) output = prefix + output;
    return output;
  }, [prefix, date, timezone, localeTimezone]);

  const minorValue = useMemo(() => {
    if (date === null) return null;
    if (timezone === localeTimezone) return;
    return `Soit ${date.tz().format(dateOnly ? "LL" : "LLLL")} pour vous`;
  }, [date, timezone, localeTimezone]);

  // Day picker

  const pickerValue = useMemo(() => {
    return date ? date.toDate() : null;
  }, [date]);

  const onChangeDate = useCallback(
    (value: Date) => {
      const day = dayjs(value).format("YYYY-MM-DD");
      const hours = date ? date.format("HH:mm:ss.SSS") : "00:00:00.000";
      const newDate = dayjs.tz(`${day}T${hours}`, timezone);
      controller.setValue(newDate.toISOString());
    },
    [date, timezone]
  );

  const onChangeTime = useCallback(
    (value: Date) => {
      const day = date
        ? date.format("YYYY-MM-DD")
        : dayjs().format("YYYY-MM-DD");
      const hours = dayjs(value).format("HH:mm:ss.SSS");
      const newDate = dayjs.tz(`${day}T${hours}`, timezone);
      controller.setValue(newDate.toISOString());
    },
    [date, timezone]
  );

  const onChangeTimezone = useCallback(
    (tz: string) => {
      const day = date
        ? date.format("YYYY-MM-DD")
        : dayjs().format("YYYY-MM-DD");
      const hours = date ? date.format("HH:mm:ss.SSS") : "00:00:00.000";
      const newDate = dayjs.tz(`${day}T${hours}`, tz);
      controller.setValue(newDate.toISOString());
      setTimezone(tz);
    },
    [date, timezone]
  );

  // Time Picker

  const onFocus = useCallback(() => {
    RootClick.trigger();
    picker.setOpened.toTrue();
  }, []);

  const inputCss = css(Css.inputReset, {
    flexGrow: 1,
  });

  const pickerCss = css(Css.inputReset, {
    position: "absolute",
    zIndex: 2,
    top: "100%",
    left: 0,
    right: 0,
    background: "white",
    width: 300,
    boxShadow: `0px 0px 20px ${rgba("black", 0.2)}`,
    table: {
      width: "100%",
    },
  });

  const tabsCss = css({
    display: "flex",
  });

  const tabCss = css({
    padding: 10,
    borderBottom: `1px solid black`,
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  });

  const picker = usePicker();

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <EditorContainer2 controller={controller}>
        <Input
          type="text"
          readOnly
          value={displayedValue}
          css={inputCss}
          onFocus={onFocus}
          disabled={disabled}
          nullValueMeaning={nullValueMeaning}
        />
        {minorValue ? <Typo typo="minor">{minorValue}</Typo> : null}
        <Picker {...picker}>
          <div css={tabsCss}>
            <div css={tabCss} onClick={() => setPickerMode("date")}>
              Date
            </div>
            {dateOnly === false ? (
              <div css={tabCss} onClick={() => setPickerMode("time")}>
                Heure
              </div>
            ) : null}
            <div css={tabCss} onClick={() => setPickerMode("timezone")}>
              Fuseau horaire
            </div>
          </div>
          {pickerMode === "date" ? (
            <Calendar value={pickerValue} onChange={onChangeDate} locale="fr" />
          ) : null}
          {pickerMode === "time" ? (
            <TimeSelector
              value={pickerValue}
              onChange={onChangeTime}
              offset={15}
            />
          ) : null}
          {pickerMode === "timezone" ? (
            <TimezoneSelector value={timezone} onChange={onChangeTimezone} />
          ) : null}
          <Button
            label="Vider"
            onClick={() => {
              controller.setValue(null);
              RootClick.trigger();
            }}
          />
          :
          <Button label="Valider" onClick={RootClick.trigger} />
        </Picker>
        {picker ? <div css={pickerCss}></div> : null}
      </EditorContainer2>
    </div>
  );
}

type TimeSelectorProps = {
  value: Date | null;
  onChange: (date: Date) => any;
  offset: number;
};

function TimeSelector(props: TimeSelectorProps) {
  const { value, onChange, offset } = props;

  const values = useMemo(() => {
    let cursor = dayjs(value).startOf("day");
    const end = cursor.endOf("day");
    const options: Array<dayjs.Dayjs> = [];
    while (cursor.isBefore(end)) {
      options.push(cursor);
      cursor = cursor.add(offset, "minutes");
    }
    return options;
  }, [value, offset]);

  const selectorCss = css({
    height: 300,
    overflow: "auto",
  });

  return (
    <div css={selectorCss}>
      {values.map((v) => (
        <div key={v.toISOString()} onClick={() => onChange(v.toDate())}>
          {v.format("HH[h]mm")}
        </div>
      ))}
    </div>
  );
}

type TimezoneSelectorProps = {
  value: string | null;
  onChange: (tz: string) => any;
};

function TimezoneSelector(props: TimezoneSelectorProps) {
  const { value, onChange } = props;

  const values = useMemo(() => {
    return Intl.supportedValuesOf("timeZone");
  }, []);

  const selectorCss = css({
    height: 300,
    overflow: "auto",
  });

  return (
    <div css={selectorCss}>
      {values.map((v) => (
        <div key={v} onClick={() => onChange(v)}>
          {v}
        </div>
      ))}
    </div>
  );
}

declare namespace Intl {
  type Key =
    | "calendar"
    | "collation"
    | "currency"
    | "numberingSystem"
    | "timeZone"
    | "unit";
  function supportedValuesOf(input: Key): string[];
}
