function ensureString(key: any): string {
  const value = process.env[key];
  if (typeof value !== "string")
    throw new Error(`Missing environment var ${key}`);
  if (value === "") throw new Error(`Missing environment var ${key}`);
  return value;
}

function searchString<TDef>(key: any, def: TDef): string | TDef {
  try {
    return ensureString(key);
  } catch (err) {
    return def;
  }
}

const Environment = {
  ensureString,
  searchString,
};

export default Environment;
