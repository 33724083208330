import { useMemo } from "react";
import SubGroupModel from "src/models/SubGroupModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { NumberFieldController } from "src/utilities/Editor2/NumberFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

type SubGroupEditorProps = {
  subgroup: SubGroupModel | null;
  onSubmit: (updater: Updates<SubGroupModel>) => any;
  onDelete?: () => any;
};

export default function SubGroupEditor(props: SubGroupEditorProps) {
  const { subgroup, onSubmit, onDelete } = props;
  const challenge = useChallenge();

  const nameField = useMemo(
    () =>
      new TextFieldController({
        label: "Nom du sous-groupe",
        initialValue: subgroup ? subgroup.name : null,
        validation: required,
      }),
    []
  );

  const sizeLimitField = useMemo(() => {
    return new NumberFieldController({
      label: "Taille limite",
      initialValue: subgroup
        ? subgroup.sizeLimit
        : challenge.defaultTeamSizeLimit,
      nullValueMeaning: "Aucune limite",
      help: "Nombre maximal de participants pouvant rejoindre cette équipe",
      unit: (n) =>
        n <= 1
          ? ` participant max dans cette équipe`
          : ` participants max dans cette équipe`,
    });
  }, []);

  const onFormSubmit = useSubmitCallback(
    () => {
      const name = nameField.validate();
      const sizeLimit = sizeLimitField.validate();
      onSubmit({ name, sizeLimit });
    },
    {},
    []
  );

  return (
    <Editor2.Form onSubmit={onFormSubmit} onDelete={onDelete}>
      {nameField.render()}
      {sizeLimitField.render()}
    </Editor2.Form>
  );
}
