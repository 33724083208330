import Form from "./Form";
import SecretFieldController from "./SecretFieldController";
import SubForm from "./SubForm";

const Editor2 = {
  Form,
  SubForm,
  SecretFieldController,
};

export default Editor2;
