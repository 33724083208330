/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import saveAs from "file-saver";
import Papa from "papaparse";
import { Fragment, PropsWithChildren, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import AccountModel from "src/models/AccountModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import { FrontError } from "src/utilities/getErrorMessage";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import useSubmitCallback from "src/utilities/useSubmitCallback";
import * as xlsx from "xlsx";

export default function ChallengeMenu() {
  return (
    <div>
      <Typo typo="title">Configuration du challenge </Typo>
      <Spacer />
      <Button label="Informations" to={Navigation.getPath("Informations")} />
      <Spacer />
      <Button
        label="Début et fin de challenge"
        to={Navigation.getPath("ChallengeDates")}
      />
      <Spacer />
      <Button
        label="Inscription et connexion"
        to={Navigation.getPath("Signin")}
      />
      <Spacer />
      <Button label="Groupes et équipes" to={Navigation.getPath("Groups")} />
      <Spacer />
      <Button
        label="Textes réglementaires"
        to={Navigation.getPath("Policies")}
      />
      <Spacer />
      <Button label="Codes d'accès" to={Navigation.getPath("Codes")} />
      <Spacer />
      <Button label="Classements" to={Navigation.getPath("Rankings")} />
      <Spacer />
      <Button label="Partenaires" to={Navigation.getPath("Partners")} />
      <Spacer />
      <Button
        label="Sports et unités"
        to={Navigation.getPath("ChallengeMetrics")}
      />
      <Spacer />
      <Button label="Capteurs" to={Navigation.getPath("Sensors")} />
      <Spacer />
      <Button label="Conférences" to={Navigation.getPath("ConferencesHome")} />
      <Spacer />
      <Button label="Missions" to={Navigation.getPath("MissionsHome")} />
      <Spacer />
      <Button label="Quiz" to={Navigation.getPath("QuizzesHome")} />
      <Spacer />
      <Button label="Cadeaux" to={Navigation.getPath("Presents")} />
      <Spacer />
      <Button
        label="Notifications"
        to={Navigation.getPath("NotificationsHome")}
      />
      <Spacer />
      <SearchUser />
      <Spacer />
      <Typo typo="title">Gérer le challenge</Typo>
      <Spacer />
      <Button
        label="Chat : Canaux et messages"
        to={Navigation.getPath("ChatRooms")}
      />
      <Spacer />
      <Button
        label="Ajouter/supprimer des pas à un utilisateur"
        to={Navigation.getPath("ManualSteps")}
      />
      <Spacer />
      <Button
        label="Changer un utilisateur de groupe"
        to={Navigation.getPath("GroupChange")}
      />
      <Spacer />
      <Exports />
      <Spacer />
      <Typo typo="title">Nettoyage</Typo>
      <Spacer />
      <Button
        label="Fonctions de nettoyage"
        to={Navigation.getPath("Cleaning")}
      />
      <Spacer scale={10} />
    </div>
  );
}

function SearchUser() {
  const { uuid } = useChallenge();
  const { platform } = Services.use();

  const navigate = useNavigate();

  const searchField = useMemo(() => {
    return new TextFieldController({
      label: "Adresse email",
      initialValue: null,
      validation: required,
    });
  }, []);

  const onSubmit = useSubmitCallback(
    async () => {
      const email = searchField.validate();
      const params = new URLSearchParams();
      params.set("email", email);
      const users = await platform.get<Array<AccountModel>>(
        `/support/challenges/${uuid}/accounts`,
        { params }
      );
      if (users.length === 0) throw new FrontError("Aucun utilisateur trouvé");
      else if (users.length === 1)
        navigate(
          Navigation.getPath("Participant", {
            params: { participant: users[0].uuid },
          })
        );
    },
    {},
    [uuid]
  );

  return (
    <Fragment>
      <Typo typo="title">Rechercher un utilisateur</Typo>
      <Spacer />
      <Editor2.Form onSubmit={onSubmit} submitLabel="Rechercher">
        {searchField.render()}
      </Editor2.Form>
    </Fragment>
  );
}

type ArchiveBundleColumn = {
  key: string;
  label: string;
  datatype: "string" | "number" | "date";
};

type ArchiveBundle = {
  name: string;
  createdAt: string;
  columns: Array<ArchiveBundleColumn>;
  data: Array<any>;
};

function Exports() {
  const { uuid } = useChallenge();
  const { platform } = Services.use();

  const onFetchAccountsExport = useCallback(() => {
    const params = new URLSearchParams();
    params.set("type", "accounts");
    return platform.post<ArchiveBundle>(
      `/support/challenges/${uuid}/archives`,
      {},
      { params }
    );
  }, []);

  const onFetchAccountGroups = useCallback(() => {
    const params = new URLSearchParams();
    params.set("type", "groups");
    return platform.post<ArchiveBundle>(
      `/support/challenges/${uuid}/archives`,
      {},
      { params }
    );
  }, []);

  return (
    <Fragment>
      <Typo typo="title">Extraire les données</Typo>
      <Export title="Participants" onFetch={onFetchAccountsExport}>
        <Typo typo="body" Tag="span">
          <ul>
            <li>Identifiant technique</li>
            <li>Adresse email, prénom, nom</li>
            <li>Pas, kilomètres et points</li>
            <li>Groupe (entreprise) et sous-groupe (équipe)</li>
            <li>
              Date de validation du compte dans l'app (choix du 1er mot de
              passe)
            </li>
            <li>
              Si l'utilisateur a demandé à supprimer son compte, date de la
              demande
            </li>
          </ul>
        </Typo>
      </Export>
      <Export title="Groupes et sous-groupes" onFetch={onFetchAccountGroups}>
        <Typo typo="body" Tag="span">
          <ul>
            <li>Nom du groupe et du sous-groupe</li>
            <li>Type de groupe (entreprise ou individuel)</li>
            <li>Pas, kilomètres et points, par groupe et par sous-groupe</li>
          </ul>
        </Typo>
      </Export>
    </Fragment>
  );
}

type ExportProps = PropsWithChildren<{
  title: string;
  onFetch: () => Promise<ArchiveBundle>;
}>;

function Export(props: ExportProps) {
  const { title, children, onFetch } = props;

  const containerCss = css({
    border: `1px solid grey`,
    background: "lightgrey",
    padding: 10,
    margin: 10,
  });

  const buildCsv = useSubmitCallback(
    async () => {
      const bundle = await onFetch();
      const fields = bundle.columns.map((c) => c.label);
      const data = bundle.data.map((d) => bundle.columns.map((c) => d[c.key]));
      const csv = Papa.unparse({ fields, data });
      const blob = new Blob([csv], { type: "text/csv" });
      saveAs(blob, `${bundle.name}.csv`);
    },
    {},
    [onFetch]
  );

  const buildXls = useSubmitCallback(
    async () => {
      const bundle = await onFetch();
      const fields = bundle.columns.map((c) => c.label);
      const data = bundle.data.map((d) => {
        const line: any = {};
        bundle.columns.forEach((c) => {
          let value = d[c.key];
          if (c.datatype === "date" && value) value = dayjs(value).toDate();
          line[c.label] = value;
        });
        return line;
      });
      const worksheet = xlsx.utils.json_to_sheet(data, {
        header: fields,
        cellDates: true,
        dateNF: "dd/mm/yyyy hh:mm:ss",
      });
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Données");
      xlsx.writeFile(workbook, bundle.name + ".xlsx");
    },
    {},
    [onFetch]
  );

  // const onExportCsv = useCallback(() => {
  //   if (items.length === 0) return;
  //   if (!renderCsvLine) return;
  //   const fields = renderCsvLine(items[0]).map((i) => i.column);
  //   const data = items.map((item) =>
  //     renderCsvLine(item).map((line) => line.value)
  //   );
  //   const csvStr = Papa.unparse({ fields, data });
  //   const blob = new Blob([csvStr], { type: "text/csv" });
  //   const filename = exportFileName || "Export";
  //   saveAs(blob, `${filename}.csv`);
  // }, [items, renderCsvLine, exportFileName]);

  // const onExportXls = useCallback(() => {
  //   if (items.length === 0) return;
  //   if (!renderCsvLine) return;
  //   const fields = renderCsvLine(items[0]).map((i) => i.column);
  //   const data = items.map((item) =>
  //     Object.fromEntries(
  //       renderCsvLine(item).map((line) => [line.column, line.value])
  //     )
  //   );
  //   const worksheet = xlsx.utils.json_to_sheet(data, { header: fields });
  //   const workbook = xlsx.utils.book_new();
  //   xlsx.utils.book_append_sheet(workbook, worksheet, "Export");
  //   const filename = exportFileName || "Export";
  //   xlsx.writeFile(workbook, filename + ".xlsx");
  // }, [items, renderCsvLine, exportFileName]);

  return (
    <div css={containerCss}>
      <Typo typo="subtitle">{title}</Typo>
      {children}
      <div css={css({ display: "flex" })}>
        <Button label="CSV" onClick={buildCsv} />
        <Spacer />
        <Button label="Excel" onClick={buildXls} />
      </div>
    </div>
  );
}
