/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import React, { useEffect, useMemo, useState } from "react";
import Clickable from "src/components/Clickable";
import Typo from "src/components/Typo";
import Services from "src/utilities/Services";
import Css from "../Css";
import useDeferred from "../Deferred";
import { TypoConfigs } from "../Typos";
import getErrorMessage, { FrontError } from "../getErrorMessage";
import EditorContainer2 from "./EditorContainer2";
import FieldController, { FieldConfig } from "./FieldController";
import Input from "./Input";

type UrlFieldValue = string | null;

type UrlFieldControllerConfig<ValidValue extends UrlFieldValue> = FieldConfig<
  UrlFieldValue,
  ValidValue
>;

export class UrlFieldController<
  ValidValue extends UrlFieldValue
> extends FieldController<UrlFieldValue, ValidValue> {
  constructor(readonly config: UrlFieldControllerConfig<ValidValue>) {
    super(config);
  }

  render(): React.ReactElement {
    return React.createElement(UrlField, { controller: this });
  }
}

export function UrlField<ValidValue extends UrlFieldValue>(props: {
  controller: UrlFieldController<ValidValue>;
}) {
  const { platform } = Services.use();

  const controller = props.controller;
  const { disabled } = controller.config;

  const inputCss = css(Css.inputReset, {
    flexGrow: 1,
  });

  const [typedValue, setTypedValue] = useState(() => controller.getValue());

  const url = useMemo(() => {
    if (typedValue === null) return null;
    return isUrlValid(typedValue) ? typedValue : null;
  }, [typedValue]);

  useEffect(() => {
    controller.setValue(url);
  }, [url]);

  const debounced = useDebounce(url, 1000);

  const check = useMemo(async () => {
    if (debounced === null) return null;
    return platform.get<UrlCheckResult>("/support/check-url", {
      params: { url: debounced },
    });
  }, [debounced]);

  const checking = useDeferred(check, false);

  const containerCss = css({});

  let previewState: "idling" | "loading" | "valid" | "invalid" = "idling";
  let previewUrl: string | undefined = typedValue || undefined;
  let previewTitle: string | null | undefined = undefined;
  let previewIcon: string | null | undefined = undefined;
  let previewError: any | null | undefined = undefined;

  if (typedValue) {
    if (debounced !== typedValue) {
      previewState = "loading";
    } else {
      if (checking.state === "idling" || checking.state === "pending") {
        previewState = "loading";
      } else if (checking.state === "rejected") {
        previewState = "invalid";
        previewError = checking.error;
      } else if (checking.state === "resolved") {
        const infos = checking.value;
        if (!infos) {
          previewState = "idling";
        } else {
          if (infos.valid) {
            previewState = "valid";
            previewTitle = infos.title;
            previewIcon = infos.icon;
          } else {
            if (infos.status) {
              previewState = "invalid";
              previewError = new FrontError(`Lien mort (code ${infos.status})`);
            } else {
              previewState = "invalid";
              previewError = null;
            }
          }
        }
      }
    }
  }

  return (
    <EditorContainer2 controller={controller}>
      <div css={containerCss}>
        <Input
          type={"text"}
          value={typedValue || ""}
          css={inputCss}
          onValueChange={(v) => setTypedValue(v || null)}
          disabled={disabled}
        />
        {typedValue ? (
          <UrlPreview
            state={previewState}
            url={previewUrl}
            title={previewTitle}
            icon={previewIcon}
            error={previewError}
          />
        ) : null}
      </div>
    </EditorContainer2>
  );
}

type UrlPreviewProps = {
  state: "idling" | "loading" | "valid" | "invalid";
  icon?: string | null;
  title?: string | null;
  url?: string | null;
  error?: any;
};

function UrlPreview(props: UrlPreviewProps) {
  const { state, title, url, icon, error } = props;

  let borderColor: string;
  let textColor: string;
  let backgroundColor: string;
  let borderStyle: string;
  let mainText: string;

  if (state === "idling") {
    borderColor = rgba("black", 0.2);
    textColor = rgba("black", 0.2);
    backgroundColor = rgba("black", 0);
    borderStyle = "dotted";
    mainText = "\u00A0";
    mainText = "Vérification de l'URL en cours...";
  } else if (state === "loading") {
    borderColor = rgba("black", 0.3);
    textColor = rgba("black", 0.3);
    backgroundColor = rgba("black", 0.1);
    borderStyle = "solid";
    mainText = "Vérification de l'URL en cours...";
  } else if (state === "valid") {
    borderColor = "darkgreen";
    textColor = "darkgreen";
    backgroundColor = rgba("darkgreen", 0.1);
    borderStyle = "solid";
    mainText = `✓ ${title || "URL vérifiée"}`;
  } else {
    borderColor = "darkred";
    textColor = "darkred";
    backgroundColor = rgba("darkred", 0.1);
    borderStyle = "solid";
    mainText = getErrorMessage(error, "L'URL semble invalide");
  }

  const containerCss = css({
    background: backgroundColor,
    display: "flex",
    borderRadius: 5,
    margin: 5,
    padding: 5,
    border: `1px ${borderStyle} ${borderColor}`,
    color: textColor,
    transition: "all 200ms",
    minWidth: 0,
  });

  const iconCss = css({
    width: `calc(${TypoConfigs["minor"].size} * ${TypoConfigs["minor"].lineHeight} * 2 - 0px)`,
    height: `calc(${TypoConfigs["minor"].size} * ${TypoConfigs["minor"].lineHeight} * 2 - 0px)`,
    backgroundImage: icon ? `url(${icon})` : undefined,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    borderRadius: 10,
    overflow: "hidden",
    margin: 0,
    marginLeft: 5,
  });

  const textsCss = css({
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 0,
  });
  return (
    <Clickable css={containerCss} href={url || ""} target="_blank">
      <div css={textsCss}>
        <Typo typo="minor" singleLine>
          {mainText}
        </Typo>
        <Typo typo="minor" opacity={0.5} singleLine>
          {url || ""}
        </Typo>
      </div>
      {icon ? <div css={iconCss}></div> : null}
    </Clickable>
  );
}

function isUrlValid(input: string) {
  try {
    const _ = new URL(input);
    return true;
  } catch (err) {
    return false;
  }
}

function useDebounce<V>(value: V, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

type UrlCheckResult =
  | { valid: true; status: number; title: string | null; icon: string | null }
  | { valid: false; status: number | null };
