import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Fragment, useCallback, useMemo } from "react";
import Button from "src/components/Button";
import DataTable2 from "src/components/DataTable2";
import DelayedView from "src/components/DelayedView";
import Spacer from "src/components/Spacer";
import ChallengeModel from "src/models/ChallengeModel";
import MissionModel from "src/models/MissionModel";
import {
  useChallenge,
  useChallengeService,
} from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { SwitchFieldController } from "src/utilities/Editor2/SwitchFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import useSubmitCallback from "src/utilities/useSubmitCallback";
dayjs.extend(localizedFormat);

export default function MissionsListPage() {
  const { platform } = Services.use();
  const challenge = useChallenge();
  const challengeService = useChallengeService();

  const load = useMemo(async () => {
    return await platform.get<MissionModel[]>(
      `/support/challenges/${challenge.uuid}/missions`
    );
  }, [challenge]);

  const activationField = useMemo(() => {
    return new SwitchFieldController({
      label: "Activer les missions",
      initialValue: challenge.missionsActivated,
      validation: required,
    });
  }, [challenge]);

  const onSubmit = useSubmitCallback(
    async () => {
      const missionsActivated = activationField.validate();
      const newChallenge = await platform.patch<ChallengeModel>(
        `/support/challenges/${challenge.uuid}`,
        { missionsActivated }
      );
      challengeService.set(newChallenge);
    },
    { successMessage: "Ok !" },
    [activationField]
  );

  return (
    <Fragment>
      <Editor2.Form onSubmit={onSubmit}>
        {activationField.render()}
      </Editor2.Form>
      {challenge.missionsActivated ? (
        <Fragment>
          <Spacer />
          <Button
            label="Ajouter un mission"
            center
            to={Navigation.getPath("MissionCreation")}
          />
          <DelayedView promise={load}>
            {(missions) => <MissionTable missions={missions} />}
          </DelayedView>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

interface MissionTableProps {
  missions: MissionModel[];
}

function MissionTable(props: MissionTableProps) {
  const { missions } = props;
  const challengeUuid = useChallenge().uuid;

  const onEdit = useCallback((uuid: string) => {
    // void router.push(`/challenge/${challengeUuid}/missions/${uuid}`);
  }, []);

  const onResults = useCallback((uuid: string) => {
    // void router.push(`/challenge/${challengeUuid}/missions/${uuid}/results`);
  }, []);

  const columns = DataTable2.useColumns<MissionModel>([
    DataTable2.columns.perLocaleStrings({
      id: "name",
      accessor: (r) => r.name,
      header: "Libellé",
    }),
    DataTable2.columns.date({
      id: "startAt",
      accessor: (r) => new Date(r.startAt),
      header: "Début",
    }),
    DataTable2.columns.date({
      id: "endAt",
      accessor: (r) => new Date(r.endAt),
      header: "Fin",
    }),
    {
      id: "manage",
      accessor: (r) => r.uuid,
      render: (v) => (
        <Button
          label="Gérer"
          to={Navigation.getPath("MissionEdition", {
            params: { missionUuid: v },
          })}
        />
      ),
    },
    {
      id: "results",
      accessor: (r) => r.uuid,
      render: (v) => (
        <Button
          label="Résultats"
          to={Navigation.getPath("MissionResults", {
            params: { missionUuid: v },
          })}
        />
      ),
    },
  ]);

  return <DataTable2 data={missions} columns={columns} />;
}
