import Routing, { Route } from "./Routing";

const Home: Route = {
  pattern: "/",
  parent: null,
};

const Themes: Route = {
  pattern: "/themes",
  parent: Home,
};

const ThemeCreation: Route = {
  pattern: "new",
  parent: Themes,
};

const ThemeDisplay: Route = {
  pattern: ":theme",
  parent: Themes,
};

const Units: Route = {
  pattern: "/units",
  parent: Home,
};

const UnitCreation: Route = {
  pattern: "new",
  parent: Units,
};

const UnitDisplay: Route = {
  pattern: ":unit",
  parent: Units,
};

const Challenges: Route = {
  pattern: "/challenges",
  parent: Home,
};

const ChallengeHome: Route = {
  pattern: "/:challengeUuid",
  parent: Challenges,
};

// Informations

const Informations: Route = {
  pattern: "informations",
  parent: ChallengeHome,
};

const ChallengeDates: Route = {
  pattern: "dates",
  parent: ChallengeHome,
};

// Signin

const Signin: Route = {
  pattern: "signin",
  parent: ChallengeHome,
};

// Partners

const Partners: Route = {
  pattern: "partners",
  parent: ChallengeHome,
};

// Unités

const ChallengeMetrics: Route = {
  pattern: "metrics",
  parent: ChallengeHome,
};

const ChallengeMetricsCreation: Route = {
  pattern: "new",
  parent: ChallengeMetrics,
};

const ChallengeMetricsEdition: Route = {
  pattern: ":metric",
  parent: ChallengeMetrics,
};

// Participants

const Participants: Route = {
  pattern: "participants",
  parent: ChallengeHome,
};

const Participant: Route = {
  pattern: ":participant",
  parent: Participants,
};

const ParticipantSteps: Route = {
  pattern: "steps",
  parent: Participant,
};

const ParticipantSensors: Route = {
  pattern: "sensors",
  parent: Participant,
};

//

// Sensors

const Sensors: Route = {
  pattern: "sensors",
  parent: ChallengeHome,
};

// Conferences

const ConferencesHome: Route = {
  pattern: "conferences",
  parent: ChallengeHome,
};

const ConferencesCreation: Route = {
  pattern: "new",
  parent: ConferencesHome,
};

const ConferencesEdition: Route = {
  pattern: ":conferenceUuid",
  parent: ConferencesHome,
};

// Presents

const Presents: Route = {
  pattern: "presents",
  parent: ChallengeHome,
};

// Missions

const MissionsHome: Route = {
  pattern: "missions",
  parent: ChallengeHome,
};

const MissionCreation: Route = {
  pattern: "new",
  parent: MissionsHome,
};

const MissionEdition: Route = {
  pattern: ":missionUuid",
  parent: MissionsHome,
};

const MissionResults: Route = {
  pattern: ":missionUuid/results",
  parent: MissionsHome,
};

// Quizzes

const QuizzesHome: Route = {
  pattern: "quizzes",
  parent: ChallengeHome,
};

const QuizCreation: Route = {
  pattern: "new",
  parent: QuizzesHome,
};

const QuizEdition: Route = {
  pattern: ":quizUuid",
  parent: QuizzesHome,
};

// Notifications

const NotificationsHome: Route = {
  pattern: "notifications",
  parent: ChallengeHome,
};

const NotificationCreation: Route = {
  pattern: "new",
  parent: NotificationsHome,
};

const NotificationEdition: Route = {
  pattern: ":notificationUuid",
  parent: NotificationsHome,
};

// ManualSteps

const ManualSteps: Route = {
  pattern: "manual-steps",
  parent: ChallengeHome,
};

// GroupeChange
const GroupChange: Route = {
  pattern: "group-change",
  parent: ChallengeHome,
};

// Groups

const Groups: Route = {
  pattern: "groups",
  parent: ChallengeHome,
};

const GroupCreation: Route = {
  pattern: "new",
  parent: Groups,
};

const GroupEdition: Route = {
  pattern: ":group",
  parent: Groups,
};

const SubGroupCreation: Route = {
  pattern: "subgroups/new",
  parent: GroupEdition,
};

const SubGroupEdition: Route = {
  pattern: "subgroups/:subgroup",
  parent: GroupEdition,
};

const ChatRooms: Route = {
  pattern: "chat",
  parent: ChallengeHome,
};

const ChatRoom: Route = {
  pattern: ":room",
  parent: ChatRooms,
};

const ChatParticipation: Route = {
  pattern: "participations/:participation",
  parent: ChatRoom,
};

const Policies: Route = {
  pattern: "policies",
  parent: ChallengeHome,
};

const PolicyCreation: Route = {
  pattern: "new",
  parent: Policies,
};

const PolicyEdition: Route = {
  pattern: ":policy",
  parent: Policies,
};

const Codes: Route = {
  pattern: "codes",
  parent: ChallengeHome,
};

const ChallengeCreation: Route = {
  pattern: "new",
  parent: Challenges,
};

const Rankings: Route = {
  pattern: "rankings",
  parent: ChallengeHome,
};

const Cleaning: Route = {
  pattern: "cleaning",
  parent: ChallengeHome,
};

const routes = {
  Home,
  Challenges,
  ChallengeHome,
  Informations,
  Signin,
  Partners,
  Participants,
  Participant,
  ParticipantSteps,
  Sensors,
  ConferencesHome,
  ConferencesCreation,
  ConferencesEdition,
  MissionsHome,
  MissionCreation,
  MissionEdition,
  MissionResults,
  QuizzesHome,
  QuizCreation,
  QuizEdition,
  NotificationsHome,
  NotificationCreation,
  NotificationEdition,
  Presents,
  ManualSteps,
  Groups,
  GroupCreation,
  GroupEdition,
  SubGroupCreation,
  SubGroupEdition,
  GroupChange,
  ChatRooms,
  ChatRoom,
  ChatParticipation,
  ChallengeDates,
  Policies,
  PolicyCreation,
  PolicyEdition,
  Themes,
  ThemeCreation,
  ThemeDisplay,
  Units,
  UnitCreation,
  UnitDisplay,
  ChallengeMetrics,
  ChallengeMetricsCreation,
  ChallengeMetricsEdition,
  Codes,
  ChallengeCreation,
  Rankings,
  Cleaning,
  ParticipantSensors,
};

const Navigation = new Routing(routes);

export default Navigation;
