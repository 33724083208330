import { useMemo } from "react";
import Editor2 from "src/utilities/Editor2";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Environment from "src/utilities/Environment";
import Services from "src/utilities/Services";
import useSubmitCallback from "src/utilities/useSubmitCallback";
import { TokenPair } from "src/utilities/WhoAmI";

export default function LoginScreen() {
  const { whoami, platform } = Services.use();

  const emailField = useMemo(
    () =>
      new TextFieldController({
        label: "Adresse email",
        initialValue: Environment.searchString("REACT_APP_DEFAULT_EMAIL", null),
        validation: required,
      }),
    []
  );

  const passwordField = useMemo(
    () =>
      new TextFieldController({
        label: "Mot de passe",
        initialValue: Environment.searchString(
          "REACT_APP_DEFAULT_PASSWORD",
          null
        ),
        sensitive: true,
        validation: required,
      }),
    []
  );

  const onSubmit = useSubmitCallback(
    async () => {
      const email = emailField.validate();
      const password = passwordField.validate();
      const tokens = await platform.post<TokenPair>("/support/auth/signin", {
        email: email,
        password: password,
      });
      whoami.setTokens(tokens);
    },
    { successMessage: "Connecté :)" },
    []
  );

  return (
    <Editor2.Form onSubmit={onSubmit}>
      {emailField.render()}
      {passwordField.render()}
    </Editor2.Form>
  );
}
