/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import axios, { InternalAxiosRequestConfig, isAxiosError } from "axios";
import { Fragment, useMemo } from "react";
import { ToastContainer } from "react-toastify";
import Services, { ServiceList } from "src/utilities/Services";
import AppRouter from "./AppRouter";
import DelayedView from "./components/DelayedView";
import Typo from "./components/Typo";
import LoginScreen from "./screens/LoginScreen";
import DataFetcher from "./utilities/DataFetcher";
import Environment from "./utilities/Environment";
import RootClick from "./utilities/RootClick";
import Toasts from "./utilities/Toasts";
import WhoAmI from "./utilities/WhoAmI";

export default function Root() {
  RootClick.use();

  const services = useMemo<Promise<ServiceList>>(async () => {
    const whoami = new WhoAmI();
    whoami.init();

    const addAuthorizationHeader = (
      config: InternalAxiosRequestConfig<any>
    ) => {
      const tokens = whoami.read();
      if (tokens)
        config.headers.set("Authorization", `Bearer ${tokens.accessToken}`);
      return config;
    };

    const catchUnhauthorized = (error: any) => {
      if (!isAxiosError(error)) throw error;
      if (!error.response) throw error;
      if (error.response.status !== 401) throw error;
      Toasts.error("Par mesure de sécurité, veuillez vous reconnecter");
      whoami.setTokens(null);
    };

    const platformAxios = axios.create({
      baseURL: Environment.ensureString("REACT_APP_PLATFORM_URL"),
    });
    platformAxios.interceptors.request.use(addAuthorizationHeader);
    platformAxios.interceptors.response.use((c) => c, catchUnhauthorized);
    const platform = new DataFetcher(platformAxios);

    const trackerAxios = axios.create({
      baseURL: Environment.ensureString("REACT_APP_TRACKER_URL"),
    });
    trackerAxios.interceptors.request.use(addAuthorizationHeader);
    trackerAxios.interceptors.response.use((c) => c, catchUnhauthorized);
    const tracker = new DataFetcher(trackerAxios);

    const chatAxios = axios.create({
      baseURL: Environment.ensureString("REACT_APP_CHAT_URL"),
    });
    chatAxios.interceptors.request.use(addAuthorizationHeader);
    chatAxios.interceptors.response.use((c) => c, catchUnhauthorized);
    const chat = new DataFetcher(chatAxios);

    return { whoami, platform, tracker, chat };
  }, []);

  return (
    <Fragment>
      <DelayedView promise={services}>
        {(services) => (
          <Services.Provider services={services}>
            <App />
          </Services.Provider>
        )}
      </DelayedView>
      <ToastContainer />
      <div
        css={css({
          position: "fixed",
          bottom: 0,
          left: 0,
          padding: 10,
          color: "white",
          background: Environment.ensureString("REACT_APP_ENVIRONMENT_COLOR"),
          borderTopRightRadius: 10,
        })}
      >
        <Typo typo="button">
          {Environment.ensureString("REACT_APP_ENVIRONMENT")}
        </Typo>
      </div>
    </Fragment>
  );
}

function App() {
  const { whoami } = Services.use();

  const loggedIn = whoami.useIsLoggedIn();
  if (!loggedIn) {
    return <LoginScreen />;
  } else {
    return <AppRouter />;
  }
}
