/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode, useCallback, useEffect, useState } from "react";
import Button from "src/components/Button";
import Css from "../Css";
import { FrontError } from "../getErrorMessage";
import useBooleanState from "../useBooleanState";
import EditorContainer2 from "./EditorContainer2";
import FieldController, { FieldConfig } from "./FieldController";

type SecretValue = string | true | null;

type SecretFieldControllerConfig<ValidValue extends SecretValue> = FieldConfig<
  SecretValue,
  ValidValue
> & {};

export default class SecretFieldController<
  ValidValue extends SecretValue
> extends FieldController<SecretValue, ValidValue> {
  public revealed: boolean = false;

  constructor(public config: SecretFieldControllerConfig<ValidValue>) {
    super(config);
  }

  validate(): ValidValue {
    if (this.revealed)
      throw new FrontError(`Validez le champ ${this.config.label}`);
    return super.validate();
  }

  render() {
    return <SecretField controller={this} />;
  }
}

type SecretFieldProps<ValidValue extends SecretValue> = {
  controller: SecretFieldController<ValidValue>;
};

function SecretField<ValidValue extends SecretValue>(
  props: SecretFieldProps<ValidValue>
) {
  const { controller } = props;

  const [revealed, setRevealed] = useBooleanState(controller.revealed);
  const [value, setValue] = useState<SecretValue>(() => controller.getValue());

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value === "" ? null : value);
  }, []);

  useEffect(() => {
    controller.setValue(value);
  }, [value]);

  useEffect(() => {
    controller.revealed = revealed;
  }, [revealed]);

  const contentCss = css({
    display: "flex",
  });

  const inputCss = css(Css.inputReset, {
    flexGrow: 1,
  });

  let fieldValue: string;
  if (revealed) {
    if (value === true) fieldValue = "";
    else if (value === null) fieldValue = "";
    else fieldValue = value;
  } else {
    if (value === true) fieldValue = "****************";
    else if (value === null) fieldValue = "";
    else fieldValue = "****************";
  }

  let buttonNode: ReactNode;
  if (revealed) {
    buttonNode = <Button label="Valider" onClick={setRevealed.toFalse} />;
  } else {
    buttonNode = <Button label="Modifier" onClick={setRevealed.toTrue} />;
  }

  return (
    <EditorContainer2 controller={controller}>
      <div css={contentCss}>
        <input
          type="text"
          value={fieldValue}
          css={inputCss}
          onChange={onChange}
          readOnly={!revealed}
        />
        {buttonNode}
      </div>
    </EditorContainer2>
  );
}
