/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

interface SpacerProps {
  grow?: boolean
  direction?: 'width' | 'height' | 'both'
  size?: number
  scale?: number
}

export default function Spacer (props: SpacerProps) {
  const { size = 12, scale = 1, grow = false, direction = 'both' } = props

  const S = size * scale

  const containerCss = css`
    width: ${direction !== 'height' ? `${S}px` : '0px'};
    height: ${direction !== 'width' ? `${S}px` : '0px'};
    flex-shrink: 0;
    flex-grow: ${grow ? 1 : 0};
  `

  return <div css={containerCss} />
}
