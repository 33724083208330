/** @jsxImportSource @emotion/react */
import { Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import { ClickableProps } from "src/components/Clickable";
import CodesList from "src/components/CodesList";
import DelayedView from "src/components/DelayedView";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import GroupEditor from "src/editors/GroupEditor";
import AccountModel from "src/models/AccountModel";
import CodeModel from "src/models/CodeModel";
import GroupModel from "src/models/GroupModel";
import RoomModel from "src/models/RoomModel";
import SubGroupModel from "src/models/SubGroupModel";
import { useChallenge } from "src/utilities/ChallengeService";
import List from "src/utilities/List";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useNow from "src/utilities/useNow";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function GroupEdition() {
  const challenge = useChallenge();
  const id = Navigation.useParam("group");

  const { platform, chat } = Services.use();
  const navigate = useNavigate();

  const group = useMemo(() => {
    return platform.get<GroupModel>(
      `/support/challenges/${challenge.uuid}/groups/${id}`
    );
  }, [id]);

  const subgroups = useMemo(() => {
    return platform.get<Array<SubGroupModel>>(
      `/support/challenges/${challenge.uuid}/groups/${id}/subgroups`
    );
  }, []);

  const accounts = useMemo(() => {
    return platform.get<Array<AccountModel>>(
      `/support/challenges/${challenge.uuid}/accounts`,
      { params: { group: id } }
    );
  }, [id]);

  const [codesLoadDate, loadCodes] = useNow();

  const codes = useMemo(() => {
    return platform.get<Array<CodeModel>>(`/support/codes`, {
      params: { group: id },
    });
  }, [codesLoadDate]);

  const onSubmit = useSubmitCallback(
    async (updates: Updates<GroupModel>) => {
      await platform.patch<GroupModel>(
        `/support/challenges/${challenge.uuid}/groups/${id}`,
        updates
      );
      await chat.patch<RoomModel>(`/support/rooms/${id}`, {
        name: updates.name,
      });
    },
    { successMessage: "Modifié" },
    []
  );

  const onDelete = useSubmitCallback(
    async () => {
      await platform.delete(
        `/support/challenges/${challenge.uuid}/groups/${id}`
      );
      navigate(Navigation.getPath("Groups"));
    },
    {
      successMessage: "Supprimé !",
      confirmationMessage: "Confirmer la suppression",
    },
    []
  );

  return (
    <Fragment>
      <Typo typo="title">Informations sur le groupe</Typo>
      <DelayedView promise={group}>
        {(group) => (
          <Fragment>
            <GroupEditor
              group={group}
              onSubmit={onSubmit}
              onDelete={onDelete}
            />
          </Fragment>
        )}
      </DelayedView>
      <Typo typo="title">Codes</Typo>
      <DelayedView promise={codes}>
        {(codes) => (
          <CodesList
            codes={codes}
            onReload={loadCodes}
            creationTarget={{
              challenge: challenge.uuid,
              group: id,
              subGroup: null,
            }}
          />
        )}
      </DelayedView>
      <Spacer />
      <Typo typo="title">Equipes</Typo>
      <DelayedView promise={subgroups}>
        {(subgroups) => (
          <Fragment>
            <Button
              center
              label="Nouvelle équipe"
              to={Navigation.getPath("SubGroupCreation")}
            />
            <List
              items={subgroups}
              renderItem={(g) => (
                <SubGroupItem
                  subgroup={g}
                  to={Navigation.getPath("SubGroupEdition", {
                    params: { subgroup: g.uuid },
                  })}
                />
              )}
              keyExtractor={(g) => g.uuid}
            />
          </Fragment>
        )}
      </DelayedView>
      <Spacer />
      <Typo typo="title">Membres</Typo>
      <DelayedView promise={accounts}>
        {(accounts) => (
          <Fragment>
            <List
              items={accounts}
              renderItem={(g) => (
                <AccountItem
                  account={g}
                  to={Navigation.getPath("Participant", {
                    params: { participant: g.uuid },
                  })}
                />
              )}
              keyExtractor={(g) => g.uuid}
            />
          </Fragment>
        )}
      </DelayedView>
    </Fragment>
  );
}

type SubGroupItemProps = {
  subgroup: SubGroupModel;
} & ClickableProps;

function SubGroupItem(props: SubGroupItemProps) {
  const { subgroup, ...clickable } = props;
  return <List.Item label={props.subgroup.name} {...clickable} />;
}

type AccountItemProps = {
  account: AccountModel;
} & ClickableProps;

export function AccountItem(props: AccountItemProps) {
  const { account, ...clickable } = props;
  let help = `${account.email}`;
  if (account.deletedAt) help += ` - Compte désactivé`;
  if (account.groupChangePending)
    help += ` - Changement de groupe/équipe en attente de validation sur l'app`;
  return (
    <List.Item
      label={`${account.firstName} ${account.lastName}`}
      helper={help}
      inactive={!!account.deletedAt}
      {...clickable}
    />
  );
}
