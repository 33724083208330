/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";

export default function Divider() {
  const dividerCss = css({
    margin: 0,
    padding: 0,
    border: 0,
    height: 1,
    background: rgba("black", 0.1),
  });

  return <hr css={dividerCss} />;
}
