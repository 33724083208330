/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useMemo, useState } from "react";
import { ChromePicker, ColorResult } from "react-color";
import Button from "src/components/Button";
import Css from "../Css";
import RootClick from "../RootClick";
import EditorContainer2 from "./EditorContainer2";
import FieldController, { FieldConfig } from "./FieldController";
import Input from "./Input";
import Picker, { usePicker } from "./Picker";

type ColorFieldValue = string | null;

type ColorFieldConfig<ValidValue extends ColorFieldValue> = FieldConfig<
  ColorFieldValue,
  ValidValue
>;

export class ColorFieldController<
  ValidValue extends ColorFieldValue
> extends FieldController<ColorFieldValue, ValidValue> {
  constructor(readonly config: ColorFieldConfig<ValidValue>) {
    super(config);
  }

  render(): React.ReactElement {
    return <ColorField controller={this} />;
  }
}

type ColorFieldProps<ValidValue extends ColorFieldValue> = {
  controller: ColorFieldController<ValidValue>;
};

function ColorField<ValidValue extends ColorFieldValue>(
  props: ColorFieldProps<ValidValue>
) {
  const { controller } = props;
  const disabled = controller.isDisabled();

  const [value, setValue] = useState<string | null>(() =>
    controller.getValue()
  );
  controller.useValueModifier(value);

  const displayedValue = useMemo(() => {
    return value || "";
  }, [value]);

  const picker = usePicker();

  const onFocus = useCallback(() => {
    if (disabled) return;
    RootClick.trigger();
    picker.setOpened.toTrue();
  }, [disabled]);

  const onChangeComplete = useCallback(
    (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(color.hex);
    },
    []
  );

  const contentCss = css({
    display: "flex",
  });

  const inputCss = css(Css.inputReset, {
    flexGrow: 1,
  });

  const resultCss = css(Css.inputReset, {
    width: 30,
    backgroundColor: value || "transparent",
  });

  const [color, setColor] = useState<string>(value || "#000000");

  return (
    <RootClick.Boundary>
      <EditorContainer2 controller={controller}>
        <div css={contentCss}>
          {value ? <div css={resultCss} /> : null}
          <Input
            type="text"
            readOnly
            value={displayedValue}
            css={inputCss}
            onFocus={onFocus}
            disabled={disabled}
          />
          {value !== null && !disabled ? (
            <Button label="-" onClick={() => setValue(null)} />
          ) : null}
          <Picker {...picker}>
            <ChromePicker
              color={color}
              onChange={(c) => setColor(c.hex)}
              onChangeComplete={onChangeComplete}
              disableAlpha
              styles={{
                default: { picker: { width: "100%", boxShadow: "none" } },
              }}
            />
            <Button
              onClick={() => {
                setValue(null);
                picker.setOpened.toFalse();
              }}
              label="Vider"
            />
            <Button onClick={picker.setOpened.toFalse} label="Valider" />
          </Picker>
        </div>
      </EditorContainer2>
    </RootClick.Boundary>
  );
}
