/** @jsxImportSource @emotion/react */

import { Fragment, useMemo } from "react";
import DelayedView from "src/components/DelayedView";
import ParticipationEditor, {
  ParticipationModelUpdates,
} from "src/editors/ParticipationEditor";
import ParticipationModel from "src/models/ParticipationModel";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function ChatParticipation() {
  const room = Navigation.useParam("room");
  const participationId = Navigation.useParam("participation");
  const { chat } = Services.use();

  const participation = useMemo(async () => {
    const participations = await chat.get<ParticipationModel>(
      `/support/rooms/${room}/participations/${participationId}`
    );
    return participations;
  }, [participationId]);

  const onSubmit = useSubmitCallback(
    async (updates: ParticipationModelUpdates) => {
      const participations = await chat.patch<ParticipationModel>(
        `/support/rooms/${room}/participations/${participationId}`,
        updates
      );
    },
    { successMessage: "Ok" },
    [participationId]
  );

  return (
    <Fragment>
      <DelayedView promise={participation}>
        {(participation) => (
          <ParticipationEditor
            participation={participation}
            onSubmit={onSubmit}
          />
        )}
      </DelayedView>
    </Fragment>
  );
}
