import { Infer, enums, nullable, object, string } from "superstruct";

export enum SensorType {
  DEVICE = "DEVICE",
  GARMIN = "GARMIN",
  SUUNTO = "SUUNTO",
  POLAR = "POLAR",
  STRAVA = "STRAVA",
  FITBIT = "FITBIT",
}

export const SensorSupporModelSchema = object({
  id: string(),
  accountUuid: string(),
  type: enums(Object.values(SensorType)),
  reference: nullable(string()),
  from: string(),
  createdAt: string(),
});

type SensorSupportModel = Infer<typeof SensorSupporModelSchema>;

export default SensorSupportModel;
