/** @jsxImportSource @emotion/react */

import { sortBy } from "lodash";
import { useMemo } from "react";
import GroupModel from "src/models/GroupModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { SelectFieldController } from "src/utilities/Editor2/SelectFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Services from "src/utilities/Services";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function GroupChange() {
  const { platform } = Services.use();
  const challenge = useChallenge();

  const groups = useMemo(async () => {
    const results = await platform.get<Array<GroupModel>>(
      `/support/challenges/${challenge.uuid}/groups`
    );
    return sortBy(results, (r) => r.name);
  }, []);

  const emailField = useMemo(
    () =>
      new TextFieldController({
        label: "Adresse email de l'utilisateur",
        initialValue: null,
        validation: required,
      }),
    []
  );

  const groupField = useMemo(
    () =>
      new SelectFieldController({
        label: "Nouveau groupe",
        initialValue: null,
        keyExtractor: (g) => g.uuid,
        options: groups,
        textExtractor: (g) => g.name,
        validation: required,
      }),
    []
  );

  const group = groupField.useValue();

  const subgroupField = useMemo(
    () =>
      new SelectFieldController({
        label: "Nouveau sous-groupe",
        initialValue: null,
        keyExtractor: (g) => g.uuid,
        hidden: !group,
        options: group
          ? platform.get<Array<GroupModel>>(
              `/support/challenges/${challenge.uuid}/groups/${group}/subgroups`
            )
          : [],
        textExtractor: (g) => g.name,
      }),
    [group]
  );

  const onSubmit = useSubmitCallback(
    () => {
      const email = emailField.validate();
      const groupUuid = groupField.validate();
      const subgroupUuid = subgroupField.validate();
      return platform.post<Array<GroupModel>>(`/support/account/change-team`, {
        email,
        challengeUuid: challenge.uuid,
        newGroupUuid: groupUuid,
        newSubGroupUuid: subgroupUuid,
      });
    },
    {
      successMessage:
        "Changement de groupe initié. L'utilisateur doit valider dans l'app (Réglages > Informations personnelles)",
    },
    [challenge.uuid, subgroupField]
  );

  return (
    <Editor2.Form onSubmit={onSubmit}>
      {emailField.render()}
      {groupField.render()}
      {subgroupField.render()}
    </Editor2.Form>
  );
}
