/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import dayjs from "dayjs";
import { rgba } from "polished";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Button from "src/components/Button";
import Clickable from "src/components/Clickable";
import DelayedView from "src/components/DelayedView";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import MessageModel from "src/models/MessageModel";
import ParticipationModel from "src/models/ParticipationModel";
import List from "src/utilities/List";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import useBooleanState from "src/utilities/useBooleanState";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function ChatRoom() {
  const room = Navigation.useParam("room");
  const { chat } = Services.use();

  const [messages, setMessages] = useState<Array<MessageModel> | null>(null);
  const [page, setPage] = useState<number>(0);
  const [showLoadMore, setShowLoadMore] = useBooleanState(true);

  const loadNextMessages = useCallback(async () => {
    const params = new URLSearchParams();
    const pageSize = 50;
    params.set("limit", pageSize.toString());
    params.set("offset", (page * pageSize).toString());
    const messages = await chat.get<Array<MessageModel>>(
      `/support/rooms/${room}/messages`,
      { params }
    );
    setShowLoadMore.to(messages.length === pageSize);
    setMessages((m) => (m ? [...m, ...messages] : [...messages]));
    setPage((p) => p + 1);
  }, [page]);

  const deleteMessage = useSubmitCallback(
    async (message: string) => {
      await chat.delete<Array<MessageModel>>(`/support/messages/${message}`);
      setMessages(null);
      setPage(0);
    },
    { confirmationMessage: "Supprimer le message ?" },
    []
  );

  useEffect(() => {
    if (messages === null) loadNextMessages();
  }, [messages]);

  const participations = useMemo(async () => {
    const participations = await chat.get<Array<ParticipationModel>>(
      `/support/rooms/${room}/participations`
    );
    return participations;
  }, []);

  const messageCss = css({
    backgroundColor: rgba("black", 0.1),
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
  });

  return (
    <Fragment>
      <Typo typo="title">Messages</Typo>
      <Spacer />
      {messages
        ? messages.map((m) => (
            <div key={m.id}>
              <Typo typo="minor">
                {`${m.userName}, le ${dayjs(m.sentAt).format("LLLL")}`}
              </Typo>
              <div css={messageCss}>
                <Typo>{m.content}</Typo>
                <Clickable onClick={() => deleteMessage(m.id)}>
                  <Typo typo="minor">Supprimer ce message</Typo>
                </Clickable>
              </div>
            </div>
          ))
        : null}
      {showLoadMore ? (
        <Button
          onClick={loadNextMessages}
          label="Afficher les messages plus anciens"
        />
      ) : null}
      <Spacer scale={3} />
      <Typo typo="title">Participants</Typo>
      <Spacer />
      <DelayedView promise={participations}>
        {(participations) => (
          <List
            items={participations}
            keyExtractor={(u) => u.id}
            renderItem={(u) => (
              <List.Item
                label={`${u.userFirstName} ${u.userLastName}`}
                to={Navigation.getPath("ChatParticipation", {
                  params: { participation: u.id },
                })}
              />
            )}
          />
        )}
      </DelayedView>
    </Fragment>
  );

  return <Fragment />;
}
