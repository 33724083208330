/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { TypoConfigs } from "src/utilities/Typos";
import Clickable, { ClickableProps, isDisabled } from "./Clickable";
import Typo from "./Typo";

type ButtonProps = {
  label: string;
  center?: boolean;
  dangerous?: boolean;
  loading?: boolean;
} & ClickableProps;

export default function Button(props: ButtonProps) {
  const { label, center, loading, dangerous, ...clickableProps } = props;

  const disabled = isDisabled(clickableProps);

  const color = dangerous ? "darkred" : "darkblue";

  const c = TypoConfigs["button"];
  const containerCss = css({
    border: `1px solid rgba(0, 0, 0, 0.1)`,
    paddingBlock: `calc(${c.size} * ${c.lineHeight} * 0.1)`,
    paddingInline: `calc(${c.size} * ${c.lineHeight} * 0.3)`,
    display: "inline-block",
    backgroundColor: disabled ? "grey" : color,
    color: "white",
    borderRadius: 4,
    position: "relative",
  });

  const loadingSpotCss = css({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    background: color,
  });

  const centererCss = css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  let output = (
    <Clickable {...clickableProps} css={containerCss}>
      <Typo Tag="span" typo="button" singleLine>
        {label}
      </Typo>
      {loading ? (
        <div css={loadingSpotCss}>
          <Typo Tag="span" typo="button" singleLine>
            ...
          </Typo>
        </div>
      ) : null}
    </Clickable>
  );

  if (center === true) {
    output = <div css={centererCss}>{output}</div>;
  }

  return output;
}
