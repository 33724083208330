import { Fragment, useMemo } from "react";
import Button from "src/components/Button";
import DataTable2 from "src/components/DataTable2";
import DelayedView from "src/components/DelayedView";
import MassNotificationModel from "src/models/MassNotificationModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";

export default function NotificationsHome() {
  const challengeUuid = useChallenge().uuid;
  const { platform } = Services.use();

  const load = useMemo(async () => {
    return platform.get<Array<MassNotificationModel>>(
      `/support/challenges/${challengeUuid}/mass-notifications`
    );
  }, [challengeUuid]);

  const columns = DataTable2.useColumns<MassNotificationModel>([
    DataTable2.columns.perLocaleStrings({
      id: "title",
      accessor: (n) => n.title,
      header: "Titre de la notification",
    }),
    DataTable2.columns.boolean({
      id: "sent",
      accessor: (n) => n.sent,
      header: "Envoyée",
    }),
    {
      id: "link",
      header: "Gérer",
      accessor: (c) => c,
      render: (c: MassNotificationModel) => (
        <Button
          to={Navigation.getPath("NotificationEdition", {
            params: { notificationUuid: c.uuid },
          })}
          label="Gérer"
        />
      ),
    },
  ]);

  return (
    <DelayedView promise={load}>
      {(notifications) => {
        return (
          <Fragment>
            <Button
              label="Créer une notification"
              center
              to={Navigation.getPath("NotificationCreation")}
            />
            <DataTable2 data={notifications} columns={columns}></DataTable2>
          </Fragment>
        );
      }}
    </DelayedView>
  );
}
