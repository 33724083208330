export default interface LinkedAccountModel {
  uuid: string;
  userId: string;
  service: ActivitySourceType;
}

export enum ActivitySourceType {
  GARMIN = "GARMIN",
  STRAVA = "STRAVA",
  FITBIT = "FITBIT",
  SUUNTO = "SUUNTO",
  POLAR = "POLAR",
}
