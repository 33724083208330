/** @jsxImportSource @emotion/react */

import { Fragment, useMemo } from "react";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import { Unit } from "src/models/BaseMetric";
import MetricIcon from "src/models/MetricIcon";
import MetricModel from "src/models/MetricModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { I18nFieldController } from "src/utilities/Editor2/I18nFieldController";
import { NumberFieldController } from "src/utilities/Editor2/NumberFieldController";
import { SelectFieldController } from "src/utilities/Editor2/SelectFieldController";
import { SwitchFieldController } from "src/utilities/Editor2/SwitchFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Extractors from "src/utilities/Extractors";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

type MetricEditorProps = {
  metric: MetricModel | null;
  onSubmit?: (updates: Updates<MetricModel>) => any;
  onDelete?: () => any;
};

export default function MetricEditor(props: MetricEditorProps) {
  const { metric, onSubmit, onDelete } = props;

  const challenge = useChallenge();

  const labelField = useMemo(() => {
    return new TextFieldController({
      label: "Libellé",
      initialValue: metric ? metric.label : null,
      validation: required,
    });
  }, []);

  const designationField = useMemo(() => {
    return new I18nFieldController({
      locales: challenge.locales,
      getController: (l) => {
        return new TextFieldController({
          label: "Désignation",
          initialValue: metric ? metric.designation[l] : null,
          validation: required,
          help: "Doit être au pluriel, commencer par une majuscule et doit être explicite.",
          placeholder: "Ex : Points, Euros, Calories",
        });
      },
    });
  }, []);

  const symbolField = useMemo(() => {
    return new I18nFieldController({
      locales: challenge.locales,
      getController: (l) => {
        return new TextFieldController({
          label: "Unité",
          initialValue: metric ? metric.symbol[l] : null,
          validation: required,
          help: "Est toujours utilisé associé à un nombre : 3.42 pt ou 7353 kcal. Doit être le plus court possible, doit fonctionner au singulier comme au pluriel, et peut être induit (km induit kilomètre, par exemple)",
          placeholder: "Ex : km, kcal, €, pas",
        });
      },
    });
  }, []);

  const unitField = useMemo(() => {
    const options = [
      { key: Unit.STEPS_RAW, label: "Pas" },
      { key: Unit.KILOMETERS, label: "Kilomètres" },
      { key: Unit.POINTS, label: "Points" },
    ];
    return new SelectFieldController({
      label: "Unité de référence",
      initialValue: metric ? metric.unit : null,
      options: options,
      keyExtractor: Extractors.key,
      textExtractor: Extractors.label,
      validation: required,
      help: "Determine sur quelle unité (pas, km ou points) se base cette métrique",
    });
  }, []);

  const ratioField = useMemo(() => {
    return new NumberFieldController({
      label: "Coefficient multiplicateur",
      initialValue: metric ? metric.coefficient : null,
      validation: required,
      help: "Ce coefficient est appliqué sur l'unité.",
    });
  }, []);

  const iconField = useMemo(() => {
    const options = [
      { key: MetricIcon.STEP, label: "Chaussure" },
      { key: MetricIcon.POINT, label: "Point" },
      { key: MetricIcon.KM, label: "Borne kilométrique" },
      { key: MetricIcon.MILE, label: "Mile ?" },
      { key: MetricIcon.EURO, label: "Pièce de monnaie" },
      { key: MetricIcon.BURNED, label: "Flamme" },
      { key: MetricIcon.JONQUILLE, label: "Jonquille" },
    ];
    return new SelectFieldController({
      label: "Icone",
      initialValue: metric ? metric.pictoName : null,
      options: options,
      keyExtractor: Extractors.key,
      textExtractor: Extractors.label,
      validation: required,
    });
  }, []);

  const decimalsField = useMemo(() => {
    return new NumberFieldController({
      label: "Décimales affichées",
      initialValue: metric ? metric.decimals : null,
      validation: required,
      decimals: 0,
    });
  }, []);

  const positionField = useMemo(() => {
    return new NumberFieldController({
      label: "Ordre d'aparition",
      initialValue: metric ? metric.position : null,
      validation: required,
      decimals: 0,
      help: "Saisissez 1 pour que la métrique apparaisse en 1e position, 2 pour qu'elle apparaisse en 2e, etc etc",
    });
  }, []);

  const hiddenInRankingField = useMemo(() => {
    return new SwitchFieldController({
      label: "Masquer l'unité dans les classements",
      initialValue: metric ? metric.hiddenInRanking : false,
      nullMeaning: "L'unité est visible dans les classements",
      validation: required,
    });
  }, []);

  const usableForBonusRewardField = useMemo(() => {
    return new SwitchFieldController({
      label:
        "Utiliser cette unité pour les récompenses des quiz et des missions",
      initialValue: metric ? metric.usableForBonusReward : false,
      validation: required,
      help: "Seule les unités basées sur les points peuvent être utilisées pour récompenser les missions et les quiz",
    });
  }, []);

  // const unit = unitField.useOption();
  // useEffect(() => {
  //   if (unit === undefined) return;
  //   const enabled =
  //     unit && unit.baseMetric === BaseMetric.POINTS ? true : false;
  //   usableForBonusRewardField.setDisabled(!enabled);
  //   if (!enabled) usableForBonusRewardField.setValue(false);
  // }, [unit]);

  const onSubmitForm = useSubmitCallback(
    () => {
      if (!onSubmit) return;
      const updates: Updates<MetricModel> = {
        label: labelField.validate(),
        designation: designationField.validate(),
        symbol: symbolField.validate(),
        coefficient: ratioField.validate(),
        pictoName: iconField.validate(),
        decimals: decimalsField.validate(),
        position: positionField.validate(),
        unit: unitField.validate(),
        hiddenInRanking: hiddenInRankingField.validate(),
        usableForBonusReward: usableForBonusRewardField.validate(),
      };
      onSubmit(updates);
    },
    {},
    [onSubmit, new Date()]
  );

  return (
    <Fragment>
      <Editor2.Form
        onSubmit={onSubmit ? onSubmitForm : undefined}
        onDelete={onDelete}
      >
        {labelField.render()}
        <Typo typo="subtitle">Textes</Typo>
        <Spacer />
        {designationField.render()}
        {symbolField.render()}
        <Typo typo="subtitle">Mode de calcul</Typo>
        <Spacer />
        {unitField.render()}
        {ratioField.render()}

        <Typo typo="subtitle">Affichage</Typo>
        <Spacer />
        {positionField.render()}
        {decimalsField.render()}
        {iconField.render()}
        {hiddenInRankingField.render()}
        {usableForBonusRewardField.render()}
      </Editor2.Form>
    </Fragment>
  );
}
