import { useNavigate } from "react-router-dom";
import ConferenceEditor from "src/editors/ConferenceEditor";
import ConferenceModel from "src/models/ConferenceModel";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function ConferencesCreation() {
  const challengeUuid = Navigation.useParam("challengeUuid");
  const { platform } = Services.use();
  const navigate = useNavigate();

  const onSubmit = useSubmitCallback(
    async (update: Updates<ConferenceModel>) => {
      const conf = await platform.post<ConferenceModel>(
        `/support/challenges/${challengeUuid}/conferences`,
        update
      );

      navigate(
        Navigation.getPath("ConferencesEdition", {
          params: { conferenceUuid: conf.uuid },
        })
      );
    },
    { successMessage: "Conférence ajoutée !" }
  );

  return <ConferenceEditor conference={null} onSubmit={onSubmit} />;
}
