import { useCallback, useMemo } from "react";
import ConferenceModel from "src/models/ConferenceModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Editor2 from "src/utilities/Editor2";
import { DateFieldController } from "src/utilities/Editor2/DateFieldController";
import FileFieldController, {
  UploadSlot,
} from "src/utilities/Editor2/FileFieldController";
import { I18nFieldController } from "src/utilities/Editor2/I18nFieldController";
import { SwitchFieldController } from "src/utilities/Editor2/SwitchFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { UrlFieldController } from "src/utilities/Editor2/UrlFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import MimeTypes from "src/utilities/MimeTypes";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";

type ConferenceEditorProps = {
  conference: ConferenceModel | null;
  onSubmit: (conference: Updates<ConferenceModel>) => any;
  onDelete?: () => any;
};

export default function ConferenceEditor(props: ConferenceEditorProps) {
  const { conference, onSubmit, onDelete } = props;
  const challenge = useChallenge();
  const { platform } = Services.use();

  const enabled = useMemo(
    () =>
      new SwitchFieldController({
        initialValue: conference ? conference.enabled : null,
        label: "Activé",
        validation: required,
      }),
    []
  );

  const label = useMemo(
    () =>
      new I18nFieldController({
        locales: challenge.locales,
        getController: (l) =>
          new TextFieldController({
            initialValue: conference ? conference.label[l] : null,
            label: "Libellé",
            validation: required,
          }),
      }),
    []
  );

  const description = useMemo(
    () =>
      new I18nFieldController({
        locales: challenge.locales,
        getController: (l) =>
          new TextFieldController({
            initialValue: conference ? conference.description[l] : null,
            label: "Libellé",
            validation: required,
          }),
      }),
    []
  );

  const startAt = useMemo(
    () =>
      new DateFieldController({
        initialValue: conference ? conference.startAt : null,
        label: "Date de début",
        validation: required,
      }),
    []
  );

  const endAt = useMemo(
    () =>
      new DateFieldController({
        initialValue: conference ? conference.endAt : null,
        label: "Date de fin",
        validation: required,
      }),
    []
  );

  const joinLink = useMemo(
    () =>
      new UrlFieldController({
        initialValue: conference ? conference.joinLink : null,
        label: "Lien pour rejoindre la conférence",
        validation: required,
      }),
    []
  );

  const joinLinkPassword = useMemo(
    () =>
      new TextFieldController({
        initialValue: conference ? conference.joinLinkPassword : null,
        label: "Code pour rejoindre la conférence",
        validation: required,
      }),
    []
  );

  const image = useMemo(
    () =>
      new FileFieldController({
        initialValue: conference ? conference.image : null,
        label: "Image de couverture",
        accept: MimeTypes.images,
        getUploadSlot: () =>
          platform.get<UploadSlot>("/support/conference-image"),
        validation: required,
      }),
    []
  );

  const replayLink = useMemo(
    () =>
      new UrlFieldController({
        initialValue: conference ? conference.replayLink : null,
        label: "Lien du replay",
      }),
    []
  );

  const replayLinkPassword = useMemo(
    () =>
      new TextFieldController({
        initialValue: conference ? conference.replayLinkPassword : null,
        label: "Code pour rejoindre le replay",
      }),
    []
  );

  const onFormSubmit = useCallback(() => {
    const updates: Updates<ConferenceModel> = {
      label: label.validate(),
      description: description.validate(),
      startAt: startAt.validate(),
      endAt: endAt.validate(),
      enabled: enabled.validate(),
      joinLink: joinLink.validate(),
      joinLinkPassword: joinLinkPassword.validate(),
      image: image.validate(),
      replayLink: replayLink.validate(),
      replayLinkPassword: replayLinkPassword.validate(),
    };
    onSubmit(updates);
  }, [onSubmit]);

  return (
    <Editor2.Form onSubmit={onFormSubmit} onDelete={onDelete}>
      {label.render()}
      {description.render()}
      {startAt.render()}
      {endAt.render()}
      {enabled.render()}
      {joinLink.render()}
      {joinLinkPassword.render()}
      {image.render()}
      {replayLink.render()}
      {replayLinkPassword.render()}
    </Editor2.Form>
  );
}
