/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import axios from "axios";
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Button from "src/components/Button";
import DelayedView from "src/components/DelayedView";
import Divider from "src/components/Divider";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import { useFilePicker } from "use-file-picker";
import Intersperse from "../Intersperse";
import Toasts from "../Toasts";
import { FrontError } from "../getErrorMessage";
import EditorContainer2 from "./EditorContainer2";
import FieldController, { FieldConfig } from "./FieldController";

type FileFieldValue = string | null;

export interface UploadSlotConfig {
  contentType?: string;
}

export interface UploadSlot {
  upload: string;
  public: string;
}

type FieldFieldControllerConfig<ValidValue extends FileFieldValue> =
  FieldConfig<FileFieldValue, ValidValue> & {
    accept?: string | Array<string>;
    getUploadSlot: (config: UploadSlotConfig) => Promise<UploadSlot>;
  };

export default class FileFieldController<
  ValidValue extends FileFieldValue
> extends FieldController<FileFieldValue, ValidValue> {
  static nomalizeValue(input: FileFieldValue): FileFieldValue {
    if (input === undefined) return null;
    else if (input === "") return null;
    else return input;
  }

  uploading: boolean = false;

  constructor(readonly config: FieldFieldControllerConfig<ValidValue>) {
    super(config);
  }

  validate(): ValidValue {
    if (this.uploading)
      throw new FrontError("Attendez la fin du traitement du fichier...");
    return super.validate();
  }

  render() {
    return <FileField controller={this} />;
  }
}

type FileFieldProps<ValidValue extends FileFieldValue> = {
  controller: FileFieldController<ValidValue>;
};

function FileField<ValidValue extends FileFieldValue>(
  props: FileFieldProps<ValidValue>
) {
  const { controller } = props;

  const value = controller.useValue();

  const [fetchingUrl, setFetchingUrl] = useState<boolean>(false);
  const [upload, setUpload] = useState<number | null>(null);

  useEffect(() => {
    controller.uploading = fetchingUrl || upload !== null;
  }, [fetchingUrl, upload]);

  const contentCss = css({
    height: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  const imageCss = css({
    height: "200px",
    display: "block",
  });

  const buttonsCss = css({
    display: "flex",
    justifyContent: "center",
  });

  let content: ReactNode;

  const [openPicker, { plainFiles, clear }] = useFilePicker({
    readAs: "ArrayBuffer",
    accept: controller.config.accept || "*",
    multiple: false,
  });
  const selectedFile = plainFiles[0] || null;

  const handleSelectedFile = useCallback(async (file: File) => {
    try {
      setFetchingUrl(true);
      const slot = await controller.config.getUploadSlot({
        contentType: file.type,
      });
      setFetchingUrl(false);
      setUpload(0);
      const arrayBuffer = await file.arrayBuffer();
      await axios.put(slot.upload, arrayBuffer, {
        headers: { "Content-Type": file.type },
      });
      setUpload(null);
      controller.setValue(slot.public);
    } catch (err) {
      Toasts.error(err);
      setFetchingUrl(false);
      setUpload(null);
    } finally {
      clear();
    }
  }, []);

  useEffect(() => {
    if (!selectedFile) return;
    handleSelectedFile(selectedFile);
  }, [selectedFile]);

  const preview = useMemo(() => {
    if (value === null) return null;
    else {
      return axios(value, { responseType: "arraybuffer" }).then((r) => {
        const contentType = `${r.headers["content-type"]}`;
        const url = URL.createObjectURL(
          new Blob([r.data], { type: contentType })
        );
        let display: "button" | "image" | "pdf" = "button";
        if (contentType.startsWith("image")) display = "image";
        else if (contentType === "application/pdf") display = "pdf";
        return { display, url };
      });
    }
  }, [value]);

  let canSelect: boolean = false;
  let canDelete: boolean = false;

  if (fetchingUrl) {
    content = <Typo>Préparation...</Typo>;
  } else if (upload !== null) {
    content = <Typo>Enregistrement du fichier...</Typo>;
  } else {
    if (value === null) {
      content = <Typo>Aucun fichier sélectionné</Typo>;
      canSelect = true;
    } else {
      canSelect = true;
      canDelete = true;
      if (preview) {
        content = (
          <DelayedView promise={preview}>
            {(p) => {
              if (p.display === "image")
                return <img src={p.url} alt="" css={imageCss} />;
              else if (p.display === "pdf") {
                return (
                  <object data={p.url} type="application/pdf" css={imageCss}>
                    <div>No online PDF viewer installed</div>
                  </object>
                );
              } else
                return (
                  <Button
                    href={p.url}
                    label="Voir le fichier"
                    target="_blank"
                  />
                );
            }}
          </DelayedView>
        );
      } else {
        content = <Fragment />;
      }
    }
  }

  return (
    <EditorContainer2 controller={controller}>
      <div css={contentCss}>{content}</div>
      <Divider />
      <div css={buttonsCss}>
        <Intersperse between={() => <Spacer />}>
          <Button
            label={"Sélectioner un fichier"}
            onClick={openPicker}
            disabled={!canSelect}
          />
          <Button
            label={"Supprimer"}
            onClick={() => controller.setValue(null)}
            disabled={!canDelete}
          />
        </Intersperse>
      </div>
    </EditorContainer2>
  );
}
