/** @jsxImportSource @emotion/react */

import { Fragment, useMemo } from "react";
import Typo from "src/components/Typo";
import ThemeModel from "src/models/ThemeModel";
import FileFieldController, {
  UploadSlot,
} from "src/utilities/Editor2/FileFieldController";
import Form from "src/utilities/Editor2/Form";
import { I18nFieldController } from "src/utilities/Editor2/I18nFieldController";
import { SelectFieldController } from "src/utilities/Editor2/SelectFieldController";
import { TextFieldController } from "src/utilities/Editor2/TextFieldController";
import { required } from "src/utilities/Editor2/useValidate";
import Extractors from "src/utilities/Extractors";
import Localized from "src/utilities/Localized";
import Services from "src/utilities/Services";
import ThemePreview from "src/utilities/ThemePreview";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export type Step2Data = {
  name: Localized<string>;
  logoUrl: string;
  themeUuid: string;
};

type Step2Props = {
  locales: Array<string>;
  onSubmit: (data: Step2Data) => any;
};

export default function Step2(props: Step2Props) {
  const { locales, onSubmit } = props;

  const { platform } = Services.use();

  const nameField = useMemo(() => {
    return new I18nFieldController({
      locales,
      getController: (l) => {
        return new TextFieldController({
          label: "Nom du challenge",
          initialValue: null,
          validation: required,
        });
      },
    });
  }, []);

  const logoField = useMemo(() => {
    return new FileFieldController({
      label: "Logo",
      initialValue: null,
      accept: "image/*",
      getUploadSlot: (config) =>
        platform.post<UploadSlot>(
          "/support/challenge-logo-upload-slot",
          config
        ),
      validation: required,
    });
  }, []);

  const themeField = useMemo(() => {
    return new SelectFieldController({
      label: "Thème",
      initialValue: null,
      options: platform.get<Array<ThemeModel>>("/support/themes"),
      keyExtractor: (t) => t.uuid,
      textExtractor: Extractors.label,
      validation: required,
    });
  }, []);

  const theme = themeField.useOption();

  const onSubmitForm = useSubmitCallback(
    () => {
      onSubmit({
        name: nameField.validate(),
        logoUrl: logoField.validate(),
        themeUuid: themeField.validate(),
      });
    },
    {},
    []
  );

  return (
    <Fragment>
      <Typo typo="subtitle">Etape 2/4 : Informations générales</Typo>
      <Form onSubmit={onSubmitForm}>
        {nameField.render()}
        {logoField.render()}
        {themeField.render()}
        {theme ? <ThemePreview theme={theme} /> : null}
      </Form>
    </Fragment>
  );
}
