/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import ThemeModel, { SelectedBottomMenuColor } from "src/models/ThemeModel";
import RecolouredSvg from "./RecolouredSvg";

type ThemePreviewProps = {
  theme: Omit<ThemeModel, "uuid" | "label">;
};

export default function ThemePreview(props: ThemePreviewProps) {
  const { theme } = props;

  const recolorations = useMemo(() => {
    return {
      "#FF0000": theme.tonic,
      "#0000FF": theme.primary,
      "#00FF00": theme.primaryLight,
      "#FFFF00": theme.tonicTextDark ? "#000000" : "#FFFFFF",
      "#00FFFF": theme.primaryTextDark ? "#000000" : "#FFFFFF",
      "#FF00FF":
        theme.selectedBottomMenuColor === SelectedBottomMenuColor.white
          ? "#FFFFFF"
          : theme.tonic,
    };
  }, [
    theme.tonic,
    theme.primary,
    theme.primaryLight,
    theme.primaryTextDark,
    theme.tonicTextDark,
    theme.selectedBottomMenuColor,
  ]);

  return <RecolouredSvg src={"/app-theme.svg"} recolorations={recolorations} />;
}
