import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import MassNotificationEditor from "src/editors/MassNotificationEditor";
import MassNotificationModel from "src/models/MassNotificationModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";

export default function NotificationsCreation() {
  const challengeUuid = useChallenge().uuid;
  const { platform } = Services.use();
  const navigate = useNavigate();

  const onCreate = useCallback(
    async (updates: Updates<MassNotificationModel, "sent">) => {
      const n = await platform.post<MassNotificationModel>(
        `/support/challenges/${challengeUuid}/mass-notifications`,
        updates
      );
      navigate(
        Navigation.getPath("NotificationEdition", {
          params: { notificationUuid: n.uuid },
        })
      );
    },
    [challengeUuid]
  );

  return <MassNotificationEditor notitication={null} onSubmit={onCreate} />;
}
