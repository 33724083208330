/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";
import Clickable, { ClickableProps } from "src/components/Clickable";
import Typo from "src/components/Typo";
import { array, is, string } from "superstruct";

type ListItemProps = {
  label: string;
  helper?: string | Array<string> | ReactNode;
  image?: string | null;
  children?: ReactNode;
  inactive?: boolean;
} & ClickableProps;

export default function ListItem(props: ListItemProps) {
  const { label, helper, image, children, inactive, ...clickableProps } = props;

  const wrapperCss = css({
    display: "flex",
    width: "100%",
  });

  const containerCss = css({
    flexGrow: 1,
    paddingBlock: 8,
    paddingInline: 12,
    display: "flex",
    width: "100%",
    textDecoration: inactive ? "line-through" : undefined,
    opacity: inactive ? 0.5 : 1,
  });

  const textsCss = css({
    display: "flex",
    flexDirection: "column",
  });

  let helperNode: ReactNode = null;
  if (helper) {
    if (is(helper, string()))
      helperNode = (
        <Typo opacity={0.5} typo="minor">
          {helper}
        </Typo>
      );
    else if (is(helper, array(string()))) {
      helperNode = helper.map((h, i) => (
        <Typo opacity={0.5} typo="minor" key={i}>
          {h}
        </Typo>
      ));
    } else {
      helperNode = helper;
    }
  }

  const imageCss = css({
    width: 40,
    height: 40,
    backgroundSize: "cover",
    borderRadius: 10,
    marginRight: 10,
  });

  return (
    <div css={wrapperCss}>
      <Clickable css={containerCss} {...clickableProps}>
        {image ? (
          <div css={css(imageCss, { backgroundImage: `url(${image})` })}></div>
        ) : null}
        <div css={textsCss}>
          <Typo>{label}</Typo>
          {helperNode}
        </div>
      </Clickable>
      {children}
    </div>
  );
}
