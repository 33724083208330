import { Fragment } from "react";
import getLocaleFlag from "src/utilities/getLocaleFlag";
import PerLocaleStrings from "src/utilities/PerLocaleStrings";

interface PerLocaleStringViewProps {
  strings: PerLocaleStrings;
}

export default function PerLocaleStringView(props: PerLocaleStringViewProps) {
  return (
    <Fragment>
      {Object.entries(props.strings).map(([locale, value]) => (
        <div key={locale}>
          {getLocaleFlag(locale)} {value}
        </div>
      ))}
    </Fragment>
  );
}

const toText = (strings: PerLocaleStrings) => {
  return Object.entries(strings)
    .map(([locale, value]) => `${getLocaleFlag(locale)} ${value}`)
    .join("\n");
};

PerLocaleStringView.toText = toText;
