import { Fragment, useMemo } from "react";
import Button from "src/components/Button";
import DelayedView from "src/components/DelayedView";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import AccountEditor from "src/editors/AccountEditor";
import AccountModel from "src/models/AccountModel";
import { useChallenge } from "src/utilities/ChallengeService";
import Form from "src/utilities/Editor2/Form";
import Navigation from "src/utilities/Navigation";
import Services from "src/utilities/Services";
import Updates from "src/utilities/Updates";
import useSubmitCallback from "src/utilities/useSubmitCallback";

export default function Participant() {
  const { platform } = Services.use();
  const challenge = useChallenge();
  const uuid = Navigation.useParam("participant");

  const load = useMemo(() => {
    return platform.get<AccountModel>(
      `/support/challenges/${challenge.uuid}/accounts/${uuid}`
    );
  }, [uuid]);

  const onSubmit = useSubmitCallback(
    async (updates: Partial<Updates<AccountModel>>) => {
      await platform.patch(
        `/support/challenges/${challenge.uuid}/accounts/${uuid}`,
        updates
      );
    },
    { successMessage: "Mis à jour !" },
    []
  );

  return (
    <DelayedView promise={load}>
      {(account) => {
        console.log(account);
        return (
          <Fragment>
            <AccountEditor account={account} onSubmit={onSubmit} />

            {account.deletedAt ? (
              <AccountRestore account={account} />
            ) : (
              <AccountDeletion account={account} />
            )}

            <Spacer />
            <Spacer />
            <Spacer />
            <Spacer />
            <Button
              label="Voir les relevés de pas"
              to={Navigation.getPath("ParticipantSteps")}
            />
            <Spacer />
            <Button
              label="Voir les changements de capteurs"
              to={Navigation.getPath("ParticipantSensors")}
            />
          </Fragment>
        );
      }}
    </DelayedView>
  );
}

type AccountRestoreProps = {
  account: AccountModel;
};

function AccountRestore(props: AccountRestoreProps) {
  const { account } = props;
  const { platform } = Services.use();
  const challenge = useChallenge();

  const onSubmit = useSubmitCallback(
    async () => {
      await platform.post<void>(
        `/support/challenges/${challenge.uuid}/accounts/${account.uuid}/restoration`,
        null
      );
      window.location.reload();
    },
    { successMessage: "Le compte est rétabli" },
    [account.uuid]
  );

  return (
    <Fragment>
      <Spacer />
      <Form onSubmit={onSubmit} submitLabel="Restaurer le compte">
        <Typo typo="title">Retablir le compte</Typo>
        <Spacer />
        <Typo>
          Cet utilisateur a demandé la suppression de son compte. Pour ne pas
          fausser les classements, cette suppression ne peut pas être faite en
          cours de challenge.
          <br />
          Aussi, si l'utilisateur le demande, il est possible de lui réactiver
          le compte grâce au bouton si dessous.
        </Typo>
      </Form>
    </Fragment>
  );
}

type AccountDeletionProps = {
  account: AccountModel;
};

function AccountDeletion(props: AccountDeletionProps) {
  const { account } = props;
  const { platform } = Services.use();
  const challenge = useChallenge();

  const onSubmit = useSubmitCallback(
    async () => {
      await platform.delete<void>(
        `/support/challenges/${challenge.uuid}/accounts/${account.uuid}`
      );
      window.location.reload();
    },
    {
      successMessage: "Le compte est supprimé",
      confirmationMessage: "Confirmation",
    },
    [account.uuid]
  );

  return (
    <Fragment>
      <Spacer />
      <Form onSubmit={onSubmit} submitLabel="Désactiver le compte">
        <Typo typo="title">Désactiver le compte</Typo>
        <Spacer />
        <Typo>
          Vous pouvez désactiver le compte de cet utilisateur. L'utilisateur ne
          pourra plus se connecter à l'application. Son nom n'apparaitra plus
          dans les classements, et il ne comptera plus dans les quotas de
          groupes ou des équipes. S'il tente de se connecter, l'utilisateur sera
          invité à contacter le support pour réactiver son compte.
        </Typo>
      </Form>
    </Fragment>
  );
}

export function during(ms: number) {
  return new Promise((r) => setTimeout(r, ms));
}
